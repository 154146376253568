import React, { useEffect, useState } from 'react';
import FormBody from '../FormComponents/FormBody';
import PageContainer from '../PageContainer';
import FormSection from '../FormComponents/FormSection';
import { useParams, navigate } from '@reach/router';
import AppSyncService from '../../graphql/AppSyncService';
import SEO from '../seo';
import {
	INITIAL_STABLE_LOCATION_FORM_STATE,
	INITIAL_OTT_INFORMATION_FORM_STATE,
	INITIAL_OTT_STATUS_FORM_STATE
} from './constants';
import {
	validateHorseLocation,
	validateOTTStatusSection,
	generateSaveHorseLocationPayload,
	generateSaveOttInfoPayload,
	initialiseHorseLocationData,
	initialiseOttInfoData,
	generateSaveOttStatusPayload,
	initialiseOttStatusData,
	readFile,
	generateHorseProfileRetirementPayload
} from './helpers';
import FormCancelConfirmModal from '../FormComponents/FormCancelConfirmModal';
import {
	convertToMultiListData,
	extractLookUpOptions
} from '../../utils/helpers';
import {
	BLANK_OPTION,
	HORSE_LISTED_B1,
	HORSE_LISTED_B2,
	HORSE_LISTED_C
} from '../../utils/constants';
import { checkUserAdmin } from '../../utils/helpers';
import { getHorseProfileEditLookupData } from './graphql/queries';
import EditFormHeader from './Components/EditFormHeader';
import StableLocationForm from './Components/StableLocationForm';
import OTTInfoForm from './Components/OTTInfoForm';
import OTTStatusForm from './Components/OTTStatusForm';
import FormFooter from '../FormComponents/FormFooter';
import PageLoadSpinner from '../PageLoadSpinner';
import {
	saveHorseLocation,
	saveOttInformation,
	setOttProgramAndStatus,
	uploadImageToS3,
	deleteImageFromS3,
	getUserImage,
	updateHorseToListed,
	saveHorseProfileRetirement,
	generateHorseListedPresetEmail
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import axios from 'axios';
import {
	saveHorseProfileImage,
	deleteHorseProfileImage
} from './graphql/mutations';
import Lightbox from 'react-image-lightbox';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import ImageCropModal from '../ImageCropModal';
import { updateActiveProfilePicture } from '../ProfilePhoto/queries';
import HorseProfileVisibilityHelperSection from '../HorseVisibilityHelperSection';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import RetirementCategory from '../RetirementCategory/index';

const HorseProfileEdit = () => {
	const { horseCode } = useParams();
	const { currentUser } = useAuth();
	const { addToast } = useToast();
	const [horseData, setHorseData] = useState(null);
	const [imageBeingCropped, setImageBeingCropped] = useState(null);
	const [stableLocationFormState, setStableLocationFormState] = useState(
		INITIAL_STABLE_LOCATION_FORM_STATE
	);
	const [ottInfoFormState, setOttInfoFormState] = useState(
		INITIAL_OTT_INFORMATION_FORM_STATE
	);
	const [ottStatusFormState, setOttStatusFormState] = useState(
		INITIAL_OTT_STATUS_FORM_STATE
	);
	const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
	const [purposeOptions, setPurposeOptions] = useState([BLANK_OPTION]);
	const [disciplineOptions, setDisciplineOptions] = useState([]);
	const [viceOptions, setViceOptions] = useState([]);
	const [injuryOptions, setInjuryOptions] = useState([]);
	const [illnessOptions, setIllnessOptions] = useState([]);
	const [programOptions, setProgramOptions] = useState([]);
	const [locationSourceOptions, setLocationSourceOptions] = useState([
		BLANK_OPTION
	]);
	const [visibilityOptions, setVisibilityOptions] = useState([]);
	const [welfareSourceOptions, setWelfareSourceOptions] = useState([
		BLANK_OPTION
	]);
	const [welfareRelationshipOptions, setWelfareRelationshipOptions] = useState([
		BLANK_OPTION
	]);
	const [
		fosterAssistanceTypeOptions,
		setFosterAssistanceTypeOptions
	] = useState([BLANK_OPTION]);
	const [
		fosterReasonForEnteringOptions,
		setFosterReasonForEnteringOptions
	] = useState([BLANK_OPTION]);
	const [
		principalRacingAuthorityOptions,
		setPrincipalRacingAuthorityOptions
	] = useState([BLANK_OPTION]);
	const [auditNameOptions, setAuditNameOptions] = useState([BLANK_OPTION]);
	const [isLoading, setIsLoading] = useState(true);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [initialProgramAndStatus, setInitialProgramAndStatus] = useState({
		program: null,
		status: null
	});
	const [isEmailRetrainers, setIsEmailRetrainers] = useState(false);

	const isAdminUser = currentUser && checkUserAdmin(currentUser?.Roles);
	const validationErrorsPresent = Object.values({
		...stableLocationFormState,
		...ottStatusFormState
	}).find(({ validationError }) => validationError);

	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [adminData, setAdminData] = useState();
	const [previousOttStatus, setPreviousOttStatus] = useState(null);
	const [onValidate, setOnValidate] = useState(false);
	const [hasAdminDataError, setHasAdminDataError] = useState(false);
	const [adminDataErrors, setAdminDataErrors] = useState({
		reasonRetirement: {
			value: false,
			message: `Reason is required*`
		},
		vulnerabilityType: {
			value: false,
			message: 'Vulnerability Type is required*'
		},
		behaviourRetirement: {
			value: false,
			message: `Behaviour is required*`
		},
		conformationalRetirement: {
			value: false,
			message: `Conformational defect/s is required*`
		},
		education: {
			value: false,
			message: `Education is required*`
		},
		educationDetails: {
			value: false,
			message: `Other education - details is required*`
		},
		injuryRetirement: {
			value: false,
			message: `Injury is required*`
		},
		otherInjuryRetirement: {
			value: false,
			message: `Other injury details is required*`
		},
		illnessRetirement: {
			value: false,
			message: `Illness is required*`
		},
		otherIllnessRetirement: {
			value: false,
			message: `Other illness details is required*`
		},
		rehomingHistory: {
			value: false,
			message: `Rehoming history - details is required*`
		},
		otherDetailsRetirement: {
			value: false,
			message: `Other - details is required*`
		},
		behaviourOtherRetirement: {
			value: false,
			message: 'Other is required*'
		},
		informationSource: {
			value: false,
			message: 'Information sourced from is required*'
		},
		sourceDetail: {
			value: false,
			message: 'Source Details is required*'
		},
		sourceDetailOther: {
			value: false,
			message: 'Source Details - Other is required*'
		},
		dateInformationProvided: {
			value: false,
			message: 'Date information was provided is required*'
		},
		detailsInformationObtained: {
			value: false,
			message: 'Details of how the information was obtained is required*'
		},
		adminId: {
			value: false,
			message: 'Admin user is required*'
		},
		otherRelevantDetails: {
			value: false,
			message: 'Other relevant details is required*'
		}
	});

	useEffect(() => {
		AppSyncService.execute(getHorseProfileEditLookupData, {
			horseCode: +horseCode,
			memberExternalId: currentUser?.UserId
		}).then((result) => {
			if (result?.data?.getHorseProfile) {
				const {
					data: { getHorseProfileRetirement }
				} = result;
				setHorseData(result.data.getHorseProfile.horse);
				if (!isAdminUser && !result?.data?.isClaimedByMember) {
					navigate(DASHBOARD_ROUTE);
				}
				const horseLocationData = result.data.getHorseProfile.horse.location;
				if (horseLocationData) {
					initialiseHorseLocationData(
						horseLocationData,
						setStableLocationFormState
					);
				}
				const horseOttInformation =
					result.data.getHorseProfile.horse.ottInformation;
				if (horseOttInformation) {
					initialiseOttInfoData(horseOttInformation, setOttInfoFormState);
				}
				const horseProgram = result.data.getHorseProfile.program;
				const horseOttStatus = result.data.getHorseProfile.status;
				setInitialProgramAndStatus({
					program: horseProgram?.id,
					status: horseOttStatus?.status?.id
				});
				if (horseProgram && horseOttStatus) {
					initialiseOttStatusData(
						horseProgram,
						horseOttStatus,
						horseLocationData,
						setOttStatusFormState
					);
					setPreviousOttStatus(horseOttStatus);
				}
				let splitOtherVicesRetirement = [];
				let mapBehaviourRetirement = [];
				if (getHorseProfileRetirement?.behaviourOthersRetirement) {
					splitOtherVicesRetirement = getHorseProfileRetirement.behaviourOthersRetirement.split(
						','
					);
					mapBehaviourRetirement = splitOtherVicesRetirement.map(
						(otherVices) => ({
							label: otherVices,
							value: otherVices
						})
					);
				}
				setAdminData({
					informationSource: getHorseProfileRetirement?.informationSource
						? getHorseProfileRetirement.informationSource
						: 0,
					sourceDetail: getHorseProfileRetirement?.sourceDetail
						? getHorseProfileRetirement.sourceDetail
						: 0,
					sourceDetailOther: getHorseProfileRetirement?.sourceDetailOther
						? getHorseProfileRetirement.sourceDetailOther
						: '',
					detailsInformationObtained: getHorseProfileRetirement?.detailsInformationObtained
						? getHorseProfileRetirement.detailsInformationObtained
						: '',
					adminId: getHorseProfileRetirement?.adminId
						? getHorseProfileRetirement.adminId
						: 0,
					dateInformationProvided: getHorseProfileRetirement?.dateInformationProvided
						? getHorseProfileRetirement.dateInformationProvided
						: null,
					otherRelevantDetails: getHorseProfileRetirement?.otherRelevantDetails
						? getHorseProfileRetirement.otherRelevantDetails
						: '',
					selectedCategory: !getHorseProfileRetirement?.id
						? evaluateRetirementCategory(horseProgram.id)
						: getHorseProfileRetirement?.retirementCategory
						? getHorseProfileRetirement.retirementCategory
						: 0,
					selectedVulnerabilityType: getHorseProfileRetirement?.vulnerabilityType
						? getHorseProfileRetirement.vulnerabilityType
						: 0,
					selectedReason:
						getHorseProfileRetirement?.reason?.length > 0
							? getHorseProfileRetirement.reason.map((reason) => {
									return { value: reason.id, label: reason.value };
							  })
							: [],
					selectedVicesRetirement:
						getHorseProfileRetirement?.habits?.length > 0
							? getHorseProfileRetirement.habits.map(
									(selectedHabitsRetirement) => {
										return {
											value: selectedHabitsRetirement.id,
											label: selectedHabitsRetirement.value
										};
									}
							  )
							: [],
					otherVicesRetirement: getHorseProfileRetirement?.behaviourOthersRetirement
						? mapBehaviourRetirement
						: '',
					conformationalDefectsRetirement: getHorseProfileRetirement?.conformationalDetailsRetirement
						? getHorseProfileRetirement.conformationalDetailsRetirement
						: '',
					selectedEducation:
						getHorseProfileRetirement?.education?.length > 0
							? getHorseProfileRetirement.education.map((selectedEducation) => {
									return {
										value: selectedEducation.id,
										label: selectedEducation.value
									};
							  })
							: [],
					otherEducationDetails: getHorseProfileRetirement?.otherEducationDetails
						? getHorseProfileRetirement.otherEducationDetails
						: '',
					selectedInjuriesRetirement:
						getHorseProfileRetirement?.injuries?.length > 0
							? getHorseProfileRetirement.injuries.map((injuriesRetirement) => {
									return {
										value: injuriesRetirement.id,
										label: injuriesRetirement.value
									};
							  })
							: [],
					selectedIllnessRetirement:
						getHorseProfileRetirement?.illness?.length > 0
							? getHorseProfileRetirement.illness.map((illnessRetirement) => {
									return {
										value: illnessRetirement.id,
										label: illnessRetirement.value
									};
							  })
							: [],
					otherInjuryDetails: getHorseProfileRetirement?.otherInjuryDetailsRetirement
						? getHorseProfileRetirement.otherInjuryDetailsRetirement
						: '',
					otherIllnessDetails: getHorseProfileRetirement?.otherIllnessDetailsRetirement
						? getHorseProfileRetirement.otherIllnessDetailsRetirement
						: '',
					rehomingHistoryDetails: getHorseProfileRetirement?.rehomingHistoryDetails
						? getHorseProfileRetirement.rehomingHistoryDetails
						: '',
					otherDetailsRetirement: getHorseProfileRetirement?.otherDetailsRetirement
						? getHorseProfileRetirement.otherDetailsRetirement
						: ''
				});
			}
			setPurposeOptions((prevState) => [
				...prevState,
				...extractLookUpOptions(result, 'listHorsePurposes')
			]);
			setDisciplineOptions(
				extractLookUpOptions(result, 'listSpecialtyDisciplines')
			);
			setInjuryOptions(extractLookUpOptions(result, 'listInjuryDetails'));
			setIllnessOptions(
				extractLookUpOptions(result, 'listShareInfoEuthanisedIllness')
			);
			setProgramOptions(extractLookUpOptions(result, 'getOttPrograms'));
			setLocationSourceOptions((prevState) => [
				...prevState,
				...extractLookUpOptions(result, 'listHorseLocationSources')
			]);
			setViceOptions(extractLookUpOptions(result, 'listHabitsAndVices'));
			setWelfareSourceOptions((prevState) => [
				...prevState,
				...extractLookUpOptions(result, 'listHorseWelfareSources')
			]);
			setWelfareRelationshipOptions((prevState) => [
				...prevState,
				...extractLookUpOptions(result, 'listHorseWelfareRelationships')
			]);
			setFosterReasonForEnteringOptions((prevState) => [
				...prevState,
				...extractLookUpOptions(
					result,
					'listFosterReasonForEnteringProgramSources'
				)
			]);
			setPrincipalRacingAuthorityOptions((prevState) => [
				...prevState,
				...extractLookUpOptions(result, 'listPrincipalRacingAuthoritySources')
			]);
			setFosterAssistanceTypeOptions(
				convertToMultiListData(
					result.data.listFosterAssistanceTypeSources,
					'value'
				)
			);
			setVisibilityOptions(
				extractLookUpOptions(result, 'listHorseDetailVisibilityOptions')
			);
			setAuditNameOptions((prevState) => [
				...prevState,
				...extractLookUpOptions(result, 'listAuditNames')
			]);
			setIsLoading(false);
		});
	}, []);

	const evaluateRetirementCategory = (horseProgramVal) => {
		switch (horseProgramVal) {
			//RESET
			//Available For Retraining
			case 1:
			case 5:
				return 1;
			//Acknowledged Retrainer and N/A
			case 2:
			case 4:
				return 0;
			//STAR
			case 3:
				return 2;
			//OHEP
			case 6:
				return 4;
			//Acknowledged Retirement
			case 7:
				return 3;
			default:
				return 0;
		}
	};

	const onCancel = (event) => {
		event.preventDefault();
		setShowCancelConfirmModal(true);
	};

	const validateFields = () => {
		let isValidationErrorsPresent = false;
		const newStableLocationFormState = validateHorseLocation(
			stableLocationFormState
		);
		setStableLocationFormState(newStableLocationFormState);
		const stableLocationValidationErrors = Object.values(
			newStableLocationFormState
		).find(({ validationError }) => validationError);
		if (stableLocationValidationErrors) {
			isValidationErrorsPresent = true;
		}
		if (isAdminUser) {
			const newOttStatusFormState = validateOTTStatusSection(
				ottStatusFormState,
				{
					locationSourceOptions,
					welfareSourceOptions,
					welfareRelationshipOptions,
					auditNameOptions,
					fosterReasonForEnteringOptions,
					fosterAssistanceTypeOptions,
					principalRacingAuthorityOptions
				},
				stableLocationFormState,
				horseData
			);
			setOttStatusFormState(newOttStatusFormState);
			const ottStatusValidationErrors = Object.values(
				newOttStatusFormState
			).find(({ validationError }) => validationError);
			if (ottStatusValidationErrors) {
				isValidationErrorsPresent = true;
			}
		}
		return isValidationErrorsPresent;
	};

	const saveHorseLocationData = () => {
		const payload = generateSaveHorseLocationPayload(
			stableLocationFormState,
			ottStatusFormState,
			{
				horseCode: +horseCode
			},
			isAdminUser
		);
		return AppSyncService.execute(saveHorseLocation, { input: payload });
	};

	const saveOTTInformation = () => {
		const ottInformation = generateSaveOttInfoPayload(ottInfoFormState, {
			horseCode: +horseCode
		});
		return AppSyncService.execute(saveOttInformation, {
			ottInformation
		});
	};

	const saveOttProgramAndStatus = () => {
		const payload = generateSaveOttStatusPayload(ottStatusFormState, {
			horseCode: +horseCode,
			memberExternalId: currentUser?.UserId
		});
		if (isEmailRetrainers) payload.isEmailRetrainers = true;
		return AppSyncService.execute(setOttProgramAndStatus, payload);
	};

	const onSaveHorseProfileRetirement = () => {
		const payload = generateHorseProfileRetirementPayload(adminData, {
			horseCode: +horseCode
		});
		return AppSyncService.execute(saveHorseProfileRetirement, {
			input: payload
		});
	};

	const removeHorsePlacement = () => {
		if (ottStatusFormState?.ottStatus?.value == 6) {
			AppSyncService.execute(updateHorseToListed, {
				horseCode: +horseCode
			}).then((data) => {
				if (data.data != null) {
					addToast({
						Message: 'Successfully updated horse replacement',
						IsSuccess: true
					});
				} else {
					addToast({
						Message: 'Something went wrong updating horse replacement',
						IsSuccess: false
					});
				}
			});
		}
	};

	const cropImage = (image) => {
		readFile(image).then((result) =>
			setImageBeingCropped({ imageFile: image, src: result })
		);
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const uploadImage = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: imageBeingCropped.imageFile.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((result) => {
			if (result.data) {
				const imageKey = result.data.putUserImage.imageKey;
				axios
					.put(result.data.putUserImage.signedUrl, image, { headers })
					.then(() => {
						AppSyncService.execute(saveHorseProfileImage, {
							fileName: imageBeingCropped.imageFile.name,
							horseCode,
							type: 'ProfileImage',
							key: imageKey
						}).then((result) => {
							if (result.data.saveHorseProfileFile) {
								const [firstName, lastName] = currentUser?.Name.split(' ');

								let dataList = Object.assign(
									[],
									ottInfoFormState.profileImage.value
								);
								dataList.push({
									filename: imageBeingCropped.imageFile.name,
									key: imageKey,
									id: result.data.saveHorseProfileFile.id,
									activeProfilePicture: false,
									uploadedBy: {
										firstName: firstName,
										lastName: lastName
									}
								});
								setOttInfoFormState((prevState) => ({
									...prevState,
									profileImage: {
										...prevState.profileImage,
										value: dataList
									}
								}));

								setImageBeingCropped(null);
								addToast({
									Message: 'Uploaded horse profile image',
									IsSuccess: true
								});
							} else {
								addToast({
									Message: 'Upload horse profile image failed',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const deleteImage = (image) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: image.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteHorseProfileImage, {
						key: image.key
					}).then((result) => {
						if (result?.data?.deleteHorseProfileFile) {
							let dataList = Object.assign(
								[],
								ottInfoFormState.profileImage.value
							);
							let newDataList = dataList.filter((d) => d.key != image.key);
							setOttInfoFormState((prevState) => ({
								...prevState,
								profileImage: { ...prevState.profileImage, value: newDataList }
							}));
							addToast({
								Message: 'Horse profile image removed',
								IsSuccess: true
							});
						} else {
							addToast({
								Message: 'Horse profile image remove failed',
								IsSuccess: false
							});
						}
					});
				}
			}
		);
	};

	const setActivePhoto = (img) => {
		const currentActivePhotoId = ottInfoFormState?.profileImage.value.filter(
			(file) =>
				file.type === 'ProfileImage' &&
				!file.removed &&
				file.activeProfilePicture
		)?.[0]?.id;
		AppSyncService.execute(updateActiveProfilePicture, {
			newActivePhotoId: img.id,
			previousPhotoId: currentActivePhotoId
		}).then(() => {
			let dataList = Object.assign([], ottInfoFormState.profileImage.value);
			dataList.forEach(function (item, i) {
				if (item.id == currentActivePhotoId)
					dataList[i].activeProfilePicture = false;
			});
			dataList.forEach(function (item, i) {
				if (item.id == img.id) dataList[i].activeProfilePicture = true;
			});
			setOttInfoFormState((prevState) => ({
				...prevState,
				profileImage: {
					...prevState.profileImage,
					value: dataList
				}
			}));
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((result) => {
			setImgSrcUrl(result.data.getUserImage.signedUrl);
		});
	};

	const onSave = (event) => {
		event.preventDefault();
		const validationErrorsPresent = validateFields();
		const hasError = isAdminUser ? evaluateAdminError() : false;
		setHasAdminDataError(hasError);
		if (!validationErrorsPresent && !hasError) {
			const horseProfileSavePromises = [
				saveHorseLocationData(),
				saveOTTInformation(),
				removeHorsePlacement(),
				onSaveHorseProfileRetirement()
			];
			if (isAdminUser) {
				horseProfileSavePromises.push(saveOttProgramAndStatus());
			}
			Promise.all(horseProfileSavePromises).then((result) => {
				const saveHorseLocationSuccessful = result.some(
					(result) => result?.data?.saveHorseLocation
				);
				const saveOTTInfoSuccessful = result.some(
					(result) => result?.data?.saveOttInformation?.id > 0
				);
				const saveOttProgramAndStatusSuccessful = result.some(
					(result) => result?.data?.setOttProgramAndStatus?.success
				);
				const saveHorseProfileSuccessful = !isAdminUser
					? saveHorseLocationSuccessful && saveOTTInfoSuccessful
					: saveHorseLocationSuccessful &&
					  saveOTTInfoSuccessful &&
					  saveOttProgramAndStatusSuccessful;
				if (saveHorseProfileSuccessful) {
					if (
						previousOttStatus?.status?.id !==
							parseInt(ottStatusFormState.ottStatus.value) &&
						![HORSE_LISTED_B1, HORSE_LISTED_B2, HORSE_LISTED_C].includes(
							previousOttStatus?.status?.id
						) &&
						[HORSE_LISTED_B1, HORSE_LISTED_B2, HORSE_LISTED_C].includes(
							parseInt(ottStatusFormState.ottStatus.value)
						)
					) {
						AppSyncService.execute(generateHorseListedPresetEmail, {
							horseCode: +horseCode
						});
					}
					addToast({
						Message: 'Horse profile saved',
						IsSuccess: true
					});
					navigate(`/horse-profile/${horseCode}`);
				} else {
					addToast({
						Message: 'Horse profile save failed',
						IsSuccess: false
					});
				}
			});
		}
	};

	const handleMultiFieldChange = (value, name, fieldsToClear) => {
		let items = { ...adminData };
		items[name] = value;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				if (name === 'informationSource') {
					items[field.item] = field.value;
				} else {
					let newItem = {
						...items[field.item],
						value: field.value
					};
					items[field.item] = newItem;
				}
			});
		}
		setOnValidate(true);
		setAdminData(items);
	};

	useEffect(() => {
		if (onValidate) {
			if (isAdminUser) evaluateAdminError();
			setOnValidate(false);
		}
	}, [onValidate]);

	const evaluateAdminError = () => {
		let errorItems = { ...adminDataErrors };

		let reasonRetirement = {
			...errorItems['reasonRetirement'],
			value:
				adminData.selectedCategory != 0 && adminData.selectedReason.length === 0
		};
		errorItems['reasonRetirement'] = reasonRetirement;

		let vulnerabilityType = {
			...errorItems['vulnerabilityType'],
			value:
				(adminData.selectedCategory == 3 || adminData.selectedCategory == 4) &&
				adminData.selectedVulnerabilityType == 0
		};
		errorItems['vulnerabilityType'] = vulnerabilityType;

		let behaviourRetirement = {
			...errorItems['behaviourRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value === 2) &&
				adminData.selectedVicesRetirement.length === 0
		};
		errorItems['behaviourRetirement'] = behaviourRetirement;

		let conformationalRetirement = {
			...errorItems['conformationalRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 3) &&
				adminData.conformationalDefectsRetirement.length === 0
		};
		errorItems['conformationalRetirement'] = conformationalRetirement;

		let education = {
			...errorItems['education'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.length === 0
		};
		errorItems['education'] = education;

		let educationDetails = {
			...errorItems['educationDetails'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.some((educ) => educ.value == 4) &&
				adminData.otherEducationDetails.length === 0
		};
		errorItems['educationDetails'] = educationDetails;

		let injuryRetirement = {
			...errorItems['injuryRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 5) &&
				adminData.selectedInjuriesRetirement.length === 0
		};
		errorItems['injuryRetirement'] = injuryRetirement;

		let otherInjuryRetirement = {
			...errorItems['otherInjuryRetirement'],
			value:
				adminData.selectedInjuriesRetirement.some(
					(injury) => injury.value == 12
				) && adminData.otherInjuryDetails.length === 0
		};
		errorItems['otherInjuryRetirement'] = otherInjuryRetirement;

		let illnessRetirement = {
			...errorItems['illnessRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 6) &&
				adminData.selectedIllnessRetirement.length === 0
		};
		errorItems['illnessRetirement'] = illnessRetirement;

		let otherIllnessRetirement = {
			...errorItems['otherIllnessRetirement'],
			value:
				adminData.selectedIllnessRetirement.some(
					(illness) => illness.value == 4
				) && adminData.otherIllnessDetails.length === 0
		};
		errorItems['otherIllnessRetirement'] = otherIllnessRetirement;

		let rehomingHistory = {
			...errorItems['rehomingHistory'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 7) &&
				adminData.rehomingHistoryDetails.length === 0
		};
		errorItems['rehomingHistory'] = rehomingHistory;

		let otherDetailsRetirement = {
			...errorItems['otherDetailsRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 8) &&
				adminData.otherDetailsRetirement.length === 0
		};
		errorItems['otherDetailsRetirement'] = otherDetailsRetirement;

		let behaviourOtherRetirement = {
			...errorItems['behaviourOtherRetirement'],
			value:
				adminData.selectedVicesRetirement.some((vice) => vice.value == 12) &&
				adminData.otherVicesRetirement.length === 0
		};
		errorItems['behaviourOtherRetirement'] = behaviourOtherRetirement;

		let informationSource = {
			...errorItems['informationSource'],
			value: adminData.informationSource == 0
		};
		errorItems['informationSource'] = informationSource;

		let sourceDetail = {
			...errorItems['sourceDetail'],
			value: adminData.sourceDetail == 0 || !adminData.sourceDetail
		};
		errorItems['sourceDetail'] = sourceDetail;

		let sourceDetailOther = {
			...errorItems['sourceDetailOther'],
			value:
				(adminData.sourceDetail == 3 ||
					adminData.sourceDetail == 10 ||
					adminData.sourceDetail == 19 ||
					adminData.sourceDetail == 20) &&
				adminData.sourceDetailOther.length == 0
		};
		errorItems['sourceDetailOther'] = sourceDetailOther;

		let detailsInformationObtained = {
			...errorItems['detailsInformationObtained'],
			value:
				adminData.informationSource == 2 &&
				adminData.detailsInformationObtained.length == 0
		};
		errorItems['detailsInformationObtained'] = detailsInformationObtained;

		let adminId = {
			...errorItems['adminId'],
			value: adminData.informationSource == 2 && adminData.adminId == 0
		};
		errorItems['adminId'] = adminId;

		let dateInformationProvided = {
			...errorItems['dateInformationProvided'],
			value: !adminData.dateInformationProvided
		};
		errorItems['dateInformationProvided'] = dateInformationProvided;

		let otherRelevantDetails = {
			...errorItems['otherRelevantDetails'],
			value:
				adminData.sourceDetail == 21 &&
				adminData.otherRelevantDetails.length == 0
		};
		errorItems['otherRelevantDetails'] = otherRelevantDetails;

		setAdminDataErrors(errorItems);
		for (let key in errorItems) {
			if (errorItems[key].value) {
				return errorItems[key].value;
			}
		}
	};

	return (
		<PageContainer>
			<SEO title="Horse Profile Edit" />
			<PageLoadSpinner isLoading={isLoading}>
				<form onSubmit={onSave}>
					<FormBody>
						<FormSection
							sectionTitle={
								<EditFormHeader
									horseName={horseData?.name}
									onCancel={onCancel}
								/>
							}
						>
							<HorseProfileVisibilityHelperSection hintMessage="Change who can see which information fields by clicking the privacy buttons next to each field." />
							<StableLocationForm
								stableLocationFormState={stableLocationFormState}
								updateFormState={setStableLocationFormState}
								visibilityOptions={visibilityOptions}
							/>
						</FormSection>
						{isAdminUser && (
							<OTTStatusForm
								stableLocationFormState={stableLocationFormState}
								horseData={horseData}
								formState={ottStatusFormState}
								updateFormState={setOttStatusFormState}
								lookUpData={{
									programOptions,
									locationSourceOptions,
									welfareSourceOptions,
									welfareRelationshipOptions,
									auditNameOptions,
									fosterReasonForEnteringOptions,
									fosterAssistanceTypeOptions,
									principalRacingAuthorityOptions
								}}
								initialProgramAndStatus={initialProgramAndStatus}
								setIsEmailRetrainers={setIsEmailRetrainers}
							/>
						)}
						{isAdminUser ? (
							<FormSection sectionTitle="Vulnerable Thoroughbred Identification">
								<RetirementCategory
									isReadOnly={false}
									adminData={adminData}
									adminDataErrors={adminDataErrors}
									handleMultiFieldChange={handleMultiFieldChange}
									isHorseProfile
								/>
							</FormSection>
						) : null}

						<OTTInfoForm
							cropImage={cropImage}
							horseData={horseData}
							formState={ottInfoFormState}
							updateFormState={setOttInfoFormState}
							lookUpData={{
								purposeOptions,
								disciplineOptions,
								viceOptions,
								injuryOptions,
								illnessOptions,
								visibilityOptions
							}}
							uploadImage={uploadImage}
							deleteImage={deleteImage}
							setActivePhoto={setActivePhoto}
							imageUploadError={uploadError}
							getImage={getImage}
						/>
					</FormBody>
					<FormFooter
						onCancel={onCancel}
						submitEnabled
						submitButtonText="Save"
						showSaveButton={false}
						validationErrorsExist={validationErrorsPresent || hasAdminDataError}
					/>
				</form>
			</PageLoadSpinner>
			<UploadPhotoErrorModal
				showErrorModal={showImageUploadErrorModal}
				closeModal={() => setShowImageUploadErrorModal(false)}
				isMinimumUploadSize={isMinimumUploadSize}
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
			<FormCancelConfirmModal
				showCancelConfirmModal={showCancelConfirmModal}
				onCloseHandler={() => setShowCancelConfirmModal(false)}
				onConfirmHandler={() => navigate(`/horse-profile/${horseCode}`)}
				modalTitle="Are you sure you want to Cancel?"
			/>
			<ImageCropModal
				imgSrc={imageBeingCropped?.src}
				isOpen={!!imageBeingCropped}
				closeModal={() => setImageBeingCropped(null)}
				onSaveCrop={uploadImage}
			/>
		</PageContainer>
	);
};

export default HorseProfileEdit;
