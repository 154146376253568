import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import Lightbox from 'react-image-lightbox';
import AppSyncService from '../../graphql/AppSyncService';
import {
	saveOTTStatusAndProgram,
	saveResetExpressRehomeAssessmentFormStatus
} from '../../graphql/custom.queries';
import { useToast } from '../../hooks/useToast';
import { formatDateReadableVariant } from '../../utils/helpers';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import FormLabelField from '../FormLabelField';
import HeaderBanner from '../HeaderBanner';
import { MAXREASONLENGTH } from './constants';
import useStyles from './styles';
import { useAuth } from '../../hooks/useAuth';
import UploadPhoto from '../UploadPhoto';
import { getImage } from './utils';

const ResetExpressRehomeReportReviewInfo = ({ data, mobile }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);
	const { currentUser } = useAuth();

	useEffect(() => {
		setRejectReason(data.reviewReasons === null ? '' : data.reviewReasons);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
	}, []);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};
	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(saveResetExpressRehomeAssessmentFormStatus, {
			id: parseInt(data.id),
			reviewNotes: notes,
			reviewReasons: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveResetExpressRehomeAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetExpressRehomeAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const approve = () => {
		setIsProcessingApprove(true);
		const horseCode = data?.horse?.horseCode;
		AppSyncService.execute(saveResetExpressRehomeAssessmentFormStatus, {
			id: parseInt(data.id),
			status: 'Approved',
			reviewNotes: notes,
			reviewReasons: rejectReason
		}).then(async (response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveResetExpressRehomeAssessmentFormStatus?.success) {
				const payload = {
					asOfDate: new Date(),
					horseCode,
					programId: 9,
					statusId: 44,
					memberExternalId: currentUser.UserId
				};
				await AppSyncService.execute(saveOTTStatusAndProgram, payload);
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetExpressRehomeAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};
	return (
		<>
			<div
				style={
					mobile
						? { border: 'none' }
						: {
								borderTop: '1px solid #b3bcc1',
								borderRadius: '5px 5px 0px 0px',
								borderBottom: 'none'
						  }
				}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<HeaderBanner
					title="PRESET Ready for Rehoming Report"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '5px 5px 0px 0px'
					}}
				/>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Date submitted"
							value={
								data.lastUpdated
									? formatDateReadableVariant(data.lastUpdated)
									: ''
							}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField title="Week Number" value={data.weekId} />
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="How would you describe the horse's temperament?"
							value={data.horseTemperamentDesc}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>
								Photos of the horse
							</div>
							<UploadPhoto
								data={data.photosOfTheHorse}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="How would you describe the horse's movement?"
							value={data.horseMovementDesc}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="What ground work has the horse completed?"
							value={data.groundWork
								.map((groundWork) => groundWork.value)
								.join(', ')}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Other details"
							value={data.groundWorkdOtherDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Can the horse tie up quietly?"
							value={data.isHorseTieUpQuietly ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.isHorseTieUpQuietlyDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Does the horse stand whilst being mounted?"
							value={data.doesHorseStand ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.doesHorseStandDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="What ridden training / exposure has the horse received?"
							value={data.riddenTrainingExposure
								.map((riddenTrainingExposure) => riddenTrainingExposure.value)
								.join(', ')}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Other details"
							value={data.riddenTrainingExposuredOtherDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="What ridden exposure has the horse received whilst in retraining?"
							value={data.riddenExposureRetraining
								.map(
									(riddenExposureRetraining) => riddenExposureRetraining.value
								)
								.join(', ')}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Other details"
							value={data.riddenExposureRetrainingdOtherDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Has the horse received any training relating to loading onto transport (ie float or truck)?"
							value={data.hasHorseReceivedTraining ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.hasHorseReceivedTrainingDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Has the horse been transported off the property?"
							value={data.hasHorseTransported ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.hasHorseTransportedDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Is the horse currently sound?"
							value={data.isHorseSound ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.isHorseSoundDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Has the horse been lame whilst in your care?"
							value={data.hasHorseBeenLame ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.hasHorseBeenLameDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Has the horse been attended to by a veterinarian whilst in your care?"
							value={data.hasHorseBeenAttentded ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.hasHorseBeenAttentdedDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Has the horse received medication whilst in your care?"
							value={data.hasHorseReceivedMedication ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Please provide details"
							value={data.hasHorseReceivedMedicationDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Is the horse good for the farrier?"
							value={data.isHorsedGood ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.isHorsedGoodDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Is the horse Shod?"
							value={data.isHorseShod ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.isHorseShodDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Has the horse been treated by a dentist?"
							value={data.hasHorseBeenTreatedDentist ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.hasHorseBeenTreatedDentistDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="What disciplines would this horse be suited to?"
							value={data.disciplines
								.map((disciplines) => disciplines.value)
								.join(', ')}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Other details"
							value={data.disciplinesdOtherDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="What type of rider will this horse be suitable for?"
							value={data.riderTypes
								.map((riderTypes) => riderTypes.value)
								.join(', ')}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Other details"
							value={data.riderTypedOtherDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Are there issues that will require ongoing attention (eg joint supplements for arthritis/ medications)"
							value={data.isRequiredOngoingAttention ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="Additional details"
							value={data.isRequiredOngoingAttentionDetails}
						/>
					</Col>
					<Col xs={6}>
						<FormLabelField
							title="What is your intended rehoming / sale Price range?"
							value={data.priceRange?.value}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Thoroughbred Details"
				mobile={mobile}
				styles={{ marginBottom: 0, marginTop: 0, borderRadius: '0px' }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<FormLabelField title="Racing Name" value={data.name} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Age" value={data.horse?.age} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Colour" value={data.horse?.colour} />
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Contact Details"
				mobile={mobile}
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Contact Name"
							value={`${data.member?.firstName} ${data.member?.lastName}`}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Email" value={data.member?.email} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Number" value={data.member.phone} />
					</Col>
				</Row>
			</div>
			<AdminReviewFormApproval
				notes={notes}
				updateNotes={(value) => setNotes(value)}
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={data.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="resetExpressRehomeReportReviewForm"
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};
export default ResetExpressRehomeReportReviewInfo;
