import React, { useEffect, useState } from 'react';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import AppSyncService from '../../graphql/AppSyncService';
import axios from 'axios';
import {
	deleteImageFromS3,
	deleteImagesFromS3,
	getMemberData,
	getResetExpressRehomeAssessmentForm,
	getResetExpressRehomeAssessmentReferences,
	getUserImage,
	saveResetExpressRehomeAssessmentForm,
	saveResetReportingImage,
	submitResetExpressRehomeAssessmentForReview,
	uploadImageToS3
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';
import { useToast } from '../../hooks/useToast';
import { APPROVED, CommonKeys, REVIEW } from '../../utils/constants/index';
import {
	RESETWeeksAvailable,
	checkIsSmallScreen,
	extractLookUpOptionsOther,
	sortArray
} from '../../utils/helpers';
import { logToServer } from '../../utils/logger';
import ConfirmationModal from '../ConfirmationModal';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import FormDropDown from '../FormDropDown';
import FormLabelField from '../FormLabelField';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import MessageBar from '../MessageBar';
import PrimaryButton from '../PrimaryButton';
import ResizingTextArea from '../ResizingTextArea';
import Toggle from '../Toggle';
import { HorseManagementKeys } from './../../utils/constants/index';
import useStyles from './style';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import Lightbox from 'react-image-lightbox';
import UploadPhoto from '../UploadPhoto';

const ResetExpressRehomeAssessmentForm = ({
	horseData,
	horseCode
	// reload
}) => {
	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const { currentUser } = useAuth();
	const [horsePhotos, setHorsePhotos] = useState([]);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const classes = useStyles();
	const { handleSubmit } = useForm({
		mode: 'onSubmit'
	});
	const [confirmationModal, setConfirmationModal] = useState(false);

	const [weekId, setWeekId] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? 51
			: horseData?.lastSubmittedForm?.weekId
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);
	const [weekIdText, setWeekIdText] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);

	// @NOTE - Form fields -- START
	const [priceRange, setpriceRange] = useState(0);
	const [horseTemperamentDesc, sethorseTemperamentDesc] = useState(null);
	const [horseMovementDesc, sethorseMovementDesc] = useState(null);
	const [groundWorkdOtherDetails, setgroundWorkdOtherDetails] = useState('');
	const [isHorseTieUpQuietly, setisHorseTieUpQuietly] = useState(false);
	const [isHorseTieUpQuietlyDetails, setisHorseTieUpQuietlyDetails] = useState(
		''
	);
	const [doesHorseStand, setdoesHorseStand] = useState(false);
	const [doesHorseStandDetails, setdoesHorseStandDetails] = useState('');
	const [
		riddenTrainingExposuredOtherDetails,
		setriddenTrainingExposuredOtherDetails
	] = useState('');
	const [
		riddenExposureRetrainingdOtherDetails,
		setriddenExposureRetrainingdOtherDetails
	] = useState('');
	const [hasHorseReceivedTraining, sethasHorseReceivedTraining] = useState(
		false
	);
	const [
		hasHorseReceivedTrainingDetails,
		sethasHorseReceivedTrainingDetails
	] = useState('');
	const [hasHorseTransported, sethasHorseTransported] = useState(false);
	const [hasHorseTransportedDetails, sethasHorseTransportedDetails] = useState(
		''
	);
	const [isHorseSound, setisHorseSound] = useState(false);
	const [isHorseSoundDetails, setisHorseSoundDetails] = useState('');
	const [hasHorseBeenLame, sethasHorseBeenLame] = useState(false);
	const [hasHorseBeenLameDetails, sethasHorseBeenLameDetails] = useState('');
	const [hasHorseBeenAttentded, sethasHorseBeenAttentded] = useState(false);
	const [
		hasHorseBeenAttentdedDetails,
		sethasHorseBeenAttentdedDetails
	] = useState('');
	const [hasHorseReceivedMedication, sethasHorseReceivedMedication] = useState(
		false
	);
	const [
		hasHorseReceivedMedicationDetails,
		sethasHorseReceivedMedicationDetails
	] = useState('');
	const [isHorsedGood, setisHorsedGood] = useState(false);
	const [isHorsedGoodDetails, setisHorsedGoodDetails] = useState('');
	const [isHorseShod, setisHorseShod] = useState(false);
	const [isHorseShodDetails, setisHorseShodDetails] = useState('');
	const [hasHorseBeenTreatedDentist, sethasHorseBeenTreatedDentist] = useState(
		false
	);
	const [
		hasHorseBeenTreatedDentistDetails,
		sethasHorseBeenTreatedDentistDetails
	] = useState('');
	const [disciplinesdOtherDetails, setdisciplinesdOtherDetails] = useState('');
	const [riderTypedOtherDetails, setriderTypedOtherDetails] = useState('');
	const [isRequiredOngoingAttention, setisRequiredOngoingAttention] = useState(
		false
	);
	const [
		isRequiredOngoingAttentionDetails,
		setisRequiredOngoingAttentionDetails
	] = useState('');
	const [groundWork, setgroundWork] = useState([]);
	const [riddenTrainingExposure, setriddenTrainingExposure] = useState([]);
	const [riddenExposureRetraining, setriddenExposureRetraining] = useState([]);
	const [riderTypes, setriderTypes] = useState([]);
	const [disciplines, setdisciplines] = useState([]);
	// @NOTE - Form fields -- END
	const [disciplineOptions, setDisciplineOptions] = useState([]);
	const [priceRangeOptions, setPriceRangeOptions] = useState([]);
	const [groundWorkOptions, setGroundWorkOptions] = useState([]);
	const [riderTypeOptions, setRiderTypeOptions] = useState([]);
	const [
		riddenExposureRetrainingOptions,
		setRiddenExposureRetrainingOptions
	] = useState([]);
	const [
		riddenTrainingExposureOptions,
		setRiddenTrainingExposureOptions
	] = useState([]);

	// @END
	const { addToast } = useToast();
	const [isLoading, setIsLoading] = useState(false);
	const [currentUserMemberId, setCurrentUserMemberId] = useState(null);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [disableSubmitButton, setDisableSubmitbutton] = useState(true);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [isRehomeDetailsLoaded, setIsRehomeDetailsLoaded] = useState(false);
	const [shouldValidate, setShouldValidate] = useState(false);

	const debouncedWeekNumber = useDebounce(weekIdText, 700);
	let isValidationErrors = false;
	/* ------------------------------- validation ------------------------------- */
	const validateFields = () => {
		let errors = {};
		if (!weekId || weekId == 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			errors = {
				...errors,
				weekId: {
					message: 'You must select a week.'
				}
			};
		}

		if (weekId == 51 && (!weekIdText || isNaN(weekIdText))) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			errors = {
				...errors,
				weekIdText: {
					message: 'You must enter a week number (numeric only).'
				}
			};
		}

		if (!horseTemperamentDesc || horseTemperamentDesc.length === 0)
			errors = {
				...errors,
				horseTemperamentDesc: { message: 'This field is required' }
			};
		if (!horseMovementDesc || horseMovementDesc.length === 0)
			errors = {
				...errors,
				horseMovementDesc: { message: 'This field is required' }
			};
		if (!groundWork || groundWork.length === 0)
			errors = { ...errors, groundWork: { message: 'This field is required' } };
		if (
			groundWork.length > 0 &&
			groundWork.some((i) => i.label === 'Other') &&
			(!groundWorkdOtherDetails || groundWorkdOtherDetails.length === 0)
		)
			errors = {
				...errors,
				groundWorkdOtherDetails: { message: 'This field is required' }
			};
		if (!riddenTrainingExposure || riddenTrainingExposure.length === 0)
			errors = {
				...errors,
				riddenTrainingExposure: { message: 'This field is required' }
			};
		if (
			riddenTrainingExposure.length > 0 &&
			riddenTrainingExposure.some((i) => i.label === 'Other') &&
			(!riddenTrainingExposuredOtherDetails ||
				riddenTrainingExposuredOtherDetails.length === 0)
		)
			errors = {
				...errors,
				riddenTrainingExposuredOtherDetails: {
					message: 'This field is required'
				}
			};
		if (!riddenExposureRetraining || riddenExposureRetraining.length === 0)
			errors = {
				...errors,
				riddenExposureRetraining: { message: 'This field is required' }
			};
		if (
			riddenExposureRetraining.length > 0 &&
			riddenExposureRetraining.some((i) => i.label === 'Other') &&
			(!riddenExposureRetrainingdOtherDetails ||
				riddenExposureRetrainingdOtherDetails.length === 0)
		)
			errors = {
				...errors,
				riddenExposureRetrainingdOtherDetails: {
					message: 'This field is required'
				}
			};
		if (
			!hasHorseTransported &&
			(!hasHorseTransportedDetails || hasHorseTransportedDetails.length === 0)
		)
			errors = {
				...errors,
				hasHorseTransportedDetails: { message: 'This field is required' }
			};
		if (
			!isHorseSound &&
			(!isHorseSoundDetails || isHorseSoundDetails.length === 0)
		)
			errors = {
				...errors,
				isHorseSoundDetails: { message: 'This field is required' }
			};
		if (
			hasHorseBeenLame &&
			(!hasHorseBeenLameDetails || hasHorseBeenLameDetails.length === 0)
		)
			errors = {
				...errors,
				hasHorseBeenLameDetails: { message: 'This field is required' }
			};
		if (
			hasHorseBeenAttentded &&
			(!hasHorseBeenAttentdedDetails ||
				hasHorseBeenAttentdedDetails.length === 0)
		)
			errors = {
				...errors,
				hasHorseBeenAttentdedDetails: { message: 'This field is required' }
			};
		if (
			hasHorseReceivedMedication &&
			(!hasHorseReceivedMedicationDetails ||
				hasHorseReceivedMedicationDetails.length === 0)
		)
			errors = {
				...errors,
				hasHorseReceivedMedicationDetails: { message: 'This field is required' }
			};
		if (
			!isHorseShod &&
			(!isHorseShodDetails || isHorseShodDetails.length === 0)
		)
			errors = {
				...errors,
				isHorseShodDetails: { message: 'This field is required' }
			};
		if (!riderTypes || riderTypes.length === 0)
			errors = {
				...errors,
				riderTypes: { message: 'This field is required' }
			};
		if (
			riderTypes.length > 0 &&
			riderTypes.some((i) => i.label === 'Other') &&
			(!riderTypedOtherDetails || riderTypedOtherDetails.length === 0)
		)
			errors = {
				...errors,
				riderTypedOtherDetails: {
					message: 'This field is required'
				}
			};
		if (
			isRequiredOngoingAttention &&
			(!isRequiredOngoingAttentionDetails ||
				isRequiredOngoingAttentionDetails.length === 0)
		)
			errors = {
				...errors,
				isRequiredOngoingAttentionDetails: { message: 'This field is required' }
			};
		if (!disciplines || disciplines.length === 0)
			errors = {
				...errors,
				disciplines: { message: 'This field is required' }
			};
		if (
			disciplines.length > 0 &&
			disciplines.some((i) => i.label === 'Other') &&
			(!disciplinesdOtherDetails || disciplinesdOtherDetails.length === 0)
		)
			errors = {
				...errors,
				disciplinesdOtherDetails: {
					message: 'This field is required'
				}
			};
		if (!priceRange || priceRange == 0)
			errors = {
				...errors,
				priceRange: { message: 'This field is required' }
			};

		if (horsePhotos.length === 0) {
			errors = {
				...errors,
				horsePhotos: { message: 'This field is required' }
			};
		}
		if (errors) {
			setDisableSubmitbutton(true);
		}
		setUncontrolledErrors(errors);
		return errors;
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	useEffect(() => {
		if (
			uncontrolledErrors?.weekId?.message ||
			uncontrolledErrors?.weekIdText?.message
		) {
			validateFields();
		}
	}, [weekId, debouncedWeekNumber]);

	const validateUncontrolledErrors = () => {
		validateFields();
	};

	const handleOnSubmit = () => {
		const errors = Object.keys(validateFields());
		if (errors?.length > 0) {
			setDisableSubmitbutton(true);
		} else {
			handleSave(true);
		}
	};

	const onSubmit = async () => {
		await validateUncontrolledErrors();
		if (
			Object.values(uncontrolledErrors).every((item) => item.message === null)
		) {
			// setIsQuarterFormValidated(true);
		}
	};

	const handleSave = async (isSubmitReport = false) => {
		validateUncontrolledErrors();
		const filteredSelectedDisciplines = disciplines.map(
			(discipline) => discipline.value
		);
		const filteredSelectedGroundWorks = groundWork.map(
			(discipline) => discipline.value
		);
		const filteredSelectedRiderTypes = riderTypes.map(
			(discipline) => discipline.value
		);
		const filteredSelectedRiddenTrainingExposure = riddenTrainingExposure.map(
			(discipline) => discipline.value
		);
		const filteredSelectedRiddenExposureRetraining = riddenExposureRetraining.map(
			(discipline) => discipline.value
		);

		let acknowledgedFormData = {
			memberId: currentUserMemberId,
			horseCode,
			weekId: weekId == 0 ? null : weekId >= 51 ? weekIdText : weekId,
			priceRangeId: priceRange ? Number(priceRange) : null,
			horseTemperamentDesc,
			horseMovementDesc,
			groundWorkdOtherDetails,
			isHorseTieUpQuietly,
			isHorseTieUpQuietlyDetails,
			doesHorseStand,
			doesHorseStandDetails,
			riddenTrainingExposuredOtherDetails,
			riddenExposureRetrainingdOtherDetails,
			hasHorseReceivedTraining,
			hasHorseReceivedTrainingDetails,
			hasHorseTransported,
			hasHorseTransportedDetails,
			isHorseSound,
			isHorseSoundDetails,
			hasHorseBeenLame,
			hasHorseBeenLameDetails,
			hasHorseBeenAttentded,
			hasHorseBeenAttentdedDetails,
			hasHorseReceivedMedication,
			hasHorseReceivedMedicationDetails,
			isHorsedGood,
			isHorsedGoodDetails,
			isHorseShod,
			isHorseShodDetails,
			hasHorseBeenTreatedDentist,
			hasHorseBeenTreatedDentistDetails,
			disciplinesdOtherDetails,
			riderTypedOtherDetails,
			isRequiredOngoingAttention,
			isRequiredOngoingAttentionDetails,
			groundWork: filteredSelectedGroundWorks,
			riddenTrainingExposure: filteredSelectedRiddenTrainingExposure,
			riddenExposureRetraining: filteredSelectedRiddenExposureRetraining,
			riderTypes: filteredSelectedRiderTypes,
			disciplines: filteredSelectedDisciplines
		};

		setIsLoading(true);
		AppSyncService.execute(saveResetExpressRehomeAssessmentForm, {
			input: acknowledgedFormData
		}).then((data) => {
			if (data?.data?.saveResetExpressRehomeAssessmentForm !== null) {
				let photoArrays = [{ name: 'horsePhotos', arr: horsePhotos }];
				photoArrays.forEach((photoArray, index) => {
					photoArray.arr.map((photo) => {
						AppSyncService.execute(saveResetReportingImage, {
							filename: photo.filename,
							key: photo.key,
							reportType: 'ResetExpressRehome',
							reportId: data.data.saveResetExpressRehomeAssessmentForm.id,
							type: photoArrays[index].name
						});
					});
				});
				setIsShowErrorMessages(true);
				if (!isSubmitReport) {
					// reload(); //@TODO
					addToast({
						Message: 'Draft Saved',
						IsSuccess: true
					});
				} else {
					AppSyncService.execute(submitResetExpressRehomeAssessmentForReview, {
						memberId: currentUserMemberId,
						horseCode: horseCode,
						weekId: weekId >= 51 ? weekIdText : weekId
					}).then((data) => {
						if (data?.data?.submitResetExpressRehomeAssessmentForReview) {
							addToast({
								Message: 'Ready for Rehome Assessment Form Submitted',
								IsSuccess: true
							});
							logToServer(
								currentUser?.UserId,
								data,
								'Ready for Rehome Assessment Report form Submission Submitted and Passed'
							);
							setIsReadOnly(true);
						} else {
							addToast({
								Message:
									'An error submitting. Racing Victoria are investigating.',
								IsSuccess: false
							});
							logToServer(
								currentUser?.UserId,
								data,
								'Ready for Rehome Assessment Report form Submission Submitted and Failed'
							);
						}
					});
				}
			}
		});

		if (!isSubmitReport) {
			if (!isValidationErrors) {
				setDisableSubmitbutton(false);
			} else {
				setDisableSubmitbutton(false);
			}
		}
		setIsLoading(false);
	};

	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		fetchMemberData();
	}, []);

	const fetchMemberData = async () => {
		const result = await AppSyncService.execute(getMemberData, {
			externalId: currentUser.UserId
		});

		if (result?.data?.getMember) {
			setCurrentUserMemberId(result.data.getMember.id);
		}
	};

	useEffect(() => {
		if (shouldValidate) {
			const errors = Object.keys(validateFields());
			if (errors?.length > 0) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	useEffect(() => {
		if (isRehomeDetailsLoaded && weekId != 0) {
			const errors = Object.keys(validateFields());
			if (errors?.length > 0) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
		}
		setIsRehomeDetailsLoaded(false);
	}, [isRehomeDetailsLoaded]);

	useEffect(() => {
		setIsLoading(true);
		if (currentUser !== null) {
			AppSyncService.execute(getResetExpressRehomeAssessmentReferences).then(
				(result) => {
					if (result.data) {
						let SortedDicipline = sortArray(
							result.data.listSpecialtyDisciplines,
							true
						);

						setDisciplineOptions(SortedDicipline);

						setGroundWorkOptions(
							extractLookUpOptionsOther(result, 'listGroundWorks')
						);
						setRiddenTrainingExposureOptions(
							extractLookUpOptionsOther(result, 'listRiddenTrainingExposure')
						);
						setRiddenExposureRetrainingOptions(
							extractLookUpOptionsOther(result, 'listRiddenExposureRetraining')
						);
						setRiderTypeOptions(
							extractLookUpOptionsOther(result, 'listRiderTypes')
						);
						setPriceRangeOptions(
							extractLookUpOptionsOther(result, 'listRehomePriceRanges')
						);
						setPriceRangeOptions((prev) => [{ id: 0, value: '' }].concat(prev));
					}
				}
			);
		}
		setIsLoading(false);
	}, []);

	useEffect(() => {
		if (
			(weekId && weekId != 0 && weekId != 51) ||
			(weekId == 51 && weekIdText && weekIdText != 0 && !isNaN(weekIdText))
		) {
			setDisableSubmitbutton(true);
			loadRehomeAssessmentForm();
		}
	}, [weekId, debouncedWeekNumber]);

	const loadRehomeAssessmentForm = () => {
		const input = {
			memberExternalId: currentUser?.UserId,
			horseCode,
			weekId: weekId >= 51 ? +weekIdText : weekId
		};
		clearFields();
		try {
			AppSyncService.execute(getResetExpressRehomeAssessmentForm, input).then(
				(result) => {
					if (result?.data?.getResetExpressRehomeAssessmentForm) {
						const initialData =
							result?.data?.getResetExpressRehomeAssessmentForm;

						if (initialData?.status == null) {
							setIsShowErrorMessages(false);
						} else {
							setIsShowErrorMessages(true);
						}

						setHorsePhotos(initialData.photosOfTheHorse ?? []);
						setpriceRange(initialData.priceRange.id ?? 0);
						sethorseTemperamentDesc(initialData.horseTemperamentDesc ?? '');
						sethorseMovementDesc(initialData.horseMovementDesc ?? '');
						setgroundWorkdOtherDetails(
							initialData.groundWorkdOtherDetails ?? ''
						);
						setisHorseTieUpQuietly(initialData.isHorseTieUpQuietly ?? false);
						setisHorseTieUpQuietlyDetails(
							initialData.isHorseTieUpQuietlyDetails ?? ''
						);
						setdoesHorseStand(initialData.doesHorseStand ?? false);
						setdoesHorseStandDetails(initialData.doesHorseStandDetails ?? '');
						setriddenTrainingExposuredOtherDetails(
							initialData.riddenTrainingExposuredOtherDetails ?? ''
						);
						setriddenExposureRetrainingdOtherDetails(
							initialData.riddenExposureRetrainingdOtherDetails ?? ''
						);
						sethasHorseReceivedTraining(
							initialData.hasHorseReceivedTraining ?? false
						);
						sethasHorseReceivedTrainingDetails(
							initialData.hasHorseReceivedTrainingDetails ?? ''
						);
						sethasHorseTransported(initialData.hasHorseTransported ?? false);
						sethasHorseTransportedDetails(
							initialData.hasHorseTransportedDetails ?? ''
						);
						setisHorseSound(initialData.isHorseSound ?? false);
						setisHorseSoundDetails(initialData.isHorseSoundDetails ?? '');
						sethasHorseBeenLame(initialData.hasHorseBeenLame ?? false);
						sethasHorseBeenLameDetails(
							initialData.hasHorseBeenLameDetails ?? ''
						);
						sethasHorseBeenAttentded(
							initialData.hasHorseBeenAttentded ?? false
						);
						sethasHorseBeenAttentdedDetails(
							initialData.hasHorseBeenAttentdedDetails ?? ''
						);
						sethasHorseReceivedMedication(
							initialData.hasHorseReceivedMedication ?? false
						);
						sethasHorseReceivedMedicationDetails(
							initialData.hasHorseReceivedMedicationDetails ?? ''
						);
						setisHorsedGood(initialData.isHorsedGood ?? false);
						setisHorsedGoodDetails(initialData.isHorsedGoodDetails ?? '');
						setisHorseShod(initialData.isHorseShod ?? false);
						setisHorseShodDetails(initialData.isHorseShodDetails ?? '');
						sethasHorseBeenTreatedDentist(
							initialData.hasHorseBeenTreatedDentist ?? false
						);
						sethasHorseBeenTreatedDentistDetails(
							initialData.hasHorseBeenTreatedDentistDetails ?? ''
						);
						setdisciplinesdOtherDetails(
							initialData.disciplinesdOtherDetails ?? ''
						);
						setriderTypedOtherDetails(initialData.riderTypedOtherDetails ?? '');
						setisRequiredOngoingAttention(
							initialData.isRequiredOngoingAttention ?? false
						);
						setisRequiredOngoingAttentionDetails(
							initialData.isRequiredOngoingAttentionDetails ?? ''
						);

						if (initialData.disciplines.length > 0) {
							const disciplines = initialData.disciplines.map((reason) => ({
								value: reason.id,
								label: reason.value
							}));
							setdisciplines(disciplines);
						}
						if (initialData.groundWork.length > 0) {
							const groundWork = initialData.groundWork.map((reason) => ({
								value: reason.id,
								label: reason.value
							}));
							setgroundWork(groundWork);
						}
						if (initialData.riddenTrainingExposure.length > 0) {
							const riddenTrainingExposure = initialData.riddenTrainingExposure.map(
								(reason) => ({
									value: reason.id,
									label: reason.value
								})
							);
							setriddenTrainingExposure(riddenTrainingExposure);
						}
						if (initialData.riddenExposureRetraining.length > 0) {
							const riddenExposureRetraining = initialData.riddenExposureRetraining.map(
								(reason) => ({
									value: reason.id,
									label: reason.value
								})
							);
							setriddenExposureRetraining(riddenExposureRetraining);
						}
						if (initialData.riderTypes.length > 0) {
							const riderTypes = initialData.riderTypes.map((reason) => ({
								value: reason.id,
								label: reason.value
							}));
							setriderTypes(riderTypes);
						}

						setIsReadOnly(
							initialData.status === REVIEW || initialData.status === APPROVED
						);
						setDisableSubmitbutton(true);
						setUncontrolledErrors({});
					}
					setIsRehomeDetailsLoaded(true);
				}
			);
		} catch (error) {
			logToServer(currentUser?.UserId, error, 'Form fetch failed');
			addToast({ Message: 'Unable to fetch form', IsSuccess: false });
		}
	};

	const clearFields = () => {
		setpriceRange(0);
		sethorseTemperamentDesc('');
		sethorseMovementDesc('');
		setgroundWorkdOtherDetails('');
		setisHorseTieUpQuietly(false);
		setisHorseTieUpQuietlyDetails('');
		setdoesHorseStand(false);
		setdoesHorseStandDetails('');
		setriddenTrainingExposuredOtherDetails('');
		setriddenExposureRetrainingdOtherDetails('');
		sethasHorseReceivedTraining(false);
		sethasHorseReceivedTrainingDetails('');
		sethasHorseTransported(false);
		sethasHorseTransportedDetails('');
		setisHorseSound(false);
		setisHorseSoundDetails('');
		sethasHorseBeenLame(false);
		sethasHorseBeenLameDetails('');
		sethasHorseBeenAttentded(false);
		sethasHorseBeenAttentdedDetails('');
		sethasHorseReceivedMedication(false);
		sethasHorseReceivedMedicationDetails('');
		setisHorsedGood(false);
		setisHorsedGoodDetails('');
		setisHorseShod(false);
		setisHorseShodDetails('');
		sethasHorseBeenTreatedDentist(false);
		sethasHorseBeenTreatedDentistDetails('');
		setdisciplinesdOtherDetails('');
		setriderTypedOtherDetails('');
		setisRequiredOngoingAttention(false);
		setisRequiredOngoingAttentionDetails('');
		setgroundWork([]);
		setriddenTrainingExposure([]);
		setriddenExposureRetraining([]);
		setriderTypes([]);
		setdisciplines([]);
	};

	const handleCancel = () => {
		deleteAllPhotos();
		setWeekId(0);
		setWeekIdText(0);
		clearFields();
		setConfirmationModal(false);
	};

	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key !== img.key));
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
				setShouldValidate(true);
			}
		);
	};

	const deleteAllPhotos = () => {
		let listOfKeys = [];
		[horsePhotos].forEach((photoArray) => {
			photoArray.map((d) => {
				listOfKeys.push(d.key);
			});
		});
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					setHorsePhotos([]);
				}
			});
		}
	};

	const renderAssessmentFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								How many weeks has the horse been in retraining?*
							</div>
							<FormDropDown
								items={RESETWeeksAvailable()}
								selectedId={weekId}
								onChange={(e) => {
									setWeekId(e.target.value);
									setShouldValidate(true);
								}}
								id={HorseManagementKeys.WEEK_NUMBER_DROPDOWN}
								showBlank
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.weekId?.message}
								</span>
							)}
						</div>
					</Col>
					{+weekId === 51 && (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Week Number*</div>
								<FormTextField
									id={HorseManagementKeys.WEEK_NUMBER_TEXT}
									value={weekIdText}
									onChange={(e) => {
										setWeekIdText(e.target.value);
										setShouldValidate(true);
									}}
									styles={{ height: 38 }}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.weekIdText?.message}
									</span>
								)}
							</div>
						</Col>
					)}
					<Col xs={12} lg={6}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Photos of the horse (jpg, png)*
							</div>
							<UploadPhoto
								id={`ResetExpressRehome-photos`}
								formats={['png', 'jpeg']}
								labelName="Upload Photos of the horse"
								error={uploadError}
								data={horsePhotos}
								sizeLimitMB={5}
								numberOfImages={5}
								success={(e) => photosUpload(e, setHorsePhotos)}
								getImage={getImage}
								removeImage={(e) => removePhoto(e, horsePhotos, setHorsePhotos)}
								canEdit={!isReadOnly}
							/>
						</div>
						<Row>
							<span style={{ fontStyle: 'italic' }}>
								Please ensure clear photos, full body, front and rear, taken on
								or as close as possible to this assessment being completed.
							</span>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors?.horsePhotos?.message}
								</span>
							)}
						</Row>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="horseTemperamentDesc"
									title="How would you describe the horse's temperament?*"
									id="resetexpress-horseTemperamentDesc"
									value={horseTemperamentDesc}
									onChange={(e) => {
										sethorseTemperamentDesc(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.horseTemperamentDesc?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="horseMovementDesc"
									title="How would you describe the horse's movement?*"
									id="resetexpress-horseMovementDesc"
									value={horseMovementDesc}
									onChange={(e) => {
										sethorseMovementDesc(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.horseMovementDesc?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<FormLabelField
								title={`What ground work has the horse completed?*`}
								value={groundWork.map((g) => g.label).join()}
								style={{ padding: 0 }}
							/>
						) : (
							<div
								className={classes.fieldContainerPaddingTopDeceasedMultiSelect}
							>
								<MultiSelectInputField
									idPrefix={`groundwork`}
									fieldLabel={`What ground work has the horse completed?*`}
									options={groundWorkOptions}
									selectedIds={groundWork}
									onSelectChange={(vals) => {
										setgroundWork(vals);
										setShouldValidate(true);
									}}
									otherDisabled={true}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.groundWork?.message}
									</span>
								)}
							</div>
						)}
					</Col>
					{groundWork.map((i, index) => {
						if (i.label === 'Other') {
							return (
								<Col key={index} xs={12} lg={8}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											<ResizingTextArea
												rows={2}
												isShowRemainingChars={false}
												name="groundWorkOtherDetails"
												title="Other details*"
												id="resetexpress-groundWorkOtherDetails"
												value={groundWorkdOtherDetails}
												onChange={(e) => {
													setgroundWorkdOtherDetails(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												charLengthStyle={{
													fontWeight: 'normal'
												}}
												styles={{
													marginLeft: 0,
													marginRight: 0,
													padding: 0
												}}
												titleStyles={{ fontSize: '14px' }}
												canEdit={!isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.groundWorkdOtherDetails?.message}
											</span>
										)}
									</div>
								</Col>
							);
						}
					})}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Can the horse tie up quietly?"
										value={isHorseTieUpQuietly ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Can the horse tie up quietly?
								</div>
								<Toggle
									id={HorseManagementKeys.TIE_UP}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisHorseTieUpQuietly(value);
										setShouldValidate(true);
									}}
									value={isHorseTieUpQuietly}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					<Col xs={12} lg={8}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="isHorseTieUpQuietlyDetails"
									title="Additional details"
									id="resetexpress-isHorseTieUpQuietlyDetails"
									value={isHorseTieUpQuietlyDetails}
									onChange={(e) => {
										setisHorseTieUpQuietlyDetails(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.isHorseTieUpQuietlyDetails?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Does the horse stand whilst being mounted?"
										value={doesHorseStand ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Does the horse stand whilst being mounted?
								</div>
								<Toggle
									id={HorseManagementKeys.TIE_UP}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setdoesHorseStand(value);
										setShouldValidate(true);
									}}
									value={doesHorseStand}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					<Col xs={12} lg={8}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="doesHorseStandDetails"
									title="Additional details"
									id="resetexpress-doesHorseStandDetails"
									value={doesHorseStandDetails}
									onChange={(e) => {
										setdoesHorseStandDetails(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.doesHorseStandDetails?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<FormLabelField
								title={`What ridden training / exposure has the horse received?*`}
								value={riddenTrainingExposure.map((g) => g.label).join()}
								style={{ padding: 0 }}
							/>
						) : (
							<div
								className={classes.fieldContainerPaddingTopDeceasedMultiSelect}
							>
								<MultiSelectInputField
									idPrefix={`riddenTrainingExposure`}
									fieldLabel={`What ridden training / exposure has the horse received?*`}
									options={riddenTrainingExposureOptions}
									selectedIds={riddenTrainingExposure}
									onSelectChange={(vals) => {
										setriddenTrainingExposure(vals);
										setShouldValidate(true);
									}}
									otherDisabled={true}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.riddenTrainingExposure?.message}
									</span>
								)}
							</div>
						)}
					</Col>
					{riddenTrainingExposure.map((i, index) => {
						if (i.label === 'Other') {
							return (
								<Col key={index} xs={12} lg={8}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											<ResizingTextArea
												rows={2}
												isShowRemainingChars={false}
												name="riddenTrainingExposuredOtherDetails"
												title="Other details*"
												id="resetexpress-riddenTrainingExposuredOtherDetails"
												value={riddenTrainingExposuredOtherDetails}
												onChange={(e) => {
													setriddenTrainingExposuredOtherDetails(
														e.target.value
													);
													setShouldValidate(true);
												}}
												maxChars={500}
												charLengthStyle={{
													fontWeight: 'normal'
												}}
												styles={{
													marginLeft: 0,
													marginRight: 0,
													padding: 0
												}}
												titleStyles={{ fontSize: '14px' }}
												canEdit={!isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{
													uncontrolledErrors.riddenTrainingExposuredOtherDetails
														?.message
												}
											</span>
										)}
									</div>
								</Col>
							);
						}
					})}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<FormLabelField
								title={`What ridden exposure has the horse received whilst in retraining?*`}
								value={riddenExposureRetraining.map((g) => g.label).join()}
								style={{ padding: 0 }}
							/>
						) : (
							<div
								className={classes.fieldContainerPaddingTopDeceasedMultiSelect}
							>
								<MultiSelectInputField
									idPrefix={`riddenExposureRetraining`}
									fieldLabel={`What ridden exposure has the horse received whilst in retraining?*`}
									options={riddenExposureRetrainingOptions}
									selectedIds={riddenExposureRetraining}
									onSelectChange={(vals) => {
										setriddenExposureRetraining(vals);
										setShouldValidate(true);
									}}
									otherDisabled={true}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.riddenExposureRetraining?.message}
									</span>
								)}
							</div>
						)}
					</Col>
					{riddenExposureRetraining.map((i, index) => {
						if (i.label === 'Other') {
							return (
								<Col key={index} xs={12} lg={8}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											<ResizingTextArea
												rows={2}
												isShowRemainingChars={false}
												name="riddenExposureRetrainingdOtherDetails"
												title="Other details*"
												id="resetexpress-riddenExposureRetrainingdOtherDetails"
												value={riddenExposureRetrainingdOtherDetails}
												onChange={(e) => {
													setriddenExposureRetrainingdOtherDetails(
														e.target.value
													);
													setShouldValidate(true);
												}}
												maxChars={500}
												charLengthStyle={{
													fontWeight: 'normal'
												}}
												styles={{
													marginLeft: 0,
													marginRight: 0,
													padding: 0
												}}
												titleStyles={{ fontSize: '14px' }}
												canEdit={!isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{
													uncontrolledErrors
														.riddenExposureRetrainingdOtherDetails?.message
												}
											</span>
										)}
									</div>
								</Col>
							);
						}
					})}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse received any training relating to loading onto transport (ie float or truck)?"
										value={hasHorseReceivedTraining ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse received any training relating to loading onto
									transport (ie float or truck)?
								</div>
								<Toggle
									id={HorseManagementKeys.RECEIVED_TRAINING}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseReceivedTraining(value);
										setShouldValidate(true);
									}}
									value={hasHorseReceivedTraining}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					<Col xs={12} lg={8}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="hasHorseReceivedTrainingDetails"
									title="Additional details"
									id="resetexpress-hasHorseReceivedTrainingDetails"
									value={hasHorseReceivedTrainingDetails}
									onChange={(e) => {
										sethasHorseReceivedTrainingDetails(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.hasHorseReceivedTrainingDetails?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse been transported off the property?"
										value={hasHorseTransported ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse been transported off the property?
								</div>
								<Toggle
									id={HorseManagementKeys.TRANSPORTED}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseTransported(value);
										setShouldValidate(true);
									}}
									value={hasHorseTransported}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{!hasHorseTransported && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="hasHorseTransportedDetails"
										title="Additional details*"
										id="resetexpress-hasHorseTransportedDetails"
										value={hasHorseTransportedDetails}
										onChange={(e) => {
											sethasHorseTransportedDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.hasHorseTransportedDetails?.message}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Is the horse currently sound?"
										value={isHorseSound ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Is the horse currently sound?
								</div>
								<Toggle
									id={HorseManagementKeys.SOUND}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisHorseSound(value);
										setShouldValidate(true);
									}}
									value={isHorseSound}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{!isHorseSound && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="isHorseSoundDetails"
										title="Additional details*"
										id="resetexpress-isHorseSoundDetails"
										value={isHorseSoundDetails}
										onChange={(e) => {
											setisHorseSoundDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.isHorseSoundDetails?.message}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse been lame whilst in your care?"
										value={hasHorseBeenLame ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse been lame whilst in your care?
								</div>
								<Toggle
									id={HorseManagementKeys.LAME}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseBeenLame(value);
										setShouldValidate(true);
									}}
									value={hasHorseBeenLame}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{hasHorseBeenLame && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="hasHorseBeenLameDetails"
										title="Please provide details of situation including length of time and any veterinarian care*"
										id="resetexpress-hasHorseBeenLameDetails"
										value={hasHorseBeenLameDetails}
										onChange={(e) => {
											sethasHorseBeenLameDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.hasHorseBeenLameDetails?.message}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse been attended to by a veterinarian whilst in your care?"
										value={hasHorseBeenAttentded ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse been attended to by a veterinarian whilst in
									your care?
								</div>
								<Toggle
									id={HorseManagementKeys.ATTENDED}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseBeenAttentded(value);
										setShouldValidate(true);
									}}
									value={hasHorseBeenAttentded}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{hasHorseBeenAttentded && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="hasHorseBeenAttentdedDetails"
										title="Additional details*"
										id="resetexpress-hasHorseBeenAttentdedDetails"
										value={hasHorseBeenAttentdedDetails}
										onChange={(e) => {
											sethasHorseBeenAttentdedDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.hasHorseBeenAttentdedDetails?.message}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse received medication whilst in your care?"
										value={hasHorseReceivedMedication ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse received medication whilst in your care?
								</div>
								<Toggle
									id={HorseManagementKeys.RECEIVED_ANY_TREATMENTS}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseReceivedMedication(value);
										setShouldValidate(true);
									}}
									value={hasHorseReceivedMedication}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{hasHorseReceivedMedication && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="hasHorseReceivedMedicationDetails"
										title="Please provide details*"
										id="resetexpress-hasHorseReceivedMedicationDetails"
										value={hasHorseReceivedMedicationDetails}
										onChange={(e) => {
											sethasHorseReceivedMedicationDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{
											uncontrolledErrors.hasHorseReceivedMedicationDetails
												?.message
										}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Is the horse good for the farrier?"
										value={isHorsedGood ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Is the horse good for the farrier?
								</div>
								<Toggle
									id={HorseManagementKeys.RECEIVED_ANY_TREATMENTS}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisHorsedGood(value);
										setShouldValidate(true);
									}}
									value={isHorsedGood}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					<Col xs={12} lg={8}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="isHorsedGoodDetails"
									title="Additional details"
									id="resetexpress-isHorsedGoodDetails"
									value={isHorsedGoodDetails}
									onChange={(e) => {
										setisHorsedGoodDetails(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.isHorsedGoodDetails?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Is the horse Shod?"
										value={isHorseShod ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Is the horse Shod?</div>
								<Toggle
									id={HorseManagementKeys.SHOD}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisHorseShod(value);
										setShouldValidate(true);
									}}
									value={isHorseShod}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{!isHorseShod && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="isHorseShodDetails"
										title="Additional details*"
										id="resetexpress-isHorseShodDetails"
										value={isHorseShodDetails}
										onChange={(e) => {
											setisHorseShodDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.isHorseShodDetails?.message}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse been treated by a dentist?"
										value={hasHorseBeenTreatedDentist ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse been treated by a dentist?
								</div>
								<Toggle
									id={HorseManagementKeys.TREATED_DENTIST}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseBeenTreatedDentist(value);
										setShouldValidate(true);
									}}
									value={hasHorseBeenTreatedDentist}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					<Col xs={12} lg={8}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="hasHorseBeenTreatedDentistDetails"
									title="Additional details"
									id="resetexpress-hasHorseBeenTreatedDentistDetails"
									value={hasHorseBeenTreatedDentistDetails}
									onChange={(e) => {
										sethasHorseBeenTreatedDentistDetails(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{
										uncontrolledErrors.hasHorseBeenTreatedDentistDetails
											?.message
									}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<FormLabelField
								title={`What disciplines would this horse be suited to?*`}
								value={disciplines.map((g) => g.label).join()}
								style={{ padding: 0 }}
							/>
						) : (
							<div
								className={classes.fieldContainerPaddingTopDeceasedMultiSelect}
							>
								<MultiSelectInputField
									idPrefix={`disciplines`}
									fieldLabel={`What disciplines would this horse be suited to?*`}
									options={disciplineOptions}
									selectedIds={disciplines}
									onSelectChange={(vals) => {
										setdisciplines(vals);
										setShouldValidate(true);
									}}
									otherDisabled={true}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.disciplines?.message}
									</span>
								)}
							</div>
						)}
					</Col>
					{disciplines.map((i, index) => {
						if (i.label === 'Other') {
							return (
								<Col key={index} xs={12} lg={8}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											<ResizingTextArea
												rows={2}
												isShowRemainingChars={false}
												name="disciplinesOtherDetails"
												title="Other details*"
												id="resetexpress-disciplinesOtherDetails"
												value={disciplinesdOtherDetails}
												onChange={(e) => {
													setdisciplinesdOtherDetails(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												charLengthStyle={{
													fontWeight: 'normal'
												}}
												styles={{
													marginLeft: 0,
													marginRight: 0,
													padding: 0
												}}
												titleStyles={{ fontSize: '14px' }}
												canEdit={!isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.disciplinesdOtherDetails?.message}
											</span>
										)}
									</div>
								</Col>
							);
						}
					})}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<FormLabelField
								title={`What type of rider will this horse be suitable for?*`}
								value={riderTypes.map((g) => g.label).join()}
								style={{ padding: 0 }}
							/>
						) : (
							<div
								className={classes.fieldContainerPaddingTopDeceasedMultiSelect}
							>
								<MultiSelectInputField
									idPrefix={`riderTypes`}
									fieldLabel={`What type of rider will this horse be suitable for?*`}
									options={riderTypeOptions}
									selectedIds={riderTypes}
									onSelectChange={(vals) => {
										setriderTypes(vals);
										setShouldValidate(true);
									}}
									otherDisabled={true}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.riderTypes?.message}
									</span>
								)}
							</div>
						)}
					</Col>
					{riderTypes.map((i, index) => {
						if (i.label === 'Other') {
							return (
								<Col key={index} xs={12} lg={8}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											<ResizingTextArea
												rows={2}
												isShowRemainingChars={false}
												name="riderTypesOtherDetails"
												title="Other details*"
												id="resetexpress-riderTypesOtherDetails"
												value={riderTypedOtherDetails}
												onChange={(e) => {
													setriderTypedOtherDetails(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												charLengthStyle={{
													fontWeight: 'normal'
												}}
												styles={{
													marginLeft: 0,
													marginRight: 0,
													padding: 0
												}}
												titleStyles={{ fontSize: '14px' }}
												canEdit={!isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.riderTypedOtherDetails?.message}
											</span>
										)}
									</div>
								</Col>
							);
						}
					})}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Are there issues that will require ongoing attention (eg joint supplements for arthritis/ medications)?"
										value={isRequiredOngoingAttention ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Are there issues that will require ongoing attention (eg joint
									supplements for arthritis/ medications)?
								</div>
								<Toggle
									id={HorseManagementKeys.SHOD}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisRequiredOngoingAttention(value);
										setShouldValidate(true);
									}}
									value={isRequiredOngoingAttention}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{isRequiredOngoingAttention && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="isRequiredOngoingAttentionDetails"
										title="Additional details*"
										id="resetexpress-isRequiredOngoingAttentionDetails"
										value={isRequiredOngoingAttentionDetails}
										onChange={(e) => {
											setisRequiredOngoingAttentionDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{
											uncontrolledErrors.isRequiredOngoingAttentionDetails
												?.message
										}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								What is your intended rehoming / sale Price range?*
							</div>
							<FormDropDown
								showBlank
								id={HorseManagementKeys.PRICE_BRACKET}
								items={priceRangeOptions}
								selectedId={priceRange}
								onChange={(e) => {
									setpriceRange(e.target.value);
									setShouldValidate(true);
								}}
								disabled={isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.priceRange?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<div className={classes.container}>
			{isLoading ? (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			) : (
				<div className={classes.formContainer}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={classes.formHeading}>
							Ready for Rehome Assessment Report
						</div>
						<Container>
							<MessageBar>
								<div className={classes.topMessage}>
									We need more information for this. Please fill in and save all
									the fields below.
								</div>
							</MessageBar>
							<>{renderAssessmentFields()}</>
							{(!weekId || weekId == 0) && (
								<div className={classes.formFieldsContainer}>
									<Row>
										<Col xs={12}>
											<div className={classes.fieldReminder}>
												Click on the week number when you return to complete
												your Weekly Form
											</div>
										</Col>
									</Row>
								</div>
							)}
							<hr className={classes.IdentificationDivider}></hr>

							<div
								style={{
									display: 'flex',
									justifyContent: `${
										isReadOnly ? 'flex-end' : 'space-between'
									}`,
									alignItems: 'center'
								}}
							>
								{!isReadOnly && (
									<div
										id={CommonKeys.CLEAR_FORM}
										className={classes.clearFormLink}
										onClick={() => {
											setConfirmationModal(true);
										}}
									>
										Clear Form
									</div>
								)}
								<div
									className={
										isSmallScreen
											? classes.saveRequestMobile
											: classes.saveRequest
									}
								>
									<span className={isSmallScreen ? null : classes.saveButton}>
										<PrimaryButton
											id={CommonKeys.SAVE}
											isNonSubmit={false}
											style={{ minWidth: '170px' }}
											onClick={() => handleSave()}
											disabled={isReadOnly}
										>
											Save
										</PrimaryButton>
									</span>
									<span>
										<PrimaryButton
											id={HorseManagementKeys.SUBMIT_REPORT}
											isNonSubmit={false}
											disabled={disableSubmitButton || isReadOnly}
											style={{ minWidth: '170px' }}
											onClick={() => handleOnSubmit()}
										>
											Submit Report
										</PrimaryButton>
									</span>
								</div>
							</div>
						</Container>
						{imgSrcUrl && (
							<Lightbox
								mainSrc={imgSrcUrl}
								onCloseRequest={() => setImgSrcUrl(false)}
							/>
						)}
						{showImageUploadErrorModal && (
							<UploadPhotoErrorModal
								showErrorModal={showImageUploadErrorModal}
								closeModal={() => setShowImageUploadErrorModal(false)}
								isMinimumUploadSize={isMinimumUploadSize}
							/>
						)}
					</form>
					<ConfirmationModal
						showModal={confirmationModal}
						closeModal={() => setConfirmationModal(false)}
						styles={modalStylesConfirmation}
						heading="Are you sure you want to clear the form?"
						positiveLabel="Confirm"
						negativeLabel="Cancel"
						onClickPositive={handleCancel}
					>
						Cancel form?
					</ConfirmationModal>
				</div>
			)}
		</div>
	);
};

export default ResetExpressRehomeAssessmentForm;
