import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import Lightbox from 'react-image-lightbox';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteImageFromS3,
	deleteImagesFromS3,
	getMemberData,
	getResetExpressRehabilitationConclusionForm,
	getResetExpressRehabilitationReferences,
	getUserImage,
	saveResetExpressRehabilitationConclusionForm,
	saveResetReportingImage,
	submitResetExpressRehabilitationConclusionForReview,
	uploadImageToS3
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';
import { useToast } from '../../hooks/useToast';
import { APPROVED, CommonKeys, REVIEW } from '../../utils/constants/index';
import {
	RESETWeeksAvailable,
	checkIsSmallScreen,
	extractLookUpOptionsOther,
	sortArray
} from '../../utils/helpers';
import { logToServer } from '../../utils/logger';
import ConfirmationModal from '../ConfirmationModal';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import FormDropDown from '../FormDropDown';
import FormLabelField from '../FormLabelField';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import MessageBar from '../MessageBar';
import PrimaryButton from '../PrimaryButton';
import ResizingTextArea from '../ResizingTextArea';
import Toggle from '../Toggle';
import UploadPhoto from '../UploadPhoto';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import { HorseManagementKeys } from './../../utils/constants/index';
import useStyles from './style';

const RehabilitationConclusionForm = ({ horseData, horseCode }) => {
	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const { currentUser } = useAuth();
	const [horsePhotos, setHorsePhotos] = useState([]);
	const classes = useStyles();
	const { handleSubmit } = useForm({
		mode: 'onSubmit'
	});
	const [confirmationModal, setConfirmationModal] = useState(false);

	const [weekId, setWeekId] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? 51
			: horseData?.lastSubmittedForm?.weekId
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);
	const [weekIdText, setWeekIdText] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);

	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	// @NOTE - Form fields -- START
	const [
		weeksToRetrainForPriorRehoming,
		setweeksToRetrainForPriorRehoming
	] = useState(0);
	const [horseTemperamentDesc, sethorseTemperamentDesc] = useState('');
	const [horseMovementDesc, sethorseMovementDesc] = useState('');
	const [isHorseGoodForFarrier, setisHorseGoodForFarrier] = useState(false);
	const [
		isHorseGoodForFarrierDetails,
		setisHorseGoodForFarrierDetails
	] = useState('');
	const [isHorseShod, setisHorseShod] = useState(false);
	const [isHorseShodDetails, setisHorseShodDetails] = useState('');
	const [hasHorseBeenTreatedDentist, sethasHorseBeenTreatedDentist] = useState(
		false
	);
	const [
		hasHorseBeenTreatedDentistDetails,
		sethasHorseBeenTreatedDentistDetails
	] = useState('');
	const [isHorseReceivedMedication, setisHorseReceivedMedication] = useState(
		false
	);
	const [
		isHorseReceivedMedicationDetails,
		setisHorseReceivedMedicationDetails
	] = useState('');
	const [
		hasHorseReceivedAnyFormOfExercise,
		sethasHorseReceivedAnyFormOfExercise
	] = useState(false);
	const [
		hasHorseReceivedAnyGroundWork,
		sethasHorseReceivedAnyGroundWork
	] = useState(false);
	const [groundWorkOtherDetails, setgroundWorkOtherDetails] = useState('');
	const [isHorseSound, setisHorseSound] = useState(false);
	const [isHorseSoundDetails, setisHorseSoundDetails] = useState('');
	const [
		isHorseReadyToCommenceRiddenWork,
		setisHorseReadyToCommenceRiddenWork
	] = useState(false);
	const [
		isHorseReadyToCommenceRiddenWorkDetails,
		setisHorseReadyToCommenceRiddenWorkDetails
	] = useState('');
	const [hasHorseBeenClearedByVet, sethasHorseBeenClearedByVet] = useState(
		false
	);
	const [
		hasHorseBeenClearedByVetDetails,
		sethasHorseBeenClearedByVetDetails
	] = useState('');
	const [vetClearedDocuments, setvetClearedDocuments] = useState([]);
	const [horseDisciplineDetails, sethorseDisciplineDetails] = useState('');
	const [isRequireOngoingAttention, setisRequireOngoingAttention] = useState(
		false
	);
	const [
		isRequireOngoingAttentionDetails,
		setisRequireOngoingAttentionDetails
	] = useState('');
	const [
		requireOngoingAttentionDocuments,
		setrequireOngoingAttentionDocuments
	] = useState([]);
	const [groundWork, setgroundWork] = useState([]);
	const [disciplines, setdisciplines] = useState([]);
	const [
		rehabilitationReportFormExercise,
		setrehabilitationReportFormExercise
	] = useState([]);
	// @NOTE - Form fields -- END

	const [disciplineOptions, setDisciplineOptions] = useState([]);
	const [formOfExerciseOptions, setFormOfExerciseOptions] = useState([]);
	const [groundWorkOptions, setGroundWorkOptions] = useState([]);
	const [weeksToRetrainOptions, setWeeksToRetrainOptions] = useState([]);

	// @END
	const { addToast } = useToast();
	const [isLoading, setIsLoading] = useState(false);
	const [currentUserMemberId, setCurrentUserMemberId] = useState(null);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [disableSubmitButton, setDisableSubmitbutton] = useState(true);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [isRehomeDetailsLoaded, setIsRehomeDetailsLoaded] = useState(false);
	const [shouldValidate, setShouldValidate] = useState(false);

	const debouncedWeekNumber = useDebounce(weekIdText, 700);
	let isValidationErrors = false;
	/* ------------------------------- validation ------------------------------- */
	const validateFields = () => {
		let errors = {};
		if (!weekId || weekId == 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			errors = {
				...errors,
				weekId: {
					message: 'You must select a week.'
				}
			};
		}

		if (weekId == 51 && (!weekIdText || isNaN(weekIdText))) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			errors = {
				...errors,
				weekIdText: {
					message: 'You must enter a week number (numeric only).'
				}
			};
		}

		if (!weeksToRetrainForPriorRehoming || weeksToRetrainForPriorRehoming == 0)
			errors = {
				...errors,
				weeksToRetrainForPriorRehoming: { message: 'This field is required' }
			};
		if (!horseTemperamentDesc || horseTemperamentDesc.length === 0)
			errors = {
				...errors,
				horseTemperamentDesc: { message: 'This field is required' }
			};
		if (!horseMovementDesc || horseMovementDesc.length === 0)
			errors = {
				...errors,
				horseMovementDesc: { message: 'This field is required' }
			};
		if (
			hasHorseReceivedAnyGroundWork &&
			(!groundWork || groundWork.length === 0)
		)
			errors = { ...errors, groundWork: { message: 'This field is required' } };
		if (
			hasHorseReceivedAnyGroundWork &&
			groundWork.length > 0 &&
			groundWork.some((i) => i.label === 'Other') &&
			(!groundWorkOtherDetails || groundWorkOtherDetails.length === 0)
		)
			errors = {
				...errors,
				groundWorkOtherDetails: { message: 'This field is required' }
			};
		if (
			hasHorseReceivedAnyFormOfExercise &&
			(!rehabilitationReportFormExercise ||
				rehabilitationReportFormExercise.length === 0)
		)
			errors = {
				...errors,
				rehabilitationReportFormExercise: { message: 'This field is required' }
			};
		if (!disciplines || disciplines.length === 0)
			errors = {
				...errors,
				disciplines: { message: 'This field is required' }
			};
		if (
			disciplines.length > 0 &&
			disciplines.some((i) => i.label === 'Other') &&
			(!horseDisciplineDetails || horseDisciplineDetails.length === 0)
		)
			errors = {
				...errors,
				horseDisciplineDetails: {
					message: 'This field is required'
				}
			};
		if (
			!isHorseShod &&
			(!isHorseShodDetails || isHorseShodDetails.length === 0)
		)
			errors = {
				...errors,
				isHorseShodDetails: { message: 'This field is required' }
			};
		if (
			isHorseReceivedMedication &&
			(!isHorseReceivedMedicationDetails ||
				isHorseReceivedMedicationDetails.length === 0)
		)
			errors = {
				...errors,
				isHorseReceivedMedicationDetails: { message: 'This field is required' }
			};
		if (
			!isHorseSound &&
			(!isHorseSoundDetails || isHorseSoundDetails.length === 0)
		)
			errors = {
				...errors,
				isHorseSoundDetails: { message: 'This field is required' }
			};
		if (
			!isHorseReadyToCommenceRiddenWork &&
			(!isHorseReadyToCommenceRiddenWorkDetails ||
				isHorseReadyToCommenceRiddenWorkDetails.length === 0)
		)
			errors = {
				...errors,
				isHorseReadyToCommenceRiddenWorkDetails: {
					message: 'This field is required'
				}
			};
		if (
			!hasHorseBeenClearedByVetDetails ||
			hasHorseBeenClearedByVetDetails.length === 0
		)
			errors = {
				...errors,
				hasHorseBeenClearedByVetDetails: { message: 'This field is required' }
			};
		if (
			isRequireOngoingAttention &&
			(!isRequireOngoingAttentionDetails ||
				isRequireOngoingAttentionDetails.length === 0)
		)
			errors = {
				...errors,
				isRequireOngoingAttentionDetails: { message: 'This field is required' }
			};

		if (horsePhotos.length === 0) {
			errors = {
				...errors,
				horsePhotos: { message: 'This field is required' }
			};
		}
		if (errors) {
			setDisableSubmitbutton(true);
		}
		setUncontrolledErrors(errors);
		return errors;
	};

	useEffect(() => {
		if (
			uncontrolledErrors?.weekId?.message ||
			uncontrolledErrors?.weekIdText?.message
		) {
			validateFields();
		}
	}, [weekId, debouncedWeekNumber]);

	const validateUncontrolledErrors = () => {
		validateFields();
	};

	const handleOnSubmit = () => {
		const errors = Object.keys(validateFields());
		if (errors?.length > 0) {
			setDisableSubmitbutton(true);
		} else {
			handleSave(true);
		}
	};

	const onSubmit = async () => {
		await validateUncontrolledErrors();
	};

	const handleSave = async (isSubmitReport = false) => {
		validateUncontrolledErrors();
		const filteredSelectedDisciplines = disciplines.map(
			(discipline) => discipline.value
		);
		const filteredSelectedGroundWorks = groundWork.map(
			(discipline) => discipline.value
		);
		const filteredSelectedRehabExercises = rehabilitationReportFormExercise.map(
			(discipline) => discipline.value
		);

		let acknowledgedFormData = {
			memberId: currentUserMemberId,
			horseCode,
			weekId: weekId == 0 ? null : weekId >= 51 ? weekIdText : weekId,
			weeksToRetrainId: weeksToRetrainForPriorRehoming
				? Number(weeksToRetrainForPriorRehoming)
				: null,
			horseTemperamentDesc,
			horseMovementDesc,
			isHorseGoodForFarrier,
			isHorseGoodForFarrierDetails,
			isHorseShod,
			isHorseShodDetails,
			hasHorseBeenTreatedDentist,
			hasHorseBeenTreatedDentistDetails,
			isHorseReceivedMedication,
			isHorseReceivedMedicationDetails,
			hasHorseReceivedAnyFormOfExercise,
			hasHorseReceivedAnyGroundWork,
			groundWorkOtherDetails,
			isHorseSound,
			isHorseSoundDetails,
			isHorseReadyToCommenceRiddenWork,
			isHorseReadyToCommenceRiddenWorkDetails,
			hasHorseBeenClearedByVet,
			hasHorseBeenClearedByVetDetails,
			horseDisciplineDetails,
			isRequireOngoingAttention,
			isRequireOngoingAttentionDetails,
			groundWork: filteredSelectedGroundWorks,
			disciplines: filteredSelectedDisciplines,
			rehabilitationReportFormExercise: filteredSelectedRehabExercises
		};

		setIsLoading(true);
		AppSyncService.execute(saveResetExpressRehabilitationConclusionForm, {
			input: acknowledgedFormData
		}).then(async (data) => {
			if (data?.data?.saveResetExpressRehabilitationConclusionForm !== null) {
				let photoArrays = [{ name: 'horsePhotos', arr: horsePhotos }];
				photoArrays.forEach((photoArray, index) => {
					photoArray.arr.map((photo) => {
						AppSyncService.execute(saveResetReportingImage, {
							filename: photo.filename,
							key: photo.key,
							reportType: 'ResetExpressRehabilitation',
							reportId:
								data.data.saveResetExpressRehabilitationConclusionForm.id,
							type: photoArrays[index].name
						});
					});
				});
				if (vetClearedDocuments?.length > 0) {
					for (let i = 0; i < vetClearedDocuments.length; i++) {
						const photo = vetClearedDocuments[i];
						await AppSyncService.execute(saveResetReportingImage, {
							filename: photo.filename,
							key: photo.key,
							reportType: 'ResetExpressRehabilitation',
							reportId:
								data.data.saveResetExpressRehabilitationConclusionForm.id,
							type: 'vetCleared'
						});
					}
				}
				if (requireOngoingAttentionDocuments?.length > 0) {
					for (let i = 0; i < requireOngoingAttentionDocuments.length; i++) {
						const photo = requireOngoingAttentionDocuments[i];
						await AppSyncService.execute(saveResetReportingImage, {
							filename: photo.filename,
							key: photo.key,
							reportType: 'ResetExpressRehabilitation',
							reportId:
								data.data.saveResetExpressRehabilitationConclusionForm.id,
							type: 'requireOngoing'
						});
					}
				}
				setIsShowErrorMessages(true);
				if (!isSubmitReport) {
					addToast({
						Message: 'Draft Saved',
						IsSuccess: true
					});
				} else {
					AppSyncService.execute(
						submitResetExpressRehabilitationConclusionForReview,
						{
							memberId: currentUserMemberId,
							horseCode: horseCode,
							weekId: weekId >= 51 ? weekIdText : weekId
						}
					).then((data) => {
						if (
							data?.data?.submitResetExpressRehabilitationConclusionForReview
						) {
							addToast({
								Message: 'Rehabilitation Concluded Form Submitted',
								IsSuccess: true
							});
							logToServer(
								currentUser?.UserId,
								data,
								'Rehabilitation Concluded Report form Submitted and Passed'
							);
							setIsReadOnly(true);
						} else {
							addToast({
								Message:
									'An error submitting. Racing Victoria are investigating.',
								IsSuccess: false
							});
							logToServer(
								currentUser?.UserId,
								data,
								'Rehabilitation Concluded Report form Submitted and Failed'
							);
						}
					});
				}
			}
		});

		if (!isSubmitReport) {
			if (!isValidationErrors) {
				setDisableSubmitbutton(false);
			} else {
				setDisableSubmitbutton(false);
			}
		}
		setIsLoading(false);
	};

	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		fetchMemberData();
	}, []);

	const fetchMemberData = async () => {
		const result = await AppSyncService.execute(getMemberData, {
			externalId: currentUser.UserId
		});

		if (result?.data?.getMember) {
			setCurrentUserMemberId(result.data.getMember.id);
		}
	};

	useEffect(() => {
		if (shouldValidate) {
			const errors = Object.keys(validateFields());
			if (errors?.length > 0) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	useEffect(() => {
		if (isRehomeDetailsLoaded && weekId != 0) {
			const errors = Object.keys(validateFields());
			if (errors?.length > 0) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
		}
		setIsRehomeDetailsLoaded(false);
	}, [isRehomeDetailsLoaded]);

	useEffect(() => {
		setIsLoading(true);
		if (currentUser !== null) {
			AppSyncService.execute(getResetExpressRehabilitationReferences).then(
				(result) => {
					if (result.data) {
						let SortedDicipline = sortArray(
							result.data.listSpecialtyDisciplines,
							true
						);

						setDisciplineOptions(SortedDicipline);

						setGroundWorkOptions(
							extractLookUpOptionsOther(result, 'listGroundWorks')
						);
						setFormOfExerciseOptions(
							extractLookUpOptionsOther(result, 'listFormOfExercisesRehab')
						);

						setWeeksToRetrainOptions(
							extractLookUpOptionsOther(
								result,
								'listWeeksToRetrainPriorRehoming'
							)
						);
						setWeeksToRetrainOptions((prev) =>
							[{ id: 0, value: '' }].concat(prev)
						);
					}
				}
			);
		}
		setIsLoading(false);
	}, []);

	useEffect(() => {
		if (
			(weekId && weekId != 0 && weekId != 51) ||
			(weekId == 51 && weekIdText && weekIdText != 0 && !isNaN(weekIdText))
		) {
			setDisableSubmitbutton(true);
			loadRehomeAssessmentForm();
		}
	}, [weekId, debouncedWeekNumber]);

	const loadRehomeAssessmentForm = () => {
		const input = {
			memberExternalId: currentUser?.UserId,
			horseCode,
			weekId: weekId >= 51 ? +weekIdText : weekId
		};
		clearFields();
		try {
			AppSyncService.execute(
				getResetExpressRehabilitationConclusionForm,
				input
			).then((result) => {
				if (result?.data?.getResetExpressRehabilitationConclusionForm) {
					const initialData =
						result?.data?.getResetExpressRehabilitationConclusionForm;

					if (initialData?.status == null) {
						setIsShowErrorMessages(false);
					} else {
						setIsShowErrorMessages(true);
					}

					setHorsePhotos(initialData.photosOfTheHorse ?? []);
					setweeksToRetrainForPriorRehoming(
						initialData.weeksToRetrainForPriorRehoming.id ?? 0
					);
					sethorseTemperamentDesc(initialData.horseTemperamentDesc ?? '');
					sethorseMovementDesc(initialData.horseMovementDesc ?? '');
					setisHorseGoodForFarrier(initialData.isHorseGoodForFarrier ?? false);
					setisHorseGoodForFarrierDetails(
						initialData.isHorseGoodForFarrierDetails ?? ''
					);
					setisHorseShod(initialData.isHorseShod ?? false);
					setisHorseShodDetails(initialData.isHorseShodDetails ?? '');
					sethasHorseBeenTreatedDentist(
						initialData.hasHorseBeenTreatedDentist ?? false
					);
					sethasHorseBeenTreatedDentistDetails(
						initialData.hasHorseBeenTreatedDentistDetails ?? ''
					);
					setisHorseReceivedMedication(
						initialData.isHorseReceivedMedication ?? false
					);
					setisHorseReceivedMedicationDetails(
						initialData.isHorseReceivedMedicationDetails ?? ''
					);
					sethasHorseReceivedAnyFormOfExercise(
						initialData.hasHorseReceivedAnyFormOfExercise ?? false
					);
					sethasHorseReceivedAnyGroundWork(
						initialData.hasHorseReceivedAnyGroundWork ?? false
					);
					setgroundWorkOtherDetails(initialData.groundWorkOtherDetails ?? '');
					setisHorseSound(initialData.isHorseSound ?? false);
					setisHorseSoundDetails(initialData.isHorseSoundDetails ?? '');
					setisHorseReadyToCommenceRiddenWork(
						initialData.isHorseReadyToCommenceRiddenWork ?? false
					);
					setisHorseReadyToCommenceRiddenWorkDetails(
						initialData.isHorseReadyToCommenceRiddenWorkDetails ?? ''
					);
					sethasHorseBeenClearedByVet(
						initialData.hasHorseBeenClearedByVet ?? false
					);
					sethasHorseBeenClearedByVetDetails(
						initialData.hasHorseBeenClearedByVetDetails ?? ''
					);
					setvetClearedDocuments(initialData.vetClearedDocuments ?? []);
					sethorseDisciplineDetails(initialData.horseDisciplineDetails ?? '');
					setisRequireOngoingAttention(
						initialData.isRequireOngoingAttention ?? false
					);
					setisRequireOngoingAttentionDetails(
						initialData.isRequireOngoingAttentionDetails ?? ''
					);
					setrequireOngoingAttentionDocuments(
						initialData.requireOngoingAttentionDocuments ?? []
					);
					if (initialData.groundWork.length > 0) {
						const groundWork = initialData.groundWork.map((reason) => ({
							value: reason.id,
							label: reason.value
						}));
						setgroundWork(groundWork);
					}
					if (initialData.disciplines.length > 0) {
						const disciplines = initialData.disciplines.map((reason) => ({
							value: reason.id,
							label: reason.value
						}));
						setdisciplines(disciplines);
					}
					if (initialData.rehabilitationReportFormExercise.length > 0) {
						const rehabilitationReportFormExercise = initialData.rehabilitationReportFormExercise.map(
							(reason) => ({
								value: reason.id,
								label: reason.value
							})
						);
						setrehabilitationReportFormExercise(
							rehabilitationReportFormExercise
						);
					}

					setIsReadOnly(
						initialData.status === REVIEW || initialData.status === APPROVED
					);
					setDisableSubmitbutton(true);
					setUncontrolledErrors({});
				}
				setIsRehomeDetailsLoaded(true);
			});
		} catch (error) {
			logToServer(currentUser?.UserId, error, 'Form fetch failed');
			addToast({ Message: 'Unable to fetch form', IsSuccess: false });
		}
	};

	const clearFields = () => {
		setweeksToRetrainForPriorRehoming(0);
		sethorseTemperamentDesc('');
		sethorseMovementDesc('');
		setisHorseGoodForFarrier(false);
		setisHorseGoodForFarrierDetails('');
		setisHorseShod(false);
		setisHorseShodDetails('');
		sethasHorseBeenTreatedDentist(false);
		sethasHorseBeenTreatedDentistDetails('');
		setisHorseReceivedMedication(false);
		setisHorseReceivedMedicationDetails('');
		sethasHorseReceivedAnyFormOfExercise(false);
		sethasHorseReceivedAnyGroundWork(false);
		setgroundWorkOtherDetails('');
		setisHorseSound(false);
		setisHorseSoundDetails('');
		setisHorseReadyToCommenceRiddenWork(false);
		setisHorseReadyToCommenceRiddenWorkDetails('');
		sethasHorseBeenClearedByVet(false);
		sethasHorseBeenClearedByVetDetails('');
		setvetClearedDocuments([]);
		sethorseDisciplineDetails('');
		setisRequireOngoingAttention(false);
		setisRequireOngoingAttentionDetails('');
		setrequireOngoingAttentionDocuments([]);
		setgroundWork([]);
		setdisciplines([]);
		setrehabilitationReportFormExercise([]);
	};

	const handleCancel = () => {
		deleteAllPhotos();
		setWeekId(0);
		setWeekIdText(0);
		clearFields();

		setConfirmationModal(false);
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key != img.key));
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
			}
		);
	};

	const deleteAllPhotos = () => {
		let listOfKeys = [];
		[requireOngoingAttentionDocuments].forEach((photoArray) => {
			photoArray.map((d) => {
				listOfKeys.push(d.key);
			});
		});
		[vetClearedDocuments].forEach((photoArray) => {
			photoArray.map((d) => {
				listOfKeys.push(d.key);
			});
		});
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					setrequireOngoingAttentionDocuments([]);
					setvetClearedDocuments([]);
				}
			});
		}
	};

	const renderAssessmentFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								How many weeks has the horse been rehabilitating for?*
							</div>
							<FormDropDown
								items={RESETWeeksAvailable()}
								selectedId={weekId}
								onChange={(e) => {
									setWeekId(e.target.value);
									setShouldValidate(true);
								}}
								id={HorseManagementKeys.WEEK_NUMBER_DROPDOWN}
								showBlank
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.weekId?.message}
								</span>
							)}
						</div>
					</Col>
					{+weekId === 51 && (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Week Number*</div>
								<FormTextField
									id={HorseManagementKeys.WEEK_NUMBER_TEXT}
									value={weekIdText}
									onChange={(e) => {
										setWeekIdText(e.target.value);
										setShouldValidate(true);
									}}
									styles={{ height: 38 }}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.weekIdText?.message}
									</span>
								)}
							</div>
						</Col>
					)}
					<Col xs={12} lg={6}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Photos of the horse (jpg, png)*
							</div>
							<UploadPhoto
								id={`ResetExpressRehabilitation-photos`}
								formats={['png', 'jpeg']}
								labelName="Upload Photos of the horse"
								error={uploadError}
								data={horsePhotos}
								sizeLimitMB={5}
								numberOfImages={5}
								success={(e) => photosUpload(e, setHorsePhotos)}
								getImage={getImage}
								removeImage={(e) => removePhoto(e, horsePhotos, setHorsePhotos)}
								canEdit={!isReadOnly}
							/>
						</div>
						<Row>
							<span style={{ fontStyle: 'italic' }}>
								Please ensure clear photos, full body, front and rear, taken on
								or as close as possible to this assessment being completed.
							</span>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors?.horsePhotos?.message}
								</span>
							)}
						</Row>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="horseTemperamentDesc"
									title="How would you describe the horse's temperament?*"
									id="resetexpress-horseTemperamentDesc"
									value={horseTemperamentDesc}
									onChange={(e) => {
										sethorseTemperamentDesc(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.horseTemperamentDesc?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="horseMovementDesc"
									title="How would you describe the horse's movement?*"
									id="resetexpress-horseMovementDesc"
									value={horseMovementDesc}
									onChange={(e) => {
										sethorseMovementDesc(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.horseMovementDesc?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Is the horse good for the farrier?"
										value={isHorseGoodForFarrier ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Is the horse good for the farrier?
								</div>
								<Toggle
									id={HorseManagementKeys.RECEIVED_ANY_TREATMENTS}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisHorseGoodForFarrier(value);
										setShouldValidate(true);
									}}
									value={isHorseGoodForFarrier}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					<Col xs={12} lg={8}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="isHorseGoodForFarrierDetails"
									title="Additional details"
									id="resetexpress-isHorseGoodForFarrierDetails"
									value={isHorseGoodForFarrierDetails}
									onChange={(e) => {
										setisHorseGoodForFarrierDetails(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.isHorseGoodForFarrierDetails?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Is the horse Shod?"
										value={isHorseShod ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Is the horse Shod?</div>
								<Toggle
									id={HorseManagementKeys.SHOD}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisHorseShod(value);
										setShouldValidate(true);
									}}
									value={isHorseShod}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{!isHorseShod && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="isHorseShodDetails"
										title="Additional details*"
										id="resetexpress-isHorseShodDetails"
										value={isHorseShodDetails}
										onChange={(e) => {
											setisHorseShodDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.isHorseShodDetails?.message}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse been treated by a dentist?"
										value={hasHorseBeenTreatedDentist ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse been treated by a dentist?
								</div>
								<Toggle
									id={HorseManagementKeys.TREATED_DENTIST}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseBeenTreatedDentist(value);
										setShouldValidate(true);
									}}
									value={hasHorseBeenTreatedDentist}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					<Col xs={12} lg={8}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="hasHorseBeenTreatedDentistDetails"
									title="Additional details"
									id="resetexpress-hasHorseBeenTreatedDentistDetails"
									value={hasHorseBeenTreatedDentistDetails}
									onChange={(e) => {
										sethasHorseBeenTreatedDentistDetails(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{
										uncontrolledErrors.hasHorseBeenTreatedDentistDetails
											?.message
									}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Is the horse currently receiving medication?"
										value={isHorseReceivedMedication ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Is the horse currently receiving medication?
								</div>
								<Toggle
									id={HorseManagementKeys.RECEIVED_ANY_TREATMENTS}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisHorseReceivedMedication(value);
										setShouldValidate(true);
									}}
									value={isHorseReceivedMedication}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{isHorseReceivedMedication && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="isHorseReceivedMedicationDetails"
										title="What medication is the horse currently receiving?*"
										id="resetexpress-isHorseReceivedMedicationDetails"
										value={isHorseReceivedMedicationDetails}
										onChange={(e) => {
											setisHorseReceivedMedicationDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{
											uncontrolledErrors.isHorseReceivedMedicationDetails
												?.message
										}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse received any form of exercise as part of the rehabilitation program?"
										value={hasHorseReceivedAnyFormOfExercise ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse received any form of exercise as part of the
									rehabilitation program?
								</div>
								<Toggle
									id={HorseManagementKeys.RECEIVED_EXERCISE}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseReceivedAnyFormOfExercise(value);
										setShouldValidate(true);
									}}
									value={hasHorseReceivedAnyFormOfExercise}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{hasHorseReceivedAnyFormOfExercise && (
						<Col xs={12} lg={8}>
							{isReadOnly ? (
								<FormLabelField
									title={`Please select*`}
									value={rehabilitationReportFormExercise
										.map((g) => g.label)
										.join()}
									style={{ padding: 0 }}
								/>
							) : (
								<div
									className={
										classes.fieldContainerPaddingTopDeceasedMultiSelect
									}
								>
									<MultiSelectInputField
										idPrefix={`rehabilitationReportFormExercise`}
										fieldLabel={`Please select*`}
										options={formOfExerciseOptions}
										selectedIds={rehabilitationReportFormExercise}
										onSelectChange={(vals) => {
											setrehabilitationReportFormExercise(vals);
											setShouldValidate(true);
										}}
										otherDisabled={true}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{
												uncontrolledErrors.rehabilitationReportFormExercise
													?.message
											}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse received any form of groundwork as part of the rehabilitation program?"
										value={hasHorseReceivedAnyGroundWork ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse received any form of groundwork as part of the
									rehabilitation program?
								</div>
								<Toggle
									id={HorseManagementKeys.GROUNDWORK_RIDDEN_ASSESSMENT}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseReceivedAnyGroundWork(value);
										setShouldValidate(true);
									}}
									value={hasHorseReceivedAnyGroundWork}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{hasHorseReceivedAnyGroundWork && (
						<Col xs={12} lg={8}>
							{isReadOnly ? (
								<FormLabelField
									title={`Please select*`}
									value={groundWork.map((g) => g.label).join()}
									style={{ padding: 0 }}
								/>
							) : (
								<div
									className={
										classes.fieldContainerPaddingTopDeceasedMultiSelect
									}
								>
									<MultiSelectInputField
										idPrefix={`groundwork`}
										fieldLabel={`Please select*`}
										options={groundWorkOptions}
										selectedIds={groundWork}
										onSelectChange={(vals) => {
											setgroundWork(vals);
											setShouldValidate(true);
										}}
										otherDisabled={true}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.groundWork?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
				</Row>
				{hasHorseReceivedAnyGroundWork &&
					groundWork.some((i) => i.label === 'Other') && (
						<Row>
							<Col xs={12} lg={8}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										<ResizingTextArea
											rows={2}
											isShowRemainingChars={false}
											name="groundWorkOtherDetails"
											title="Other details*"
											id="resetexpress-groundWorkOtherDetails"
											value={groundWorkOtherDetails}
											onChange={(e) => {
												setgroundWorkOtherDetails(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											charLengthStyle={{
												fontWeight: 'normal'
											}}
											styles={{
												marginLeft: 0,
												marginRight: 0,
												padding: 0
											}}
											titleStyles={{ fontSize: '14px' }}
											canEdit={!isReadOnly}
										/>
									</div>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.groundWorkOtherDetails?.message}
										</span>
									)}
								</div>
							</Col>
						</Row>
					)}
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Is the horse currently sound?"
										value={isHorseSound ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Is the horse currently sound?
								</div>
								<Toggle
									id={HorseManagementKeys.SOUND}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisHorseSound(value);
										setShouldValidate(true);
									}}
									value={isHorseSound}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{!isHorseSound && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="isHorseSoundDetails"
										title="Additional details*"
										id="resetexpress-isHorseSoundDetails"
										value={isHorseSoundDetails}
										onChange={(e) => {
											setisHorseSoundDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.isHorseSoundDetails?.message}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Is the horse ready to commence ridden work for the purpose of retraining and rehoming?"
										value={isHorseReadyToCommenceRiddenWork ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Is the horse ready to commence ridden work for the purpose of
									retraining and rehoming?
								</div>
								<Toggle
									id={HorseManagementKeys.COMMENCE_RIDDEN}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisHorseReadyToCommenceRiddenWork(value);
										setShouldValidate(true);
									}}
									value={isHorseReadyToCommenceRiddenWork}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{!isHorseReadyToCommenceRiddenWork && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="isHorseReadyToCommenceRiddenWorkDetails"
										title="Additional details*"
										id="resetexpress-isHorseReadyToCommenceRiddenWorkDetails"
										value={isHorseReadyToCommenceRiddenWorkDetails}
										onChange={(e) => {
											setisHorseReadyToCommenceRiddenWorkDetails(
												e.target.value
											);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{
											uncontrolledErrors.isHorseReadyToCommenceRiddenWorkDetails
												?.message
										}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse been cleared by your Veterinarian to return to ridden work?"
										value={hasHorseBeenClearedByVet ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has the horse been cleared by your Veterinarian to return to
									ridden work?
								</div>
								<Toggle
									id={HorseManagementKeys.CLEARED_BY_VET}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										sethasHorseBeenClearedByVet(value);
										setShouldValidate(true);
									}}
									value={hasHorseBeenClearedByVet}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					<Col xs={12} lg={8}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									rows={2}
									isShowRemainingChars={false}
									name="hasHorseBeenClearedByVetDetails"
									title={
										hasHorseBeenClearedByVet
											? 'Who was the veterinarian who provided the veterinary clearance?*'
											: 'Please provide details*'
									}
									id="resetexpress-hasHorseBeenClearedByVetDetails"
									value={hasHorseBeenClearedByVetDetails}
									onChange={(e) => {
										sethasHorseBeenClearedByVetDetails(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.hasHorseBeenClearedByVetDetails?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Upload a veterinary report document
							</div>
							<UploadPhoto
								id={HorseManagementKeys.VET_CLEARED_DOCUMENTS}
								formats={['png', 'jpeg']}
								labelName="Upload"
								error={uploadError}
								data={vetClearedDocuments}
								sizeLimitMB={5}
								numberOfImages={10}
								success={(e) => photosUpload(e, setvetClearedDocuments)}
								getImage={getImage}
								removeImage={(e) =>
									removePhoto(e, vetClearedDocuments, setvetClearedDocuments)
								}
								canEdit={!isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.vetClearedDocuments?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<FormLabelField
								title={`Once retrained, what disciplines do you anticipate this horse would be suited to?*`}
								value={disciplines.map((g) => g.label).join()}
								style={{ padding: 0 }}
							/>
						) : (
							<div
								className={classes.fieldContainerPaddingTopDeceasedMultiSelect}
							>
								<MultiSelectInputField
									idPrefix={`disciplines`}
									fieldLabel={`Once retrained, what disciplines do you anticipate this horse would be suited to?*`}
									options={disciplineOptions}
									selectedIds={disciplines}
									onSelectChange={(vals) => {
										setdisciplines(vals);
										setShouldValidate(true);
									}}
									otherDisabled={true}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.disciplines?.message}
									</span>
								)}
							</div>
						)}
					</Col>
					{disciplines.map((i, index) => {
						if (i.label === 'Other') {
							return (
								<Col key={index} xs={12} lg={8}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											<ResizingTextArea
												rows={2}
												isShowRemainingChars={false}
												name="disciplinesOtherDetails"
												title="Other details*"
												id="resetexpress-disciplinesOtherDetails"
												value={horseDisciplineDetails}
												onChange={(e) => {
													sethorseDisciplineDetails(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												charLengthStyle={{
													fontWeight: 'normal'
												}}
												styles={{
													marginLeft: 0,
													marginRight: 0,
													padding: 0
												}}
												titleStyles={{ fontSize: '14px' }}
												canEdit={!isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.horseDisciplineDetails?.message}
											</span>
										)}
									</div>
								</Col>
							);
						}
					})}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Are there issues that will require ongoing attention (eg joint supplements for arthritis/ medications)?"
										value={isRequireOngoingAttention ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Are there issues that will require ongoing attention (eg joint
									supplements for arthritis/ medications)?
								</div>
								<Toggle
									id={HorseManagementKeys.SHOD}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setisRequireOngoingAttention(value);
										setShouldValidate(true);
									}}
									value={isRequireOngoingAttention}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
					{isRequireOngoingAttention && (
						<Col xs={12} lg={8}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										rows={2}
										isShowRemainingChars={false}
										name="isRequireOngoingAttentionDetails"
										title="Please provide details*"
										id="resetexpress-isRequireOngoingAttentionDetails"
										value={isRequireOngoingAttentionDetails}
										onChange={(e) => {
											setisRequireOngoingAttentionDetails(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{
											uncontrolledErrors.isRequireOngoingAttentionDetails
												?.message
										}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Upload a veterinary report document
							</div>
							<UploadPhoto
								id={HorseManagementKeys.REQUIRE_ONGOING_ATTENTION}
								formats={['png', 'jpeg']}
								labelName="Upload"
								error={uploadError}
								data={requireOngoingAttentionDocuments}
								sizeLimitMB={5}
								numberOfImages={10}
								success={(e) =>
									photosUpload(e, setrequireOngoingAttentionDocuments)
								}
								getImage={getImage}
								removeImage={(e) =>
									removePhoto(
										e,
										requireOngoingAttentionDocuments,
										setrequireOngoingAttentionDocuments
									)
								}
								canEdit={!isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.requireOngoingAttentionDocuments?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								How many weeks do you intend to retrain this horse for prior to
								rehoming?*
							</div>
							<FormDropDown
								showBlank
								id={HorseManagementKeys.WEEKS_TO_RETRAIN}
								items={weeksToRetrainOptions}
								selectedId={weeksToRetrainForPriorRehoming}
								onChange={(e) => {
									setweeksToRetrainForPriorRehoming(e.target.value);
									setShouldValidate(true);
								}}
								disabled={isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.weeksToRetrainForPriorRehoming?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<div className={classes.container}>
			{isLoading ? (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			) : (
				<div className={classes.formContainer}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={classes.formHeading}>
							Rehabilitation Assessment Report
						</div>
						<Container>
							<MessageBar>
								<div className={classes.topMessage}>
									We need more information for this. Please fill in and save all
									the fields below.
								</div>
							</MessageBar>
							<>{renderAssessmentFields()}</>
							{(!weekId || weekId == 0) && (
								<div className={classes.formFieldsContainer}>
									<Row>
										<Col xs={12}>
											<div className={classes.fieldReminder}>
												Click on the week number when you return to complete
												your Weekly Form
											</div>
										</Col>
									</Row>
								</div>
							)}
							{imgSrcUrl && (
								<Lightbox
									mainSrc={imgSrcUrl}
									onCloseRequest={() => setImgSrcUrl(false)}
								/>
							)}
							<hr className={classes.IdentificationDivider}></hr>

							<div
								style={{
									display: 'flex',
									justifyContent: `${
										isReadOnly ? 'flex-end' : 'space-between'
									}`,
									alignItems: 'center'
								}}
							>
								{!isReadOnly && (
									<div
										id={CommonKeys.CLEAR_FORM}
										className={classes.clearFormLink}
										onClick={() => {
											setConfirmationModal(true);
										}}
									>
										Clear Form
									</div>
								)}
								<div
									className={
										isSmallScreen
											? classes.saveRequestMobile
											: classes.saveRequest
									}
								>
									<span className={isSmallScreen ? null : classes.saveButton}>
										<PrimaryButton
											id={CommonKeys.SAVE}
											isNonSubmit={false}
											style={{ minWidth: '170px' }}
											onClick={() => handleSave()}
											disabled={isReadOnly}
										>
											Save
										</PrimaryButton>
									</span>
									<span>
										<PrimaryButton
											id={HorseManagementKeys.SUBMIT_REPORT}
											isNonSubmit={false}
											disabled={disableSubmitButton || isReadOnly}
											style={{ minWidth: '170px' }}
											onClick={() => handleOnSubmit()}
										>
											Submit Report
										</PrimaryButton>
									</span>
								</div>
							</div>
						</Container>
					</form>
					<ConfirmationModal
						showModal={confirmationModal}
						closeModal={() => setConfirmationModal(false)}
						styles={modalStylesConfirmation}
						heading="Are you sure you want to clear the form?"
						positiveLabel="Confirm"
						negativeLabel="Cancel"
						onClickPositive={handleCancel}
					>
						Cancel form?
					</ConfirmationModal>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				</div>
			)}
		</div>
	);
};

export default RehabilitationConclusionForm;
