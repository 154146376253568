import {
	HORSE_AVAILABILITY_PENDING_STATUS,
	HORSE_AVAILABILITY_REMOVED_STATUS,
	HorseStatusType,
	RESET_ELIGIBLE_LISTINGS,
	RESET_HORSE_EXPRESS_ASSIGNED_STATUS
} from '../../utils/constants';

export const getMoreDetaillsStyle = (data, isRetrainerOnly) => {
	return isRetrainerOnly && !data.assignedRetrainer
		? { fontSize: 15 }
		: (data.assignedRetrainer &&
				RESET_HORSE_EXPRESS_ASSIGNED_STATUS.includes(data.horseOTTStatusID)) ||
		  data.horseOTTStatusID == HORSE_AVAILABILITY_REMOVED_STATUS ||
		  data.horseOTTStatusID == HORSE_AVAILABILITY_PENDING_STATUS ||
		  (data?.removeReason?.id &&
				data.horseStatusSubtype != HorseStatusType.ResetExpressHorses) ||
		  RESET_ELIGIBLE_LISTINGS.includes(data.horseOTTStatusID)
		? { fontSize: 15, background: '#808080' }
		: { fontSize: 15 };
};
