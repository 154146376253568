import React, { useState, useEffect } from 'react';
import useStyles from './style';
import AppSyncService from '../../graphql/AppSyncService';
import { Container, Row, Col } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
import Lightbox from 'react-image-lightbox';
import FormTextField from '../FormTextField';
import Toggle from '../Toggle';
import PrimaryButton from '../PrimaryButton';
import FormDatePicker from '../FormDatePicker';
import { useForm } from 'react-hook-form';
import LoaderSpinner from '../LoaderSpinner';
import ResizingTextArea from '../ResizingTextArea';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import ConfirmationModal from '../ConfirmationModal';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import {
	validatePostCode,
	formatDate,
	extractLookUpOptionsOther,
	formatDateReadableVariant
} from '../../utils/helpers';
import {
	getAcknowledgedRetirementQuarterlyAssessmentReferences,
	saveRetirementQuarterlyAssessmentForm,
	getAcknowledgedRetirementQuarterlyAssessmentForm,
	saveResetReportingImage,
	getUserImage,
	uploadImageToS3,
	deleteImageFromS3,
	deleteImagesFromS3,
	getMemberData,
	submitAckRetirementQuarterlyReportsForReview,
	saveDeceasedThoroughbredForm
} from '../../graphql/custom.queries';
import UploadPhoto from '../UploadPhoto';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import { logToServer } from '../../utils/logger';
import FormToolTip from '../FormToolTip';
import axios from 'axios';
import { REVIEW, APPROVED, CommonKeys } from '../../utils/constants/index';
import FormLabelField from '../FormLabelField';
import { HorseManagementKeys } from './../../utils/constants/index';
import { getQuarterlyYears } from './helper';

const changesToHealthOther = 4;
const reasonForRehab = {
	INJURY: 1,
	ILLNESS: 2,
	OTHER: 3
};

const reasonForDeath = {
	INJURY: 1,
	ILLNESS: 2,
	OTHER: 3
};

const AcknowledgedRetirementQuarterlyAssessmentForm = ({
	horseData,
	horseCode,
	reload
}) => {
	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};
	const { currentUser } = useAuth();
	const classes = useStyles();
	const { handleSubmit } = useForm({
		mode: 'onSubmit'
	});
	const [confirmationModal, setConfirmationModal] = useState(false);

	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [referenceQuarterList, setReferenceQuarterList] = useState([]);
	const [
		referenceAgistmentChangesToHealthList,
		setReferenceAgistmentChangesToHealthList
	] = useState([]);
	const [referenceRehabReasonList, setReferenceRehabReasonList] = useState([]);
	const [referenceInjuryList, setReferenceInjuryList] = useState([]);
	const [referenceIllnessList, setReferenceIllnessList] = useState([]);
	const [referenceCauseOfDeathList, setReferenceCauseOfDeathList] = useState(
		[]
	);
	const [referenceReasonOfDeathList, setReferenceReasonOfDeathList] = useState(
		[]
	);
	const [referenceNewStatusList, setReferenceNewStatusList] = useState([]);
	const [
		referenceDeceasedReasonOfDeathOtherList,
		setReferenceDeceasedReasonOfDeathOtherList
	] = useState([]);
	const { addToast } = useToast();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [currentUserMemberId, setCurrentUserMemberId] = useState(null);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [progressPhotos, setProgressPhotos] = useState([]);

	const [quarterNumber, setQuarterNumber] = useState(0);
	const [year, setYear] = useState(new Date().getFullYear());
	const referenceYearList = getQuarterlyYears();
	const [dateOfAssessment, setDateOfAssessment] = useState(null);
	const [hasHorseStatusChanged, setHasHorseStatusChanged] = useState(false);
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [newStatus, setNewStatus] = useState(0);
	const [
		agistmentHasPhysicalHealthChanged,
		setAgistmentHasPhysicalHealthChanged
	] = useState(false);
	const [
		agistmentChangesToPhysicalHealth,
		setAgistmentChangesToPhysicalHealth
	] = useState(null);
	const [
		agistmentChangesToPhysicalHealthOther,
		setAgistmentChangesToPhysicalHealthOther
	] = useState(null);
	const [
		agistmentHasBehaviourChanged,
		setAgistmentHasBehaviourChanged
	] = useState(false);
	const [
		agistmentChangesToBehaviourOther,
		setAgistmentChangesToBehaviourOther
	] = useState(null);
	const [worming, setWorming] = useState(false);
	const [wormingDateCompleted, setWormingDateCompleted] = useState(null);
	const [farrier, setFarrier] = useState(false);
	const [farrierDateCompleted, setFarrierDateCompleted] = useState(null);
	const [dentistTreatment, setDentistTreatment] = useState(false);
	const [
		dentistTreatmentDateCompleted,
		setDentistTreatmentDateCompleted
	] = useState(null);
	const [husbandryOther, setHusbandryOther] = useState('');
	const [rehabReason, setRehabReason] = useState(0);
	const [injuryType, setInjuryType] = useState([]);
	const [illnessType, setIllnessType] = useState([]);
	const [rehabReasonOther, setRehabReasonOther] = useState(null);
	const [rehabDateCommenced, setRehabDateCommenced] = useState(null);
	const [treatmentSinceLastReport, setTreatmentSinceLastReport] = useState(
		false
	);
	const [deceasedCauseOfDeath, setDeceasedCauseOfDeath] = useState(0);
	const [deceasedDateOfDeath, setDeceasedDateOfDeath] = useState(null);
	const [deceasedReasonOfDeath, setDeceasedReasonOfDeath] = useState(0);
	const [deceasedInjuryDetailsOther, setDeceasedInjuryDetailsOther] = useState(
		''
	);
	const [
		deceasedIllnessDetailsOther,
		setDeceasedIllnessDetailsOther
	] = useState('');
	const [
		deceasedReasonForDeathOther,
		setDeceasedReasonForDeathOther
	] = useState(0);
	const [deceasedKnackeryPostcode, setDeceasedKnackeryPostcode] = useState(
		null
	);
	const [deceasedKnackerySuburb, setDeceasedKnackerySuburb] = useState('');
	const [informationAboutDeath, setInformationAboutDeath] = useState('');
	const [hasOwnerBeenNotified, setHasOwnerBeenNotified] = useState(false);
	const [finalCommentsNotes, setFinalCommentsNotes] = useState('');
	const [disableSubmitButton, setDisableSubmitbutton] = useState(true);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [isRetirementDetailsLoaded, setIsRetirementDetailsLoaded] = useState(
		false
	);
	const [shouldValidate, setShouldValidate] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);

	let isValidationErrors = false;
	/* ------------------------------- validation ------------------------------- */

	const validateYear = () => {
		if (!year || year == 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				year: {
					message: 'You must select a Year.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				year: {
					message: null
				}
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.year?.message) {
			validateYear();
		}
	}, [quarterNumber]);

	const validateQuarterNumber = () => {
		if (!quarterNumber || quarterNumber == 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				quarterNumber: {
					message: 'You must select a new Quarter Number.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				quarterNumber: {
					message: null
				}
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.quarterNumber?.message) {
			validateQuarterNumber();
		}
	}, [quarterNumber]);

	const validateDateOfAssessment = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				dateOfAssessment: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (!dateOfAssessment) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateOfAssessment: {
						message: 'You must select date of assessment.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateOfAssessment: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.dateOfAssessment?.message) {
			validateDateOfAssessment();
		}
	}, [dateOfAssessment]);

	const validateNewStatus = () => {
		if (hasHorseStatusChanged && !newStatus) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				newStatus: {
					message: 'You must select the new status.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				newStatus: {
					message: null
				}
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.newStatus?.message) {
			validateNewStatus();
		}
	}, [hasHorseStatusChanged, newStatus]);

	const validateAgistmentChangesToPhysicalHealth = () => {
		if (
			hasHorseStatusChanged &&
			agistmentHasPhysicalHealthChanged &&
			!agistmentChangesToPhysicalHealth
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				agistmentChangesToPhysicalHealth: {
					message: 'You must select the change to physical health.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				agistmentChangesToPhysicalHealth: {
					message: null
				}
			}));
		}
	};

	const validateAgistmentChangesToPhysicalHealthOther = () => {
		if (
			hasHorseStatusChanged &&
			agistmentChangesToPhysicalHealth == 4 &&
			!agistmentChangesToPhysicalHealthOther
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				agistmentChangesToPhysicalHealthOther: {
					message: 'You must enter details of other physical health changes'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				agistmentChangesToPhysicalHealthOther: {
					message: null
				}
			}));
		}
	};

	const validateAgistmentChangesToBehaviourOther = () => {
		if (
			hasHorseStatusChanged &&
			agistmentHasBehaviourChanged &&
			!agistmentChangesToBehaviourOther
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				agistmentChangesToBehaviourOther: {
					message: 'You must enter details of other behaviour changes'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				agistmentChangesToBehaviourOther: {
					message: null
				}
			}));
		}
	};

	const validateWormingDateCompleted = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				wormingDateCompleted: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (
				hasHorseStatusChanged &&
				treatmentSinceLastReport &&
				worming &&
				!wormingDateCompleted
			) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					wormingDateCompleted: {
						message: 'You must enter completed date of worming.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					wormingDateCompleted: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.wormingDateCompleted?.message) {
			validateWormingDateCompleted();
		}
	}, [worming, wormingDateCompleted]);

	const validateFarrierDateCompleted = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				farrierDateCompleted: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (
				hasHorseStatusChanged &&
				treatmentSinceLastReport &&
				farrier &&
				!farrierDateCompleted
			) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					farrierDateCompleted: {
						message: 'You must enter completed date of farrier.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					farrierDateCompleted: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.farrierDateCompleted?.message) {
			validateFarrierDateCompleted();
		}
	}, [farrier, farrierDateCompleted]);

	const validateDentistTreatmentDateCompleted = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				dentistTreatmentDateCompleted: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (
				hasHorseStatusChanged &&
				dentistTreatment &&
				treatmentSinceLastReport &&
				!dentistTreatmentDateCompleted
			) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dentistTreatmentDateCompleted: {
						message: 'You must enter completed date of dentist treatment.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dentistTreatmentDateCompleted: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.dentistTreatmentDateCompleted?.message) {
			validateDentistTreatmentDateCompleted();
		}
	}, [dentistTreatment, dentistTreatmentDateCompleted]);

	const validateHusbandryOther = () => {
		if (hasHorseStatusChanged && treatmentSinceLastReport && !husbandryOther) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				husbandryOther: {
					message: 'You must enter other details of husbandry.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				husbandryOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.husbandryOther?.message) {
			validateHusbandryOther();
		}
	}, [husbandryOther]);

	const validateRehabReason = () => {
		if (newStatus == 2 && !rehabReason) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabReason: {
					message: 'You must select a reason for rehabilitation.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabReason: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.rehabReason?.message) {
			validateRehabReason();
		}
	}, [rehabReason]);

	const validateInjuryType = () => {
		if (
			hasHorseStatusChanged &&
			newStatus != 1 &&
			(rehabReason == 1 || deceasedReasonOfDeath == 1) &&
			injuryType.length === 0
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				injuryType: {
					message: 'You must select injury type.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				injuryType: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.injuryType?.message) {
			validateInjuryType();
		}
	}, [injuryType]);

	const validateIllnessType = () => {
		if (
			hasHorseStatusChanged &&
			newStatus != 1 &&
			(rehabReason == 2 || deceasedReasonOfDeath == 2) &&
			illnessType.length === 0
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				illnessType: {
					message: 'You must select illness type.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				illnessType: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.illnessType?.message) {
			validateIllnessType();
		}
	}, [illnessType]);

	const validateRehabReasonOther = () => {
		if (hasHorseStatusChanged && rehabReason == 3 && !rehabReasonOther) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabReasonOther: {
					message: 'You must provide other reason for rehabilitation.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabReasonOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.rehabReasonOther?.message) {
			validateRehabReasonOther();
		}
	}, [rehabReasonOther]);

	const validateRehabDateCommenced = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabDateCommenced: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (hasHorseStatusChanged && newStatus == 2 && !rehabDateCommenced) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					rehabDateCommenced: {
						message: 'You must enter rehabilitation date commenced.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					rehabDateCommenced: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.rehabDateCommenced?.message) {
			validateRehabDateCommenced();
		}
	}, [rehabDateCommenced]);

	const validateDeceasedCauseOfDeath = () => {
		if (newStatus == 3 && !deceasedCauseOfDeath) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedCauseOfDeath: {
					message: 'You must select a cause of death.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedCauseOfDeath: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedCauseOfDeath?.message) {
			validateDeceasedCauseOfDeath();
		}
	}, [deceasedCauseOfDeath]);

	const validateDeceasedDateOfDeath = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedDateOfDeath: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (newStatus == 3 && !deceasedDateOfDeath) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					deceasedDateOfDeath: {
						message: 'You must select the deceased date.'
					}
				}));
				3;
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					deceasedDateOfDeath: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedDateOfDeath?.message) {
			validateDeceasedDateOfDeath();
		}
	}, [deceasedDateOfDeath]);

	useEffect(() => {
		if (isRetirementDetailsLoaded && quarterNumber != 0) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
		}
		setIsRetirementDetailsLoaded(false);
	}, [isRetirementDetailsLoaded]);

	useEffect(() => {
		if (shouldValidate) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	const validateDeceasedReasonOfDeath = () => {
		if (
			hasHorseStatusChanged &&
			deceasedCauseOfDeath == 2 &&
			!deceasedReasonOfDeath
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedReasonOfDeath: {
					message: 'You must select the reason of death.'
				}
			}));
			3;
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedReasonOfDeath: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedReasonOfDeath?.message) {
			validateDeceasedReasonOfDeath();
		}
	}, [deceasedReasonOfDeath]);

	const validateDeceasedInjuryDetailsOther = () => {
		if (injuryType?.find((e) => e.value == 12) && !deceasedInjuryDetailsOther) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedInjuryDetailsOther: {
					message: 'You must provide other injury details.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedInjuryDetailsOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedInjuryDetailsOther?.message) {
			validateDeceasedInjuryDetailsOther();
		}
	}, [deceasedInjuryDetailsOther]);

	const validateDeceasedIllnessDetailsOther = () => {
		if (
			illnessType?.find((s) => s.value == 4) &&
			!deceasedIllnessDetailsOther
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedIllnessDetailsOther: {
					message: 'You must provide other illness details.'
				}
			}));
			3;
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedIllnessDetailsOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedIllnessDetailsOther?.message) {
			validateDeceasedIllnessDetailsOther();
		}
	}, [deceasedIllnessDetailsOther]);

	const validateDeceasedReasonForDeathOther = () => {
		if (deceasedReasonOfDeath == 3 && !deceasedReasonForDeathOther) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedReasonForDeathOther: {
					message: 'You must provide other reason of death.'
				}
			}));
			3;
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedReasonForDeathOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedReasonForDeathOther?.message) {
			validateDeceasedReasonForDeathOther();
		}
	}, [deceasedReasonForDeathOther]);

	const validateFinalCommentsNotes = () => {
		if (finalCommentsNotes.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				finalCommentsNotes: {
					message: 'You must provide final comments or notes.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				finalCommentsNotes: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.finalCommentsNotes?.message) {
			validateFinalCommentsNotes();
		}
	}, [finalCommentsNotes]);

	const validateProgressPhotos = () => {
		if (newStatus != 3 && progressPhotos?.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				progressPhotos: { message: 'You must have at least one progress photo' }
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				progressPhotos: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.progressPhotos?.message) {
			validateProgressPhotos();
		}
	}, [progressPhotos]);

	const validateUncontrolledErrors = () => {
		validateYear();
		validateQuarterNumber();
		validateDateOfAssessment();
		validateAgistmentChangesToBehaviourOther();
		validateWormingDateCompleted();
		validateFarrierDateCompleted();
		validateDentistTreatmentDateCompleted();
		validateHusbandryOther();
		validateRehabReason();
		validateInjuryType();
		validateIllnessType();
		validateRehabReasonOther();
		validateRehabDateCommenced();
		validateDeceasedCauseOfDeath();
		validateDeceasedDateOfDeath();
		validateDeceasedReasonOfDeath();
		validateDeceasedInjuryDetailsOther();
		validateDeceasedIllnessDetailsOther();
		validateDeceasedReasonForDeathOther();
		validateFinalCommentsNotes();
		validateProgressPhotos();
		validateNewStatus();
		validateAgistmentChangesToPhysicalHealth();
		validateAgistmentChangesToPhysicalHealthOther();
	};

	const handleOnSubmit = () => {
		validateUncontrolledErrors();
		if (isValidationErrors) {
			setDisableSubmitbutton(true);
		} else {
			handleSave(true);
		}
	};

	const onSubmit = async () => {
		await validateUncontrolledErrors();
	};

	const handleSave = async (isSubmitReport = false) => {
		// if (
		// 	Object.values(uncontrolledErrors).every((item) => item.message === null)
		// ) {
		validateUncontrolledErrors();
		const filteredInjuries = injuryType.map((inj) => inj.value);
		const filteredIllnesses = illnessType.map((ill) => ill.value);
		let acknowledgedFormData = {
			memberId: currentUserMemberId,
			horseCode: horseCode,
			quarterNumber: quarterNumber == 0 ? null : quarterNumber,
			year,
			hasHorseStatusChanged: hasHorseStatusChanged
				? hasHorseStatusChanged
				: false,
			newStatus: newStatus == 0 ? null : newStatus,
			dateOfAssessment: dateOfAssessment,
			agistmentHasPhysicalHealthChanged: agistmentHasPhysicalHealthChanged
				? agistmentHasPhysicalHealthChanged
				: false,
			agistmentChangesToPhysicalHealth:
				agistmentChangesToPhysicalHealth == 0
					? null
					: agistmentChangesToPhysicalHealth,
			agistmentChangesToPhysicalHealthOther: agistmentChangesToPhysicalHealthOther,
			agistmentHasBehaviourChanged: agistmentHasBehaviourChanged
				? agistmentHasBehaviourChanged
				: false,
			agistmentChangesToBehaviourOther: agistmentChangesToBehaviourOther,
			worming: worming ? worming : false,
			wormingDateCompleted: wormingDateCompleted,
			farrier: farrier ? farrier : false,
			farrierDateCompleted: farrierDateCompleted,
			dentistTreatment: dentistTreatment ? dentistTreatment : false,
			dentistTreatmentDateCompleted: dentistTreatmentDateCompleted,
			husbandryOther: husbandryOther,
			rehabReason: rehabReason == 0 ? null : rehabReason,
			injuryType: filteredInjuries,
			illnessType: filteredIllnesses,
			rehabReasonOther: rehabReasonOther,
			rehabDateCommenced: rehabDateCommenced,
			treatmentSinceLastReport: treatmentSinceLastReport
				? treatmentSinceLastReport
				: false,
			deceasedCauseOfDeath:
				deceasedCauseOfDeath == 0 ? null : deceasedCauseOfDeath,
			deceasedDateOfDeath: deceasedDateOfDeath,
			deceasedReasonOfDeath:
				deceasedReasonOfDeath == 0 ? null : deceasedReasonOfDeath,
			deceasedInjuryDetailsOther: deceasedInjuryDetailsOther,
			deceasedIllnessDetailsOther: deceasedIllnessDetailsOther,
			deceasedReasonForDeathOther:
				deceasedReasonForDeathOther == 0 ? null : deceasedReasonForDeathOther,
			deceasedKnackeryPostcode: deceasedKnackeryPostcode,
			deceasedKnackerySuburb: deceasedKnackerySuburb,
			informationAboutDeath: informationAboutDeath,
			hasOwnerBeenNotified: hasOwnerBeenNotified ? hasOwnerBeenNotified : false,
			finalCommentsNotes: finalCommentsNotes
		};
		setIsLoading(true);
		AppSyncService.execute(saveRetirementQuarterlyAssessmentForm, {
			acknowledgedFormData
		}).then((data) => {
			if (data.data !== null) {
				progressPhotos.forEach((photo) => {
					AppSyncService.execute(saveResetReportingImage, {
						filename: photo.filename,
						key: photo.key,
						reportType: 'RetirementQuarterly',
						reportId: data.data.saveRetirementQuarterlyAssessmentForm.id,
						type: 'progress'
					});
				});
				setIsShowErrorMessages(true);
				if (!isSubmitReport) {
					reload();
					addToast({
						Message: 'Draft Saved',
						IsSuccess: true
					});
				} else {
					AppSyncService.execute(submitAckRetirementQuarterlyReportsForReview, {
						memberId: currentUserMemberId,
						horseCode: horseCode,
						quarterNumber: quarterNumber,
						year
					}).then((data) => {
						if (data?.data?.submitAckRetirementQuarterlyReportsForReview) {
							if (newStatus == 3) {
								const deceasedCauseOfDeathId =
									deceasedCauseOfDeath != 0
										? Number(deceasedCauseOfDeath)
										: null;
								const euthanisedReasonId =
									deceasedCauseOfDeathId == 2 && deceasedReasonOfDeath != 0
										? Number(deceasedReasonOfDeath)
										: null;
								const deceasedInput = {
									horseCode: Number(horseCode),
									euthanisedReasonId,
									deceasedThoroughbredSourceId: 5, //@NOTE - 1 is equal to Ack. Retirement Quarterly Assessment
									deceasedDateOfDeath: deceasedDateOfDeath,
									deceasedCauseOfDeathId: deceasedCauseOfDeathId,
									memberId: null,
									unsuitableReasons: [],
									euthanisedInjuryOther:
										deceasedCauseOfDeathId == 2 && euthanisedReasonId == 1
											? deceasedInjuryDetailsOther
											: null,
									euthanisedIllnessOther:
										deceasedCauseOfDeathId == 2 && euthanisedReasonId == 2
											? deceasedIllnessDetailsOther
											: null,
									euthanisedUnsuitableId: null,
									euthanisedUnsuitableOther: null,
									deceasedReasonForDeathOther:
										deceasedReasonForDeathOther &&
										deceasedCauseOfDeathId == 2 &&
										euthanisedReasonId == 3 &&
										!isNaN(Number(deceasedReasonForDeathOther))
											? [Number(deceasedReasonForDeathOther)]
											: [],
									deceasedKnackeryPostcode:
										deceasedCauseOfDeathId == 4
											? deceasedKnackeryPostcode
											: null,
									deceasedKnackerySuburb:
										deceasedCauseOfDeathId == 4 ? deceasedKnackerySuburb : null,
									deceasedOtherPostcode: null,
									deceasedOtherSuburb: null,
									informationAboutDeath:
										deceasedCauseOfDeathId == 6 ? informationAboutDeath : null,
									hasOwnerBeenNotified,
									status: 'Review',
									injuries:
										deceasedCauseOfDeathId == 2 &&
										euthanisedReasonId == 1 &&
										injuryType?.length > 0
											? injuryType.map((item) => item.value)
											: [],
									illnesses:
										deceasedCauseOfDeathId == 2 &&
										euthanisedReasonId == 2 &&
										illnessType?.length > 0
											? illnessType.map((item) => item.value)
											: []
								};
								AppSyncService.execute(saveDeceasedThoroughbredForm, {
									input: deceasedInput
								});
							}
							addToast({
								Message: 'Quarterly Retirement Form Submitted',
								IsSuccess: true
							});
							logToServer(
								currentUser?.UserId,
								data,
								'Quarterly Retirement Report form Submission Submitted and Passed'
							);
						} else {
							addToast({
								Message:
									'An error submitting. Racing Victoria are investigating.',
								IsSuccess: false
							});
							logToServer(
								currentUser?.UserId,
								data,
								'Quarterly Retirement Report form Submission Submitted and Failed'
							);
						}
					});
				}
			}
		});

		if (!isSubmitReport) {
			if (!isValidationErrors) {
				setDisableSubmitbutton(false);
			} else {
				setDisableSubmitbutton(false);
			}
		}
		setIsLoading(false);
	};

	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		fetchMemberData();
	}, []);

	const fetchMemberData = async () => {
		const result = await AppSyncService.execute(getMemberData, {
			externalId: currentUser.UserId
		});

		if (result?.data?.getMember) {
			setCurrentUserMemberId(result.data.getMember.id);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		if (currentUser !== null) {
			AppSyncService.execute(
				getAcknowledgedRetirementQuarterlyAssessmentReferences
			).then((result) => {
				if (result.data) {
					setReferenceQuarterList(
						extractLookUpOptionsOther(result, 'listAckRetirementQuarterTypes')
					);
					setReferenceQuarterList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setReferenceAgistmentChangesToHealthList(
						extractLookUpOptionsOther(
							result,
							'listAckRetirementChangesToHealthTypes'
						)
					);
					setReferenceAgistmentChangesToHealthList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setReferenceRehabReasonList(
						extractLookUpOptionsOther(
							result,
							'listAckRetirementRehabReasonTypes'
						)
					);
					setReferenceRehabReasonList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setReferenceInjuryList(
						extractLookUpOptionsOther(result, 'listAckRetirementInjuryTypes')
					);
					setReferenceInjuryList((prev) => [{ id: 0, value: '' }].concat(prev));
					setReferenceIllnessList(
						extractLookUpOptionsOther(result, 'listAckRetirementIllnessTypes')
					);
					setReferenceIllnessList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setReferenceCauseOfDeathList(
						extractLookUpOptionsOther(
							result,
							'listAckRetirementCauseOfDeathTypes'
						)
					);
					setReferenceCauseOfDeathList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setReferenceReasonOfDeathList(
						extractLookUpOptionsOther(
							result,
							'listAckRetirementReasonOfDeathTypes'
						)
					);
					setReferenceReasonOfDeathList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setReferenceDeceasedReasonOfDeathOtherList(
						extractLookUpOptionsOther(
							result,
							'listAckRetirementDeceasedReasonOfDeathOtherTypes'
						)
					);
					setReferenceDeceasedReasonOfDeathOtherList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
					setReferenceNewStatusList(
						extractLookUpOptionsOther(result, 'listAckRetirementNewStatusTypes')
					);
					setReferenceNewStatusList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
				}
			});
		}
		setIsLoading(false);
	}, []);

	useEffect(() => {
		if (year && quarterNumber && quarterNumber != 0) {
			setDisableSubmitbutton(true);
			loadQuarterlyRetirementForm();
		}
	}, [quarterNumber, year]);

	const loadQuarterlyRetirementForm = () => {
		const input = {
			memberExternalId: currentUser?.UserId,
			horseCode,
			quarterNumber: quarterNumber,
			year
		};
		try {
			AppSyncService.execute(
				getAcknowledgedRetirementQuarterlyAssessmentForm,
				input
			).then((result) => {
				if (result?.data?.getAcknowledgedRetirementQuarterlyAssessmentForm) {
					const initialData =
						result?.data?.getAcknowledgedRetirementQuarterlyAssessmentForm;

					if (initialData?.status == null) {
						setIsShowErrorMessages(false);
					} else {
						setIsShowErrorMessages(true);
					}

					updateDate(
						initialData.dateOfAssessment,
						setDateOfAssessment,
						validateDateOfAssessment
					);
					setHasHorseStatusChanged(initialData.hasHorseStatusChanged);
					setNewStatus(initialData.newStatus.id ?? 0);
					setAgistmentHasPhysicalHealthChanged(
						initialData.agistmentHasBehaviourChanged
					);
					setAgistmentChangesToPhysicalHealth(
						initialData.agistmentChangesToPhysicalHealth.id ?? 0
					);
					setAgistmentChangesToPhysicalHealthOther(
						initialData.agistmentChangesToPhysicalHealthOther ?? ''
					);
					setAgistmentHasBehaviourChanged(
						initialData.agistmentHasBehaviourChanged
					);
					setAgistmentChangesToBehaviourOther(
						initialData.agistmentChangesToBehaviourOther ?? ''
					);
					setWorming(!!initialData.worming);
					updateDate(
						initialData.wormingDateCompleted,
						setWormingDateCompleted,
						validateWormingDateCompleted
					);
					setFarrier(!!initialData.farrier);
					updateDate(
						initialData.farrierDateCompleted,
						setFarrierDateCompleted,
						validateFarrierDateCompleted
					);
					setDentistTreatment(!!initialData.dentistTreatment);
					updateDate(
						initialData.dentistTreatmentDateCompleted,
						setDentistTreatmentDateCompleted,
						validateDentistTreatmentDateCompleted
					);
					setHusbandryOther(initialData.husbandryOther ?? '');
					setRehabReason(initialData.rehabReason.id ?? 0);
					if (initialData.injuryType.length > 0) {
						const mapInjuries = initialData.injuryType.map((injury) => ({
							value: injury.id,
							label: injury.value
						}));
						setInjuryType(mapInjuries);
					}
					if (initialData.illnessType.length > 0) {
						const mapIllnesses = initialData.illnessType.map((illness) => ({
							value: illness.id,
							label: illness.value
						}));
						setIllnessType(mapIllnesses);
					}
					setRehabReasonOther(initialData.rehabReasonOther ?? '');
					updateDate(
						initialData.rehabDateCommenced
							? initialData.rehabDateCommenced
							: null,
						setRehabDateCommenced,
						validateRehabDateCommenced
					);
					setTreatmentSinceLastReport(initialData.treatmentSinceLastReport);
					setDeceasedCauseOfDeath(initialData.deceasedCauseOfDeath.id ?? 0);
					updateDate(
						initialData.deceasedDateOfDeath,
						setDeceasedDateOfDeath,
						validateDeceasedDateOfDeath
					);
					setDeceasedReasonOfDeath(initialData.deceasedReasonOfDeath.id ?? 0);
					setDeceasedInjuryDetailsOther(
						initialData.deceasedInjuryDetailsOther ?? ''
					);
					setDeceasedIllnessDetailsOther(
						initialData.deceasedIllnessDetailsOther ?? ''
					);
					setDeceasedReasonForDeathOther(
						initialData.deceasedReasonForDeathOther.id ?? 0
					);
					setDeceasedKnackeryPostcode(
						initialData.deceasedKnackeryPostcode ?? null
					);
					setDeceasedKnackerySuburb(initialData.deceasedKnackerySuburb ?? '');
					setFinalCommentsNotes(initialData.finalCommentsNotes ?? '');
					setProgressPhotos(initialData.progressPhotos ?? []);
					setDisableSubmitbutton(true);
					setUncontrolledErrors({});
					setIsReadOnly(
						initialData.status === REVIEW || initialData.status === APPROVED
					);
				}
				setIsRetirementDetailsLoaded(true);
			});
		} catch (error) {
			logToServer(currentUser?.UserId, error, 'Form fetch failed');
			addToast({ Message: 'Unable to fetch form', IsSuccess: false });
		}
	};

	const handleCancel = () => {
		deleteAllPhotos();
		setQuarterNumber(0);
		setDateOfAssessment(null);
		setHasHorseStatusChanged(false);
		setNewStatus(0);
		setAgistmentHasPhysicalHealthChanged(false);
		setAgistmentChangesToPhysicalHealth(null);
		setAgistmentChangesToPhysicalHealthOther(null);
		setAgistmentHasBehaviourChanged(false);
		setAgistmentChangesToBehaviourOther(null);
		setWorming(false);
		setWormingDateCompleted(null);
		setFarrier(false);
		setFarrierDateCompleted(null);
		setDentistTreatment(false);
		setDentistTreatmentDateCompleted(null);
		setHusbandryOther('');
		setRehabReason(0);
		setInjuryType([]);
		setIllnessType([]);
		setRehabReasonOther(null);
		setRehabDateCommenced(null);
		setTreatmentSinceLastReport(false);
		setDeceasedCauseOfDeath(0);
		setDeceasedDateOfDeath(null);
		setDeceasedReasonOfDeath(0);
		setDeceasedInjuryDetailsOther('');
		setDeceasedIllnessDetailsOther('');
		setDeceasedReasonForDeathOther(0);
		setDeceasedKnackeryPostcode(null);
		setDeceasedKnackerySuburb('');
		setInformationAboutDeath('');
		setHasOwnerBeenNotified(false);
		setFinalCommentsNotes('');

		setConfirmationModal(false);
	};

	// const onPostCodeChange = (newPostCode) => {
	// 	const isValidPostCode = validatePostCode(newPostCode);
	// 	if (isValidPostCode) {
	// 		setDeceasedKnackeryPostcode(newPostCode);
	// 	}
	// };

	const updateDate = (value, stateAssignmentFunction) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			stateAssignmentFunction(formattedDate);
		} else {
			stateAssignmentFunction(null);
		}
	};
	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key != img.key));
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
			}
		);
	};

	const deleteAllPhotos = () => {
		let listOfKeys = [];
		[progressPhotos].forEach((photoArray) => {
			photoArray.map((d) => {
				listOfKeys.push(d.key);
			});
		});
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					setProgressPhotos([]);
				}
			});
		}
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const renderAssessmentFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Year*</div>
							<FormDropDown
								showBlank
								id={HorseManagementKeys.YEAR}
								items={referenceYearList}
								selectedId={year}
								onChange={(e) => {
									setYear(e.target.value);
								}}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.year?.message}
								</span>
							)}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Quarter Number*
								<FormToolTip>
									The quarter of the year the form applies to
								</FormToolTip>
							</div>
							<FormDropDown
								showBlank
								id={HorseManagementKeys.QUARTER_NUMBER}
								items={referenceQuarterList}
								selectedId={quarterNumber}
								onChange={(e) => {
									setQuarterNumber(e.target.value);
								}}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.quarterNumber?.message}
								</span>
							)}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Date of Assessment*"
										value={formatDateReadableVariant(dateOfAssessment)}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Date of Assessment*</div>
								<FormDatePicker
									DateValue={dateOfAssessment}
									id={HorseManagementKeys.DATE_OF_ASSESSMENT}
									updateSelection={(value) => {
										updateDate(
											value,
											setDateOfAssessment,
											validateDateOfAssessment
										);
										setShouldValidate(true);
									}}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.dateOfAssessment?.message}
									</span>
								)}
							</div>
						)}
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainerAdjustedPaddingTop}>
							<div className={classes.fieldTitle}>
								Has the horse&apos;s status changed?*
							</div>
							<Toggle
								id={HorseManagementKeys.HORSE_STATUS_CHANGE}
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									if (!value) {
										setNewStatus(0);
									}
									setHasHorseStatusChanged(value);
									setShouldValidate(true);
								}}
								value={hasHorseStatusChanged}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
					{hasHorseStatusChanged ? (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>New Status*</div>
								<FormDropDown
									showBlank
									id={HorseManagementKeys.NEW_STATUS}
									items={referenceNewStatusList}
									selectedId={newStatus}
									onChange={(e) => {
										setNewStatus(e.target.value);
										// if new status is changed,
										// set treatment since last report to false
										setTreatmentSinceLastReport(false);
										setShouldValidate(true);
										setIllnessType([]);
										setInjuryType([]);
										setRehabReason([]);
									}}
									disabled={isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.newStatus?.message}
									</span>
								)}
							</div>
						</Col>
					) : (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>Current Status</div>
								<div className={classes.statusTitle}>
									{horseData?.currentStatus?.value}
								</div>
							</div>
						</Col>
					)}
					{newStatus != 3 && (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainerNoPaddingTop}>
								<div className={classes.fieldTitle}>
									Progress Photo(s)* (1 required - jpeg, png)
								</div>
								<UploadPhoto
									id={HorseManagementKeys.QUARTERLY_PROGRESS_PHOTOS}
									formats={['png', 'jpeg']}
									labelName="Upload Progress Photo(s)"
									error={uploadError}
									data={progressPhotos}
									sizeLimitMB={5}
									numberOfImages={10}
									success={(e) => photosUpload(e, setProgressPhotos)}
									getImage={getImage}
									removeImage={(e) =>
										removePhoto(e, progressPhotos, setProgressPhotos)
									}
									canEdit={!isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.progressPhotos?.message}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
			</div>
		);
	};

	const renderAgistmentFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldTitle}>
							Are there any changes to the horse&apos;s physical health?*
						</div>
						<Toggle
							id={HorseManagementKeys.PHYSICAL_HEALTH_CHANGES}
							labelNegative="No"
							labelPositive="Yes"
							onClick={(value) => {
								setAgistmentHasPhysicalHealthChanged(value);
								setShouldValidate(true);
							}}
							value={agistmentHasPhysicalHealthChanged}
							disabled={isReadOnly}
						/>
					</Col>

					{agistmentHasPhysicalHealthChanged && (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Please specify what change*
								</div>
								<FormDropDown
									showBlank
									name="agistmentChangesToPhysicalHealth"
									id={HorseManagementKeys.PHYSICAL_CHANGE}
									items={referenceAgistmentChangesToHealthList}
									selectedId={agistmentChangesToPhysicalHealth}
									onChange={(e) => {
										setAgistmentChangesToPhysicalHealth(e.target.value);
										setShouldValidate(true);
									}}
									disabled={isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{
											uncontrolledErrors.agistmentChangesToPhysicalHealth
												?.message
										}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					{agistmentHasPhysicalHealthChanged &&
						agistmentChangesToPhysicalHealth == changesToHealthOther && (
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Please specify*</div>
									<ResizingTextArea
										name="agistmentChangesToPhysicalHealthOther"
										id={HorseManagementKeys.PLEASE_SPECIFY}
										value={agistmentChangesToPhysicalHealthOther}
										onChange={(e) => {
											setAgistmentChangesToPhysicalHealthOther(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={700}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{
												uncontrolledErrors.agistmentChangesToPhysicalHealthOther
													?.message
											}
										</span>
									)}
								</div>
							</Col>
						)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Are there any changes to the horse&apos;s behaviour?*
							</div>
							<Toggle
								id={HorseManagementKeys.BEHAVIOUR_CHANGES}
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setAgistmentHasBehaviourChanged(value);
									setShouldValidate(true);
								}}
								value={agistmentHasBehaviourChanged}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					{agistmentHasBehaviourChanged && (
						<Col xs={12} lg={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Please provide more information*
								</div>
								<ResizingTextArea
									name="agistmentChangesToBehaviourOther"
									id={HorseManagementKeys.PROVIDE_MORE_INFORMATION}
									onChange={(e) => {
										setAgistmentChangesToBehaviourOther(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={700}
									value={agistmentChangesToBehaviourOther}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{
											uncontrolledErrors.agistmentChangesToBehaviourOther
												?.message
										}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Has the horse received any treatments since the last report?*
							</div>
							<Toggle
								id={HorseManagementKeys.RECEIVED_ANY_TREATMENTS}
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setTreatmentSinceLastReport(value);
									setShouldValidate(true);
								}}
								value={treatmentSinceLastReport}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	const renderTreatmentFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Worming</div>
							<Toggle
								id={HorseManagementKeys.WORMING}
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setWorming(value);
									setShouldValidate(true);
								}}
								value={worming}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
					{worming && (
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<Row>
									<Col>
										<FormLabelField
											title="Date of Completion*"
											value={formatDateReadableVariant(wormingDateCompleted)}
										/>
									</Col>
								</Row>
							) : (
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Date of Completion*</div>

									<FormDatePicker
										id={HorseManagementKeys.WORMING_DATE_OF_COMPLETION}
										DateValue={wormingDateCompleted}
										updateSelection={(value) => {
											updateDate(
												value,
												setWormingDateCompleted,
												validateWormingDateCompleted
											);
											setShouldValidate(true);
										}}
										canEdit={!isReadOnly}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.wormingDateCompleted?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Farrier</div>
							<Toggle
								id={HorseManagementKeys.FARRIER}
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setFarrier(value);
									setShouldValidate(true);
								}}
								value={farrier}
								disabled={isReadOnly}
							/>
						</div>
					</Col>

					{farrier && (
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<Row>
									<Col>
										<FormLabelField
											title="Date of Completion*"
											value={formatDateReadableVariant(farrierDateCompleted)}
										/>
									</Col>
								</Row>
							) : (
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Date of Completion*</div>
									<FormDatePicker
										id={HorseManagementKeys.FARRIER_DATE_OF_COMPLETION}
										DateValue={farrierDateCompleted}
										updateSelection={(value) => {
											updateDate(
												value,
												setFarrierDateCompleted,
												validateFarrierDateCompleted
											);
											setShouldValidate(true);
										}}
										canEdit={!isReadOnly}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.farrierDateCompleted?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Dentist Treatment</div>
							<Toggle
								id={HorseManagementKeys.DENTIST}
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setDentistTreatment(value);
									setShouldValidate(true);
								}}
								value={dentistTreatment}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
					{dentistTreatment && (
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<Row>
									<Col>
										<FormLabelField
											title="Date of Completion*"
											value={formatDateReadableVariant(
												dentistTreatmentDateCompleted
											)}
										/>
									</Col>
								</Row>
							) : (
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Date of Completion*</div>
									<FormDatePicker
										id={HorseManagementKeys.DENTIST_DATE_OF_COMPLETION}
										DateValue={dentistTreatmentDateCompleted}
										updateSelection={(value) => {
											updateDate(
												value,
												setDentistTreatmentDateCompleted,
												validateDentistTreatmentDateCompleted
											);
											setShouldValidate(true);
										}}
										canEdit={!isReadOnly}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{
												uncontrolledErrors.dentistTreatmentDateCompleted
													?.message
											}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={6}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Other Husbandry Information*
							</div>
							<FormTextField
								name="husbandryOther"
								id={HorseManagementKeys.OTHER_HUSBANDRY_INFO}
								onChange={(e) => {
									setHusbandryOther(e.target.value);
									setShouldValidate(true);
								}}
								value={husbandryOther}
								canEdit={!isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.husbandryOther?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	const renderRehabFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Please indicate reason for rehabilitation*
							</div>
							<FormDropDown
								showBlank
								name="rehabReason"
								id={HorseManagementKeys.REHABILITATION_REASON}
								items={referenceRehabReasonList}
								selectedId={rehabReason}
								onChange={(e) => {
									setRehabReason(e.target.value);
									setShouldValidate(true);
									setIllnessType([]);
									setInjuryType([]);
								}}
								disabled={isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.rehabReason?.message}
								</span>
							)}
						</div>
					</Col>
					{rehabReason == reasonForRehab.INJURY && (
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<FormLabelField
									title={`Injury Type*`}
									value={injuryType
										.map((seledctedInjury) => seledctedInjury.label)
										.join()}
									style={{ padding: 0 }}
								/>
							) : (
								<div
									className={classes.fieldContainerPaddingTopRehabMultiSelect}
								>
									<MultiSelectInputField
										idPrefix={HorseManagementKeys.INJURIES}
										fieldLabel={`Injury Type*`}
										options={referenceInjuryList}
										selectedIds={injuryType}
										onSelectChange={(vals) => {
											setInjuryType(vals);
											setShouldValidate(true);
										}}
										otherDisabled={true}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.injuryType?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
					{rehabReason == reasonForRehab.ILLNESS && (
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<FormLabelField
									title={`Illness Type*`}
									value={illnessType
										.map((selectedIllness) => selectedIllness.label)
										.join()}
									style={{ padding: 0 }}
								/>
							) : (
								<div
									className={classes.fieldContainerPaddingTopRehabMultiSelect}
								>
									<MultiSelectInputField
										idPrefix={HorseManagementKeys.ILLNESS}
										fieldLabel={`Illness Type*`}
										options={referenceIllnessList}
										selectedIds={illnessType}
										onSelectChange={(vals) => {
											setIllnessType(vals);
											setShouldValidate(true);
										}}
										otherDisabled={true}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.illnessType?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
				</Row>
				{rehabReason == reasonForRehab.OTHER && (
					<Row>
						<Col xs={12} lg={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Other Retirement Rehabilitation*
								</div>
								<ResizingTextArea
									name="rehabReasonOther"
									id={HorseManagementKeys.OTHER_REHABILITATION_REASON}
									value={rehabReasonOther}
									onChange={(e) => {
										setRehabReasonOther(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={700}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.rehabReasonOther?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
				)}
				{injuryType.map((i, index) => {
					if (i.label === 'Other') {
						return (
							<Row key={index}>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Other injury details*
										</div>
										<ResizingTextArea
											name="deceasedInjuryDetailsOther"
											id="acknowledgedRetirement-deceasedInjuryDetailsOther"
											value={deceasedInjuryDetailsOther}
											onChange={(e) => {
												setDeceasedInjuryDetailsOther(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={700}
											styles={{
												marginLeft: 0,
												marginRight: 0,
												padding: 0
											}}
											titleStyles={{ fontSize: '14px' }}
											canEdit={!isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.deceasedInjuryDetailsOther?.message}
											</span>
										)}
									</div>
								</Col>
							</Row>
						);
					}
				})}
				{illnessType.map((i, index) => {
					if (i.label === 'Other') {
						return (
							<Row key={index}>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Other illness details*
										</div>
										<ResizingTextArea
											name="deceasedIllnessDetailsOther"
											id="acknowledgedRetirement-deceasedIllnessDetailsOther"
											value={deceasedIllnessDetailsOther}
											onChange={(e) => {
												setDeceasedIllnessDetailsOther(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={700}
											styles={{
												marginLeft: 0,
												marginRight: 0,
												padding: 0
											}}
											titleStyles={{ fontSize: '14px' }}
											canEdit={!isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{
													uncontrolledErrors.deceasedIllnessDetailsOther
														?.message
												}
											</span>
										)}
									</div>
								</Col>
							</Row>
						);
					}
				})}
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Date rehabilitation commenced*"
										value={formatDateReadableVariant(rehabDateCommenced)}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>
									Date rehabilitation commenced*
								</div>
								<FormDatePicker
									id={HorseManagementKeys.REHABILITATION_COMMENCED}
									DateValue={rehabDateCommenced}
									updateSelection={(value) => {
										updateDate(
											value,
											setRehabDateCommenced,
											validateRehabDateCommenced
										);
										setShouldValidate(true);
									}}
									canEdit={!isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.rehabDateCommenced?.message}
									</span>
								)}
							</div>
						)}
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainerNoPaddingTop}>
							<div className={classes.fieldTitle}>
								Has the horse received any treatments since the last report?*
							</div>
							<Toggle
								id={HorseManagementKeys.RECEIVED_ANY_TREATMENTS}
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setTreatmentSinceLastReport(value);
									setShouldValidate(true);
								}}
								value={treatmentSinceLastReport}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	const renderDeceasedFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								What was the cause of death?*
							</div>
							<FormDropDown
								showBlank
								name="deceasedCauseOfDeath"
								id={HorseManagementKeys.CAUSE_OF_DEATH}
								items={referenceCauseOfDeathList}
								selectedId={deceasedCauseOfDeath}
								onChange={(e) => {
									setDeceasedCauseOfDeath(e.target.value);
									setShouldValidate(true);
								}}
								disabled={isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.deceasedCauseOfDeath?.message}
								</span>
							)}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Date of Death*"
										value={formatDateReadableVariant(deceasedDateOfDeath)}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Date of Death*</div>
								<FormDatePicker
									id={HorseManagementKeys.DATE_OF_DEATH}
									DateValue={deceasedDateOfDeath}
									updateSelection={(value) => {
										updateDate(
											value,
											setDeceasedDateOfDeath,
											validateDeceasedDateOfDeath
										);
										setShouldValidate(true);
									}}
									canEdit={!isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.deceasedDateOfDeath?.message}
									</span>
								)}
							</div>
						)}
					</Col>
				</Row>
				<Row>
					{deceasedCauseOfDeath == 2 && (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Reason*</div>
								<FormDropDown
									showBlank
									name="deceasedReasonOfDeath"
									id="acknowledgedRetirement-deceasedReasonOfDeath"
									items={referenceReasonOfDeathList}
									selectedId={deceasedReasonOfDeath}
									onChange={(e) => {
										setDeceasedReasonOfDeath(e.target.value);
										setShouldValidate(true);
										setIllnessType([]);
										setInjuryType([]);
									}}
									disabled={isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.deceasedReasonOfDeath?.message}
									</span>
								)}
							</div>
						</Col>
					)}
					{deceasedReasonOfDeath == reasonForDeath.INJURY && (
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<FormLabelField
									title={`Injury Type*`}
									value={injuryType
										.map((seledctedInjury) => seledctedInjury.label)
										.join()}
									style={{ padding: 0 }}
								/>
							) : (
								<div
									className={
										classes.fieldContainerPaddingTopDeceasedMultiSelect
									}
								>
									<MultiSelectInputField
										idPrefix={`injuries`}
										fieldLabel={`Injury Type*`}
										options={referenceInjuryList}
										selectedIds={injuryType}
										onSelectChange={(vals) => {
											setInjuryType(vals);
											setShouldValidate(true);
										}}
										otherDisabled={true}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.injuryType?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
					{deceasedReasonOfDeath == reasonForDeath.ILLNESS && (
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<FormLabelField
									title={`Illness Type*`}
									value={illnessType
										.map((seledctedInjury) => seledctedInjury.label)
										.join()}
									style={{ padding: 0 }}
								/>
							) : (
								<div
									className={
										classes.fieldContainerPaddingTopDeceasedMultiSelect
									}
								>
									<MultiSelectInputField
										idPrefix={`illnesses`}
										fieldLabel={`Illness Type*`}
										options={referenceIllnessList}
										selectedIds={illnessType}
										onSelectChange={(vals) => {
											setIllnessType(vals);
											setShouldValidate(true);
										}}
										otherDisabled={true}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.illnessType?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
					{deceasedReasonOfDeath == reasonForDeath.OTHER && (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Other reason for death*
								</div>
								<FormDropDown
									showBlank
									name="deceasedReasonForDeathOther"
									id="acknowledgedRetirement-deceasedReasonForDeathOther"
									items={referenceDeceasedReasonOfDeathOtherList}
									selectedId={deceasedReasonForDeathOther}
									onChange={(e) => {
										setDeceasedReasonForDeathOther(e.target.value);
										setShouldValidate(true);
									}}
									disabled={isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.deceasedReasonForDeathOther?.message}
									</span>
								)}
							</div>
						</Col>
					)}
				</Row>

				{injuryType.map((i, index) => {
					if (i.label === 'Other') {
						return (
							<Row key={index}>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Other injury details*
										</div>
										<ResizingTextArea
											name="deceasedInjuryDetailsOther"
											id="acknowledgedRetirement-deceasedInjuryDetailsOther"
											value={deceasedInjuryDetailsOther}
											onChange={(e) => {
												setDeceasedInjuryDetailsOther(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={700}
											styles={{
												marginLeft: 0,
												marginRight: 0,
												padding: 0
											}}
											titleStyles={{ fontSize: '14px' }}
											canEdit={!isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.deceasedInjuryDetailsOther?.message}
											</span>
										)}
									</div>
								</Col>
							</Row>
						);
					}
				})}
				{illnessType.map((i, index) => {
					if (i.label === 'Other') {
						return (
							<Row key={index}>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Other illness details*
										</div>
										<ResizingTextArea
											name="deceasedIllnessDetailsOther"
											id="acknowledgedRetirement-deceasedIllnessDetailsOther"
											value={deceasedIllnessDetailsOther}
											onChange={(e) => {
												setDeceasedIllnessDetailsOther(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={700}
											styles={{
												marginLeft: 0,
												marginRight: 0,
												padding: 0
											}}
											titleStyles={{ fontSize: '14px' }}
											canEdit={!isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{
													uncontrolledErrors.deceasedIllnessDetailsOther
														?.message
												}
											</span>
										)}
									</div>
								</Col>
							</Row>
						);
					}
				})}

				{deceasedCauseOfDeath == 4 && (
					<Row>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Knackery Postcode</div>
								<FormTextField
									name="deceasedKnackeryPostcode"
									id="acknowledgedRetirement-deceasedKnackeryPostcode"
									onChange={(e) => {
										const isValidPostcode = validatePostCode(e.target.value);
										if (isValidPostcode) {
											setDeceasedKnackeryPostcode(e.target.value);
										}
									}}
									value={deceasedKnackeryPostcode}
									canEdit={!isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.deceasedKnackeryPostcode?.message}
									</span>
								)}
							</div>
						</Col>
						<Col xs={12} lg={6}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Knackery Suburb</div>
								<ResizingTextArea
									name="deceasedKnackerySuburb"
									id="acknowledgeRetrainer-deceasedKnackerySuburb"
									value={deceasedKnackerySuburb}
									onChange={(e) => {
										setDeceasedKnackerySuburb(e.target.value);
									}}
									maxChars={100}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.deceasedKnackerySuburb?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
				)}
				{deceasedCauseOfDeath == 6 && (
					<Row>
						<Col xs={12} lg={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Information about death
								</div>
								<ResizingTextArea
									name="informationAboutDeath"
									id="acknowledgeRetrainer-informationAboutDeath"
									value={informationAboutDeath}
									onChange={(e) => {
										setInformationAboutDeath(e.target.value);
									}}
									maxChars={700}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.informationAboutDeath?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
				)}

				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Has the owner(s) been notified?*
							</div>
							<Toggle
								id={HorseManagementKeys.OWNER_BEEN_NOTIFIED}
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setHasOwnerBeenNotified(value);
									setShouldValidate(true);
								}}
								value={hasOwnerBeenNotified}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	const renderMiscFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<ResizingTextArea
								title="Final Comments or notes*"
								name="finalCommentsNotes"
								id={HorseManagementKeys.FINAL_COMMENTS}
								value={finalCommentsNotes}
								onChange={(e) => {
									setFinalCommentsNotes(e.target.value);
									setShouldValidate(true);
								}}
								maxChars={700}
								styles={{
									marginLeft: 0,
									marginRight: 0,
									padding: 0
								}}
								titleStyles={{ fontSize: '14px' }}
								canEdit={!isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.finalCommentsNotes?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<div className={classes.container}>
			{isLoading ? (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			) : (
				<div className={classes.formContainer}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={classes.formHeading}>Quarterly Progress Form</div>
						<Container>
							<>{renderAssessmentFields()}</>
							{hasHorseStatusChanged && newStatus == 1 && (
								<>{renderAgistmentFields()}</>
							)}
							{hasHorseStatusChanged && newStatus == 2 && (
								<>{renderRehabFields()}</>
							)}
							{hasHorseStatusChanged && newStatus == 3 && (
								<>{renderDeceasedFields()}</>
							)}
							{hasHorseStatusChanged && treatmentSinceLastReport && (
								<>{renderTreatmentFields()}</>
							)}
							<>{renderMiscFields()}</>
							{imgSrcUrl && (
								<Lightbox
									mainSrc={imgSrcUrl}
									onCloseRequest={() => setImgSrcUrl(false)}
								/>
							)}
							<hr className={classes.IdentificationDivider}></hr>

							<div
								style={{
									display: 'flex',
									justifyContent: `${
										isReadOnly ? 'flex-end' : 'space-between'
									}`,
									alignItems: 'center'
								}}
							>
								{!isReadOnly && (
									<div
										id={CommonKeys.CLEAR_FORM}
										className={classes.clearFormLink}
										onClick={() => {
											setConfirmationModal(true);
										}}
									>
										Clear Form
									</div>
								)}
								<div className={classes.saveRequest}>
									<span className={classes.saveButton}>
										<PrimaryButton
											id={CommonKeys.SAVE}
											isNonSubmit={false}
											style={{ minWidth: '170px' }}
											onClick={() => handleSave()}
											disabled={isReadOnly}
										>
											Save
										</PrimaryButton>
									</span>
									<span>
										<PrimaryButton
											id={HorseManagementKeys.SUBMIT_REPORT}
											isNonSubmit={false}
											disabled={disableSubmitButton || isReadOnly}
											style={{ minWidth: '170px' }}
											onClick={() => handleOnSubmit()}
										>
											Submit Report
										</PrimaryButton>
									</span>
								</div>
							</div>
						</Container>
						{imgSrcUrl && (
							<Lightbox
								mainSrc={imgSrcUrl}
								onCloseRequest={() => setImgSrcUrl(false)}
							/>
						)}
					</form>
					<ConfirmationModal
						showModal={confirmationModal}
						closeModal={() => setConfirmationModal(false)}
						styles={modalStylesConfirmation}
						heading="Are you sure you want to clear the form?"
						positiveLabel="Confirm"
						negativeLabel="Cancel"
						onClickPositive={handleCancel}
					>
						Cancel quarterly progress form?
					</ConfirmationModal>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				</div>
			)}
		</div>
	);
};

export default AcknowledgedRetirementQuarterlyAssessmentForm;
