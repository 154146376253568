import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { Container } from 'react-grid-system';
import SEO from '../seo';
import AvailableForRetrainingRecord from '../AvailableForRetrainingRecord';
import SelectInputField from '../FormComponents/SelectInputField/index.js';
import { HorseStatus, HorseStatusType } from '../../utils/constants/index';
import { Typography, Link } from '@material-ui/core';
import LoaderSpinner from '../LoaderSpinner';
import { navigate } from '@reach/router';
import { extractLookUpOptions } from '../../utils/helpers/index.js';
import AppSyncService from '../../graphql/AppSyncService.js';
import { getOttPrograms } from '../../graphql/custom.queries.js';

const AvailableForRetraining = ({
	listingsData,
	isLoading,
	isRetrainerOnly,
	isRetrainerWithResetExpress,
	isAdmin,
	selectedHorseStatus,
	setSelectedHorseStatus
}) => {
	const classes = useStyles();
	const setDefaultOTTStatus = (selectedHorseStatus) => {
		switch (parseInt(selectedHorseStatus)) {
			case HorseStatusType.AvailableForRetraining:
				return 6;
			case HorseStatusType.EligibleForReset:
				return 10;
			case HorseStatusType.ResetExpressHorses:
				return 37;
			default:
				return null;
		}
	};

	const [selectedOTTStatus, setSelectedOTTStatus] = useState(
		setDefaultOTTStatus(selectedHorseStatus)
	);
	const [programOptions, setProgramOptions] = useState([]);

	useEffect(() => {
		AppSyncService.execute(getOttPrograms, {}).then((result) => {
			setProgramOptions(extractLookUpOptions(result, 'getOttPrograms'));
		});
	}, []);

	const horseStatusOptions = () => {
		const options =
			isAdmin || isRetrainerWithResetExpress
				? HorseStatus
				: HorseStatus.filter(
						(item) => item.id !== HorseStatusType.ResetExpressHorses
				  );
		return options;
	};

	const ottStatusOptions = () => {
		return (
			programOptions.filter(
				(item) => item.id === parseInt(selectedHorseStatus)
			)[0]?.statuses ?? []
		);
	};

	const filteredListings = isAdmin ? listingsData.filter(
		(selectedData) =>
			selectedData.horseStatusSubtype === parseInt(selectedHorseStatus) &&
			selectedData.horseOTTStatusID === parseInt(selectedOTTStatus)
	) : listingsData.filter(
		(selectedData) =>
			selectedData.horseStatusSubtype === parseInt(selectedHorseStatus)
	)

	return (
		<>
			<Container className={classes.pageContainer}>
				<SEO title="Available for Retraining" />
				<div className={classes.selectContainer}>
					<SelectInputField
						id={`available-for-retraining-select-input`}
						label="Horse Status"
						value={selectedHorseStatus}
						options={horseStatusOptions()}
						onChange={(e) => {
							setSelectedHorseStatus(e.target.value);
							setSelectedOTTStatus(setDefaultOTTStatus(e.target.value));
						}}
					/>
					{isAdmin &&
					<SelectInputField
						id={`available-for-retraining-select-input-ott-status`}
						label="OTT Status"
						value={selectedOTTStatus}
						options={ottStatusOptions()}
						onChange={(e) => setSelectedOTTStatus(e.target.value)}
					/>
					}
				</div>
				{isLoading ? (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				) : filteredListings.length > 0 ? (
					filteredListings.map((listing) => (
						<AvailableForRetrainingRecord
							selectedHorseStatus={selectedHorseStatus}
							key={listing.id}
							data={listing}
							isAdmin={isAdmin}
							isRetrainerOnly={isRetrainerOnly}
							isRetrainerWithResetExpress={isRetrainerWithResetExpress}
						/>
					))
				) : (
					<div className={classes.noListingAvailableContainer}>
						<Typography paragraph>No listing available.</Typography>

						{isRetrainerOnly &&
						selectedHorseStatus != HorseStatusType.AvailableForRetraining ? (
							<Typography>
								Once a RESET horse has been assigned to you, their Available for
								Retraining listing will be displayed here. If you believe there
								is a mistake, please{' '}
								<Link
									underline="always"
									onClick={() => navigate('/contact-us')}
								>
									Contact Us
								</Link>{' '}
							</Typography>
						) : null}
					</div>
				)}
			</Container>
		</>
	);
};
export default AvailableForRetraining;
