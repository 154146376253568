import React from 'react';
import FormSection from '../../../FormComponents/FormSection';
import { Row, Col } from 'react-grid-system';
import DateInputField from '../../../FormComponents/DateInputField';
import SelectInputField from '../../../FormComponents/SelectInputField';
import TextInputField from '../../../FormComponents/TextInputField';
import ResizingTextArea from '../../../ResizingTextArea';
import {
	formatDate,
	isMultiSelectOptionOther
} from '../../../../utils/helpers';
import {
	PAGE_ID,
	WELFARE_CHECK,
	AUDIT,
	DEFAULT_PROGRAM,
	FOSTER_CHECK,
	EMERGENCY_AID_PROGRAM,
	PRINCIPAL_RACING_AUTHORITY
} from '../../constants';
import { isOptionOther, checkDropDownValue } from '../../../../utils/helpers';
import { BLANK_OPTION } from '../../../../utils/constants';
import { checkStableLocationChanged } from '../../helpers';
import useStyles from './styles';
import FormMultiSelectList from '../../../FormMultiSelectList';
import FormFieldWrapper from '../../../FormComponents/FormFieldWrapper';

const OTTStatusForm = ({
	stableLocationFormState,
	horseData,
	formState,
	updateFormState,
	lookUpData,
	initialProgramAndStatus,
	setIsEmailRetrainers
}) => {
	const classes = useStyles();
	const {
		asOfDate,
		program,
		ottStatus,
		locationSource,
		otherLocationSource,
		welfareSource,
		otherWelfareSource,
		personsOfInterest,
		welfareRelationship,
		otherWelfareRelationship,
		welfareComments,
		auditName,
		personOfInterestAudit,
		relationshipAudit,
		otherRelationshipAudit,
		auditComments,
		auditOther,
		fosterReasonForEnteringProgram,
		fosterAssistanceType,
		fosterReasonForEnteringProgramOther,
		fosterAssistanceTypeOther,
		principalRacingAuthority,
		principalRacingAuthorityOther
	} = formState;
	const {
		programOptions,
		locationSourceOptions,
		welfareSourceOptions,
		welfareRelationshipOptions,
		auditNameOptions,
		fosterReasonForEnteringOptions,
		fosterAssistanceTypeOptions,
		principalRacingAuthorityOptions
	} = lookUpData;
	const statusOptions =
		programOptions
			?.find(({ id }) => id == program.value)
			?.statuses.filter((status) => status.id !== 17) || [];
	const isOtherLocationSource = isOptionOther(
		locationSourceOptions,
		locationSource.value
	);
	const isLocationSourceWelfareCheck = checkDropDownValue(
		locationSourceOptions,
		locationSource.value,
		WELFARE_CHECK
	);
	const isLocationSourceFosterCheck = checkDropDownValue(
		locationSourceOptions,
		locationSource.value,
		FOSTER_CHECK
	);

	const isPrincipalRacingAuthority = checkDropDownValue(
		locationSourceOptions,
		locationSource.value,
		PRINCIPAL_RACING_AUTHORITY
	);

	const isOtherWelfareSource = isOptionOther(
		welfareSourceOptions,
		welfareSource.value
	);

	const isOtherPrincipalRacingAuthority = isOptionOther(
		principalRacingAuthorityOptions,
		principalRacingAuthority.value
	);

	const isOtherFosterReason = isOptionOther(
		fosterReasonForEnteringOptions,
		fosterReasonForEnteringProgram.value
	);
	const isOtherFosterAssistance =
		fosterAssistanceType.value && fosterAssistanceType.value.length > 0
			? isMultiSelectOptionOther(fosterAssistanceType.value)
			: false;

	const isOtherWelfareRelationship = isOptionOther(
		welfareRelationshipOptions,
		welfareRelationship.value
	);
	const isLocationSourceAudit = checkDropDownValue(
		locationSourceOptions,
		locationSource.value,
		AUDIT
	);
	const isOtherAuditRelationship = isOptionOther(
		welfareRelationshipOptions,
		relationshipAudit.value
	);
	const isOtherAuditName = isOptionOther(auditNameOptions, auditName.value);

	const sortedAuditNameOption = auditNameOptions.sort((a, b) =>
		a.value.localeCompare(b.value)
	);

	const handleChange = (name, value, validationError = '') => {
		updateFormState((prevState) => ({
			...prevState,
			[name]: {
				...prevState[name],
				value,
				validationError: validationError
			}
		}));
	};

	const handleChangeMultiSelect = (val, name, fieldsToClear) => {
		let items = { ...formState };
		let item = {
			...items[name],
			value: val,
			validationError: val?.length > 0 ? '' : 'Assistance Type is required'
		};
		items[name] = item;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		updateFormState(items);
	};

	return (
		<FormSection sectionTitle="OTT Status" isAdminSection>
			<Col className={classes.formSectionContent}>
				<Row>
					<SelectInputField
						id={`${PAGE_ID}-program-select`}
						label="Program"
						options={
							programOptions.length > 0
								? programOptions.map(({ id, value }) => {
										value = value == 'RESET Express' ? 'PRESET' : value;
										return { id, value };
								  })
								: []
						}
						value={program.value}
						onChange={(event) => {
							const newProgram = event.target.value;
							updateFormState((prevState) => ({
								...prevState,
								program: { value: newProgram, validationError: '' },
								ottStatus: {
									value: programOptions
										.find(({ id }) => id == newProgram)
										.statuses.find((status) => status.default).id,
									validationError: ''
								}
							}));
						}}
						isRequired
					/>
					<SelectInputField
						id={`${PAGE_ID}-ott-status-select`}
						label="OTT Status"
						options={statusOptions}
						value={ottStatus.value}
						onChange={(event) => {
							const newOttStatus = event.target.value;
							updateFormState((prevState) => ({
								...prevState,
								ottStatus: { value: newOttStatus, validationError: '' }
							}));
							// @Note: 6 is equivalent to Listed
							//        7 is equivalent to Pending
							if (initialProgramAndStatus.status == 7 && newOttStatus == 6) {
								setIsEmailRetrainers(true);
							} else {
								setIsEmailRetrainers(false);
							}
						}}
						isRequired
					/>
					<DateInputField
						label="As of Date (Approximate)"
						value={asOfDate.value}
						onChange={(newDateValue) =>
							updateFormState((prevState) => ({
								...prevState,
								asOfDate: {
									value: newDateValue ? formatDate(newDateValue) : newDateValue,
									validationError: ''
								}
							}))
						}
						validationError={asOfDate.validationError}
						isRequired={program.value != DEFAULT_PROGRAM}
					/>
				</Row>
				<Row>
					<SelectInputField
						id={`${PAGE_ID}-locationSource-select`}
						label="Source of Location"
						options={locationSourceOptions}
						value={locationSource.value}
						onChange={(event) => {
							const newLocationSource = event.target.value;
							updateFormState((prevState) => ({
								...prevState,
								locationSource: {
									value: newLocationSource,
									validationError: ''
								},
								...(!isOptionOther(
									locationSourceOptions,
									newLocationSource
								) && { otherLocationSource: { value: '' } }),
								...(!checkDropDownValue(
									locationSourceOptions,
									newLocationSource,
									WELFARE_CHECK
								) && {
									welfareSource: {
										value: BLANK_OPTION.id,
										validationError: ''
									},
									otherWelfareSource: {
										value: '',
										validationError: ''
									},
									personsOfInterest: {
										value: '',
										validationError: ''
									},
									welfareRelationship: {
										value: BLANK_OPTION.id,
										validationError: ''
									},
									auditName: { value: BLANK_OPTION.id, validationError: '' },
									otherWelfareRelationship: {
										value: '',
										validationError: ''
									},
									welfareComments: {
										value: '',
										validationError: ''
									},
									fosterReasonForEnteringProgram: {
										value: BLANK_OPTION.id,
										validationError: ''
									},
									principalRacingAuthority: {
										value: BLANK_OPTION.id,
										validationError: ''
									},
									fosterAssistanceType: {
										value: BLANK_OPTION.id,
										validationError: ''
									},
									fosterReasonForEnteringProgramOther: {
										value: '',
										validationError: ''
									},
									principalRacingAuthorityOther: {
										value: '',
										validationError: ''
									},
									fosterAssistanceTypeOther: {
										value: '',
										validationError: ''
									}
								})
							}));
						}}
						validationError={locationSource.validationError}
						isRequired={checkStableLocationChanged(
							stableLocationFormState,
							horseData.location
						)}
					/>
					{(isLocationSourceFosterCheck ||
						program.value == EMERGENCY_AID_PROGRAM) && (
						<>
							<SelectInputField
								id={`${PAGE_ID}-fosterreason-select`}
								label="Reason for entering program"
								options={fosterReasonForEnteringOptions}
								value={fosterReasonForEnteringProgram.value}
								onChange={(event) => {
									const newFosterReason = event.target.value;
									updateFormState((prevState) => ({
										...prevState,
										fosterReasonForEnteringProgram: {
											value: newFosterReason,
											validationError: ''
										},
										...(!isOptionOther(
											fosterReasonForEnteringOptions,
											newFosterReason
										) && {
											fosterReasonForEnteringProgramOther: {
												value: '',
												validationError: ''
											}
										})
									}));
								}}
								validationError={fosterReasonForEnteringProgram.validationError}
								isRequired
							/>
							<FormFieldWrapper
								fieldLabel={'Assistance type'}
								errorMessage={fosterAssistanceType.validationError}
								fullWidth={false}
								isRequired={program.value == EMERGENCY_AID_PROGRAM}
							>
								<FormMultiSelectList
									id={`${PAGE_ID}-fosterassistancetype-multiselect`}
									name="fosterAssistanceType"
									listOptions={fosterAssistanceTypeOptions}
									selectedIds={fosterAssistanceType.value}
									onSelectChange={(val) => {
										updateFormState((prevState) => ({
											...prevState,
											fosterAssistanceType: {
												value: [],
												validationError: ''
											}
										}));
										handleChangeMultiSelect(val, 'fosterAssistanceType', [
											{ item: 'fosterAssistanceTypeOther', value: '' }
										]);
									}}
									border={fosterAssistanceType.validationError ? 'red' : null}
								/>
							</FormFieldWrapper>
						</>
					)}

					{isPrincipalRacingAuthority && (
						<>
							<SelectInputField
								id={`${PAGE_ID}-principal-racing-authority-select`}
								label="Principal Racing Authority"
								options={principalRacingAuthorityOptions}
								value={principalRacingAuthority.value}
								onChange={(event) => {
									const newPrincipalRacingAuthority = event.target.value;
									updateFormState((prevState) => ({
										...prevState,
										principalRacingAuthority: {
											value: newPrincipalRacingAuthority,
											validationError: ''
										},
										...(!isOptionOther(
											principalRacingAuthorityOptions,
											newPrincipalRacingAuthority
										) && {
											principalRacingAuthorityOther: {
												value: '',
												validationError: ''
											}
										})
									}));
								}}
								validationError={principalRacingAuthority.validationError}
								isRequired
							/>
						</>
					)}
					{isLocationSourceWelfareCheck && (
						<>
							<SelectInputField
								id={`${PAGE_ID}-welfaresource-select`}
								label="Identified During"
								options={welfareSourceOptions}
								value={welfareSource.value}
								onChange={(event) => {
									const newWelfareSource = event.target.value;
									updateFormState((prevState) => ({
										...prevState,
										welfareSource: {
											value: newWelfareSource,
											validationError: ''
										},
										...(!isOptionOther(
											welfareSourceOptions,
											newWelfareSource
										) && {
											otherWelfareSource: { value: '', validationError: '' }
										})
									}));
								}}
								validationError={welfareSource.validationError}
								isRequired
							/>
							<TextInputField
								id={`${PAGE_ID}-persons-of-interest-textfield`}
								label="Person of interest (POI)"
								value={personsOfInterest.value}
								onChange={(event) => {
									const newValue = event.target.value;
									updateFormState((prevState) => ({
										...prevState,
										personsOfInterest: {
											value: newValue,
											validationError: ''
										}
									}));
								}}
								isRequired
								validationError={personsOfInterest.validationError}
							/>
						</>
					)}
				</Row>
				{(isLocationSourceFosterCheck ||
					program.value == EMERGENCY_AID_PROGRAM) &&
					isOtherFosterReason && (
						<Row>
							<Col xs={12}>
								<div className={classes.fieldTitle} style={{ float: 'left' }}>
									Reason for entering program - Other*
								</div>
								<ResizingTextArea
									borderColor={
										fosterReasonForEnteringProgramOther?.validationError
											? 'red'
											: '#c7ced1'
									}
									name="fosterReasonForEnteringProgramOther"
									id={`${PAGE_ID}-other-foster-reason-textfield`}
									value={fosterReasonForEnteringProgramOther.value}
									onChange={(event) => {
										const newValue = event.target.value;
										updateFormState((prevState) => ({
											...prevState,
											fosterReasonForEnteringProgramOther: {
												value: newValue,
												validationError: ''
											}
										}));
									}}
									maxChars={500}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
								/>
								<span className={classes.errorText}>
									{fosterReasonForEnteringProgramOther?.validationError}
								</span>
							</Col>
						</Row>
					)}
				{(isLocationSourceFosterCheck ||
					program.value == EMERGENCY_AID_PROGRAM) &&
					isOtherFosterAssistance && (
						<Row>
							<Col xs={12}>
								<div className={classes.fieldTitle} style={{ float: 'left' }}>
									Assistance type - Other*
								</div>
								<ResizingTextArea
									borderColor={
										fosterAssistanceTypeOther?.validationError
											? 'red'
											: '#c7ced1'
									}
									name="fosterAssistanceTypeOther"
									id={`${PAGE_ID}-other-foster-assistance-textfield`}
									value={fosterAssistanceTypeOther.value}
									onChange={(event) => {
										const newValue = event.target.value;
										updateFormState((prevState) => ({
											...prevState,
											fosterAssistanceTypeOther: {
												value: newValue,
												validationError: ''
											}
										}));
									}}
									maxChars={500}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
								/>
								<span className={classes.errorText}>
									{fosterAssistanceTypeOther?.validationError}
								</span>
							</Col>
						</Row>
					)}
				{isLocationSourceAudit && (
					<Row>
						<SelectInputField
							id={`${PAGE_ID}-auditName-select`}
							label="Audit Name"
							options={sortedAuditNameOption}
							onChange={(e) => handleChange('auditName', e.target.value)}
							value={auditName.value}
							validationError={auditName.validationError}
							isRequired
						/>

						<TextInputField
							id={`${PAGE_ID}-audit-persons-of-interest-textfield`}
							label="Person of interest (POI)"
							value={personOfInterestAudit.value}
							onChange={(event) => {
								const newValue = event.target.value;
								updateFormState((prevState) => ({
									...prevState,
									personOfInterestAudit: {
										value: newValue,
										validationError: ''
									}
								}));
							}}
							validationError={personOfInterestAudit.validationError}
						/>

						<SelectInputField
							id={`${PAGE_ID}-audit-relationship-select`}
							label="POI Relationship"
							options={welfareRelationshipOptions}
							value={relationshipAudit.value}
							onChange={(event) => {
								const newRelationshipAudit = event.target.value;
								updateFormState((prevState) => ({
									...prevState,
									relationshipAudit: {
										value: newRelationshipAudit,
										validationError: ''
									},
									...(!isOptionOther(
										welfareRelationshipOptions,
										newRelationshipAudit
									) && {
										otherRelationshipAudit: {
											value: '',
											validationError: ''
										}
									})
								}));
							}}
							validationError={relationshipAudit.validationError}
						/>
					</Row>
				)}
				{isLocationSourceAudit && (
					<Row>
						{isOtherAuditName && (
							<TextInputField
								id={`${PAGE_ID}-other-audit-textfield`}
								label="Other Audit Name"
								value={auditOther.value}
								onChange={(event) => {
									const newValue = event.target.value;
									updateFormState((prevState) => ({
										...prevState,
										auditOther: {
											value: newValue,
											validationError: ''
										}
									}));
								}}
								validationError={auditOther.validationError}
								isRequired
							/>
						)}
						<Col></Col>
						{isOtherAuditRelationship && (
							<TextInputField
								id={`${PAGE_ID}-other-audit-relationship-textfield`}
								label="Other POI Relationship"
								value={otherRelationshipAudit.value}
								onChange={(event) => {
									const newValue = event.target.value;
									updateFormState((prevState) => ({
										...prevState,
										otherRelationshipAudit: {
											value: newValue,
											validationError: ''
										}
									}));
								}}
								validationError={otherRelationshipAudit.validationError}
								isRequired
							/>
						)}
					</Row>
				)}
				{isLocationSourceAudit && (
					<Row>
						<Col>
							<ResizingTextArea
								title="Comments"
								id={`${PAGE_ID}-audit-comments-textarea`}
								onChange={(event) => {
									const newAuditComments = event.target.value;
									updateFormState((prevState) => ({
										...prevState,
										auditComments: {
											value: newAuditComments,
											validationError: ''
										}
									}));
								}}
								maxChars={500}
								value={auditComments.value}
								styles={{ paddingTop: 0 }}
							/>
						</Col>
					</Row>
				)}
				{isOtherLocationSource && (
					<Row>
						<TextInputField
							id={`${PAGE_ID}-other-location-source-textfield`}
							label="Other source of location"
							value={otherLocationSource.value}
							onChange={(event) => {
								const newValue = event.target.value;
								updateFormState((prevState) => ({
									...prevState,
									otherLocationSource: { value: newValue, validationError: '' }
								}));
							}}
							validationError={otherLocationSource.validationError}
							isRequired
						/>
					</Row>
				)}

				{isOtherPrincipalRacingAuthority && (
					<Row>
						<TextInputField
							id={`${PAGE_ID}-other-principal-racing-authority-textfield`}
							label="Other PRA"
							value={principalRacingAuthorityOther.value}
							onChange={(event) => {
								const newValue = event.target.value;
								updateFormState((prevState) => ({
									...prevState,
									principalRacingAuthorityOther: {
										value: newValue,
										validationError: ''
									}
								}));
							}}
							validationError={principalRacingAuthorityOther.validationError}
							isRequired
						/>
					</Row>
				)}
				{isOtherWelfareSource && (
					<Row>
						<TextInputField
							id={`${PAGE_ID}-other-welfare-source-textfield`}
							label="Other identified during"
							value={otherWelfareSource.value}
							onChange={(event) => {
								const newValue = event.target.value;
								updateFormState((prevState) => ({
									...prevState,
									otherWelfareSource: { value: newValue, validationError: '' }
								}));
							}}
							validationError={otherWelfareSource.validationError}
							isRequired
						/>
					</Row>
				)}
				{isLocationSourceWelfareCheck && (
					<>
						<Row>
							<SelectInputField
								id={`${PAGE_ID}-welfare-relationship-select`}
								label="POI Relationship"
								options={welfareRelationshipOptions}
								value={welfareRelationship.value}
								onChange={(event) => {
									const newWelfareRelationship = event.target.value;
									updateFormState((prevState) => ({
										...prevState,
										welfareRelationship: {
											value: newWelfareRelationship,
											validationError: ''
										},
										...(!isOptionOther(
											welfareRelationshipOptions,
											newWelfareRelationship
										) && {
											otherWelfareRelationship: {
												value: '',
												validationError: ''
											}
										})
									}));
								}}
								validationError={welfareRelationship.validationError}
								isRequired
							/>
							{isOtherWelfareRelationship && (
								<TextInputField
									id={`${PAGE_ID}-other-welfare-relationship-textfield`}
									label="Other POI Relationship"
									value={otherWelfareRelationship.value}
									onChange={(event) => {
										const newValue = event.target.value;
										updateFormState((prevState) => ({
											...prevState,
											otherWelfareRelationship: {
												value: newValue,
												validationError: ''
											}
										}));
									}}
									validationError={otherWelfareRelationship.validationError}
									isRequired
								/>
							)}
						</Row>
						<Row>
							<Col>
								<ResizingTextArea
									title="Comments"
									id={`${PAGE_ID}-welfare-comments-textarea`}
									onChange={(event) => {
										const newWelfareComments = event.target.value;
										updateFormState((prevState) => ({
											...prevState,
											welfareComments: {
												value: newWelfareComments,
												validationError: ''
											}
										}));
									}}
									maxChars={500}
									value={welfareComments.value}
									styles={{ paddingTop: 0 }}
								/>
							</Col>
						</Row>
					</>
				)}
			</Col>
		</FormSection>
	);
};

export default OTTStatusForm;
