import React from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import { formatDateReadableVariant } from '../../utils/helpers';
import { ClaimThoroughbredFormKeys } from '../../utils/constants';

const MemberProfileView = ({
	memberProfileData,
	ownershipType,
	organisation,
	isThoroughbredRegistration,
	claimPurpose,
	horseProgram,
	adminReview,
	stabledAddress,
	stabledDate,
	claimsSource,
	claimsSourceOther,
	title = 'Personal Profile'
}) => {
	const classes = useStyles();
	return (
		<>
			<HeaderBanner
				title={title}
				top
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col>
						<FormLabelField
							title="First Name*"
							value={memberProfileData?.firstName}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Last Name*"
							value={memberProfileData?.lastName}
						/>
					</Col>
					<Col>
						<FormLabelField title="Email*" value={memberProfileData?.email} />
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Phone* (Personal)"
							value={memberProfileData?.phone}
						/>
					</Col>
					<Col>
						<FormLabelField title="Gender*" value={memberProfileData?.gender} />
					</Col>
					<Col></Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					<Col>
						<FormLabelField
							id={ClaimThoroughbredFormKeys.RESIDENTIAL_ADDRESS_LINE_1}
							title="Residential Address Line 1*"
							value={memberProfileData?.residentialAddress?.address}
						/>
					</Col>
					<Col>
						<FormLabelField
							id={ClaimThoroughbredFormKeys.RESIDENTIAL_ADDRESS_LINE_2}
							title="Residential Address Line 2"
							value=""
						/>
					</Col>
					<Col>
						<FormLabelField
							id={ClaimThoroughbredFormKeys.RESIDENTIAL_SUBURB}
							title="Residential Suburb*"
							value={memberProfileData?.residentialAddress?.suburb}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							id={ClaimThoroughbredFormKeys.RESIDENTIAL_POSTCODE}
							title="Residential Postcode*"
							value={memberProfileData?.residentialAddress?.postcode}
						/>
					</Col>
					<Col>
						<FormLabelField
							id={ClaimThoroughbredFormKeys.RESIDENTIAL_STATE}
							title="Residential State*"
							value={memberProfileData?.residentialAddress?.state}
						/>
					</Col>
					<Col>
						<FormLabelField
							id={ClaimThoroughbredFormKeys.RESIDENTIAL_COUNTRY}
							title="Residential Country*"
							value={memberProfileData?.residentialAddress?.country}
						/>
					</Col>
				</Row>
				{adminReview &&
					((stabledAddress && (
						<>
							<hr className={classes.divider}></hr>
							<Row>
								<Col md={4} sm={12}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.THOROUGHBRED_ADDRESS}
										title="Thoroughbred Address*"
										value={stabledAddress.stabledAddressLine}
									/>
								</Col>
								<Col md={4} sm={12}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.THOROUGHBRED_SUBURB}
										title="Thoroughbred Suburb*"
										value={stabledAddress.stabledSuburb}
									/>
								</Col>
								<Col md={4} sm={12}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.THOROUGHBRED_STATE}
										title="Thoroughbred State*"
										value={stabledAddress.stabledState}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={4} sm={12}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.THOROUGHBRED_POSTCODE}
										title="Thoroughbred Postcode*"
										value={stabledAddress.stabledPostcode}
									/>
								</Col>
								<Col md={4} sm={12}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.THOROUGHBRED_COUNTRY}
										title="Thoroughbred Country*"
										value={stabledAddress.stabledCountry}
									/>
								</Col>
								<Col md={4} sm={12}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.DATE_ARRIVED}
										title="Date Arrived*"
										value={formatDateReadableVariant(stabledDate)}
									/>
								</Col>
								<Col md={4} sm={12}>
									<FormLabelField
										id={
											ClaimThoroughbredFormKeys.HOW_DID_YOU_ACQUIRE_THIS_HORSE
										}
										title="How did you acquire this horse?"
										value={claimsSource}
									/>
								</Col>
							</Row>
							{claimsSource === 'Other' && (
								<Row>
									<Col xs={12}>
										<FormLabelField
											id={
												ClaimThoroughbredFormKeys.HOW_DID_YOU_ACQUIRE_THIS_HORSE_OTHER
											}
											title="Please explain further"
											value={claimsSourceOther}
										/>
									</Col>
								</Row>
							)}
						</>
					)) || (
						<>
							<Row>
								<Col md={4} sm={12}>
									<FormLabelField title="Thoroughbred is at this address." />
								</Col>
								<Col md={4} sm={12}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.DATE_ARRIVED}
										title="Date Arrived*"
										value={formatDateReadableVariant(stabledDate)}
									/>
								</Col>
								<Col md={4} sm={12}>
									<FormLabelField
										id={
											ClaimThoroughbredFormKeys.HOW_DID_YOU_ACQUIRE_THIS_HORSE
										}
										title="How did you acquire this horse?"
										value={claimsSource}
									/>
								</Col>
							</Row>
							{claimsSource === 'Other' && (
								<Row>
									<Col xs={12}>
										<FormLabelField
											id={
												ClaimThoroughbredFormKeys.HOW_DID_YOU_ACQUIRE_THIS_HORSE_OTHER
											}
											title="Please explain further"
											value={claimsSourceOther}
										/>
									</Col>
								</Row>
							)}
						</>
					))}
				{!isThoroughbredRegistration && (
					<>
						<hr className={classes.divider}></hr>
						<Row>
							<Col xs={4}>
								<FormLabelField
									id={ClaimThoroughbredFormKeys.RELATIONSHIP}
									title="I am a*"
									value={ownershipType}
								/>
							</Col>
							{ownershipType === 'Organisation' && (
								<Col xs={4}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.ORGANISATION_NAME}
										title="Organisation Name*"
										value={organisation}
									/>
								</Col>
							)}
							{ownershipType === 'Retrainer' && (
								<Col xs={4}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.PURPOSE_OF_CLAIM}
										title="Purpose of Claim*"
										value={claimPurpose}
									/>
								</Col>
							)}
							{adminReview && (
								<Col xs={4}>
									<FormLabelField
										id={ClaimThoroughbredFormKeys.HORSE_PROGRAM}
										title="Horse Program"
										value={
											horseProgram == 'RESET Express' ? 'PRESET' : horseProgram
										}
										showWarning={horseProgram !== 'N/A' && horseProgram}
									/>
								</Col>
							)}
						</Row>
					</>
				)}
			</div>
		</>
	);
};

export default MemberProfileView;
