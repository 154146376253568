import React from 'react';
import BasicChip from '../BasicChip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
	RESET_EXPRESS_COLORS,
	RESET_EXPRESS_QUALIFICATIONS
} from '../AvailableForRetrainingRecord/constants';

const ResetExpressTag = ({
	latestRESETExpressQualification,
	isRemoveMargin
}) => {
	const renderResetExpressTag = (resetExpressTag) => {
		switch (resetExpressTag) {
			case RESET_EXPRESS_QUALIFICATIONS.B1:
				return 'PRESET B1';
			case RESET_EXPRESS_QUALIFICATIONS.B2:
				return 'PRESET B2';
			case RESET_EXPRESS_QUALIFICATIONS.C:
				return 'PRESET C';
			default:
				return '';
		}
	};

	const renderResetTagColor = (resetExpressTag) => {
		switch (resetExpressTag) {
			case RESET_EXPRESS_QUALIFICATIONS.B1:
				return RESET_EXPRESS_COLORS.B1;
			case RESET_EXPRESS_QUALIFICATIONS.B2:
				return RESET_EXPRESS_COLORS.B2;
			case RESET_EXPRESS_QUALIFICATIONS.C:
				return RESET_EXPRESS_COLORS.C;
			default:
				return '';
		}
	};

	return (
		<BasicChip
			containerStyle={
				isRemoveMargin ? {} : { marginBottom: 20, marginLeft: 20 }
			}
			icon={<CheckCircleIcon style={{ color: '#115293' }} />}
			styles={{
				fontSize: 13,
				fontWeight: 550,
				fontStyle: 'italic',
				backgroundColor: renderResetTagColor(latestRESETExpressQualification),
				color: 'black'
			}}
			label={renderResetExpressTag(latestRESETExpressQualification)}
		/>
	);
};

export default ResetExpressTag;
