import React, { useEffect, useState, useCallback } from 'react';
import useStyles from './styles';
import { useScreenClass } from 'react-grid-system';
import { useAuth } from '../../hooks/useAuth';
import HeaderBanner from '../HeaderBanner';
import {
	AVAILABLE_FOR_RETRAINING,
	ACKNOWLEDGED_RETIREMENT_FARMS,
	ACKNOWLEDGED_RETRAINER,
	EVENTS,
	THOROUGHBRED,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_RV_EQUINE_WELFARE,
	SearchKeys,
	HorseStatusType,
	AVAILABLE_FOR_RETRAINING_LISTINGS,
	RESET_ELIGIBLE_LISTINGS,
	RESET_HORSE_EXPRESS,
	//HORSE_AVAILABILITY_REMOVED_STATUS,
	ACKNOWLEDGED_FOSTER_FARMS,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	ROLE_ACKNOWLEDGED_RESET_RETRAINER
} from '../../utils/constants';
import RetrainerSearch from '../RetrainerSearch';
import ScrollMenu from '../ScrollMenu';
import SearchThoroughBred from '../SearchThoroughBred';
import AvailableForRetraining from '../AvailableForRetraining';
import {
	getPublishedHorsePlacementListings,
	getAvailableHorsePlacement,
	getInterestedHorseListings,
	getAdminHorsePlacementListings,
	getRetrainerHorsePlacementListings,
	getResetExpressHorsePlacement,
	countAvailableRetrainingAvailable
} from '../../graphql/custom.queries';
import AppSyncService from '../../graphql/AppSyncService';
import { checkIsSmallScreen } from '../../utils/helpers';
import RetirementFarmSearch from '../RetirementFarmSearch';
import FosterProviderSearch from '../FosterProviderSearch';

const GlobalSearch = ({ location }) => {
	const auth = useAuth();
	const screenSize = useScreenClass();
	const classes = useStyles();
	const [selectedSearch, setSelectedSearch] = useState(THOROUGHBRED);
	const [approvedPlacementOwner, setApprovedPlacementOwner] = useState(false);
	const [menuList, setmenuList] = useState([]);
	const { currentUser } = useAuth();
	const membersExternalId = currentUser?.UserId;
	const [listingsData, setListingsData] = useState([]);
	const [placementsListed, setPlacementsListed] = useState(0);
	const bannerStyles = {
		boxSizing: 'border-box',
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto'
	};
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedHorseStatus, setSelectedHorseStatus] = useState(
		HorseStatusType.AvailableForRetraining
	);

	const isAdmin = currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE);
	const isRetrainer = currentUser?.Roles.includes(
		ROLE_RV_ACKNOWLEDGED_RETRAINER
	);
	const isRetrainerWithResetExpress = currentUser?.Roles.includes(
		ROLE_ACKNOWLEDGED_RESET_RETRAINER
	);

	const onCountAvailableRetraining = useCallback(async () => {
		if (currentUser) {
			if (isAdmin) {
				const result = await AppSyncService.execute(
					countAvailableRetrainingAvailable
				);
				if (result?.data?.countAvailableRetraining) {
					setPlacementsListed(result.data.countAvailableRetraining);
				}
			} else {
				const result = await AppSyncService.execute(
					countAvailableRetrainingAvailable,
					{
						memberExternalId: membersExternalId
					}
				);
				if (result?.data?.countAvailableRetraining) {
					setPlacementsListed(result.data.countAvailableRetraining);
				}
			}
		}
	}, currentUser);

	useEffect(() => {
		onCountAvailableRetraining();
	}, [onCountAvailableRetraining]);

	const evaluateHorrseStatusIds = () => {
		let horseOttStatusIds = [];
		switch (parseInt(selectedHorseStatus)) {
			case HorseStatusType.AvailableForRetraining:
				horseOttStatusIds = AVAILABLE_FOR_RETRAINING_LISTINGS;
				break;
			case HorseStatusType.EligibleForReset:
				horseOttStatusIds = RESET_ELIGIBLE_LISTINGS;
				break;
			case HorseStatusType.ResetExpressHorses:
				horseOttStatusIds = RESET_HORSE_EXPRESS;
				break;
			default:
				horseOttStatusIds = AVAILABLE_FOR_RETRAINING_LISTINGS;
				break;
		}

		return horseOttStatusIds;
	};

	const mapListingData = (horsePlacementListings) => {
		let selectedSubType = null;
		switch (parseInt(selectedHorseStatus)) {
			case HorseStatusType.AvailableForRetraining:
				selectedSubType = HorseStatusType.AvailableForRetraining;
				break;
			case HorseStatusType.EligibleForReset:
				selectedSubType = HorseStatusType.EligibleForReset;
				break;
			case HorseStatusType.ResetExpressHorses:
				selectedSubType = HorseStatusType.ResetExpressHorses;
				break;
			default:
				selectedSubType = HorseStatusType.AvailableForRetraining;
				break;
		}
		return horsePlacementListings.map((item) => ({
			horseStatusSubtype: selectedSubType,
			...item
		}));
	};

	const onLoadRetrainerAvailableForRetraining = async () => {
		let listingsForDisplay = [];
		const horseOttStatusIds = evaluateHorrseStatusIds();

		const getInterestedHorseListingsResult = await AppSyncService.execute(
			getInterestedHorseListings,
			{
				memberExternalId: membersExternalId,
				horseOttStatusId: horseOttStatusIds
			}
		);
		if (getInterestedHorseListingsResult?.data?.getInterestedHorseListings) {
			listingsForDisplay = listingsForDisplay.concat(
				getInterestedHorseListingsResult.data.getInterestedHorseListings
			);
		}

		const getAvailableHorsePlacementResult = await AppSyncService.execute(
			getAvailableHorsePlacement
		);
		if (getAvailableHorsePlacementResult?.data?.getAvailableHorsePlacement) {
			setListingsData(
				getAvailableHorsePlacementResult.data.getAvailableHorsePlacement
			);
			listingsForDisplay = listingsForDisplay.concat(
				getAvailableHorsePlacementResult.data.getAvailableHorsePlacement
			);
		}
		const mappedListingsForDisplay = mapListingData(listingsForDisplay);
		setListingsData(mappedListingsForDisplay);
		setIsLoading(false);
	};

	const onLoadRetrainerEligibleForReset = async () => {
		const horseOttStatusIds = evaluateHorrseStatusIds();

		const getRetrainerHorsePlacementListingsResult = await AppSyncService.execute(
			getRetrainerHorsePlacementListings,
			{
				memberExternalId: membersExternalId,
				horseOttStatusId: horseOttStatusIds
			}
		);
		if (
			getRetrainerHorsePlacementListingsResult?.data
				?.getRetrainerHorsePlacementListings
		) {
			const horsePlacementListingsRetrainer = mapListingData(
				getRetrainerHorsePlacementListingsResult?.data?.getRetrainerHorsePlacementListings.map(
					(item) => ({ ...item, assignedRetrainer: 1 })
				)
			);
			setListingsData(horsePlacementListingsRetrainer);
		}
		setIsLoading(false);
	};

	const onLoadRetrainerResetExpress = async () => {
		getRetrainerHorsePlacementListings;
		getResetExpressHorsePlacement;
		let listingsForDisplay = [];

		const horseOttStatusIds = evaluateHorrseStatusIds();

		const getInterestedHorseListingsResult = await AppSyncService.execute(
			getRetrainerHorsePlacementListings,
			{
				memberExternalId: membersExternalId,
				horseOttStatusId: horseOttStatusIds
			}
		);
		if (
			getInterestedHorseListingsResult?.data?.getRetrainerHorsePlacementListings
		) {
			listingsForDisplay = listingsForDisplay.concat(
				getInterestedHorseListingsResult.data.getRetrainerHorsePlacementListings.map(
					(item) => ({ ...item, assignedRetrainer: 1 })
				)
			);
		}

		const getResetExpressHorsePlacementResult = await AppSyncService.execute(
			getResetExpressHorsePlacement
		);
		if (
			getResetExpressHorsePlacementResult?.data?.getResetExpressHorsePlacement
		) {
			setListingsData(
				getResetExpressHorsePlacementResult.data.getResetExpressHorsePlacement
			);
			listingsForDisplay = listingsForDisplay.concat(
				getResetExpressHorsePlacementResult.data.getResetExpressHorsePlacement
			);
		}
		const mappedListingsForDisplay = mapListingData(listingsForDisplay);
		setListingsData(mappedListingsForDisplay);
		setIsLoading(false);
	};

	const onLoadRetrainerListings = () => {
		switch (parseInt(selectedHorseStatus)) {
			case HorseStatusType.AvailableForRetraining:
				onLoadRetrainerAvailableForRetraining();
				break;
			case HorseStatusType.EligibleForReset:
				onLoadRetrainerEligibleForReset();
				break;
			case HorseStatusType.ResetExpressHorses:
				onLoadRetrainerResetExpress();
				break;
			default:
				onLoadRetrainerAvailableForRetraining();
				break;
		}
	};

	const onLoadListings = useCallback(async () => {
		if (currentUser && selectedSearch === AVAILABLE_FOR_RETRAINING) {
			setIsLoading(true);
			const horseOttStatusIds = evaluateHorrseStatusIds();

			if (isAdmin) {
				const result = await AppSyncService.execute(
					getAdminHorsePlacementListings,
					{ horseOttStatusId: horseOttStatusIds }
				);
				if (result?.data?.getAdminHorsePlacementListings) {
					const availableForRetrainingAdmin = mapListingData(
						result?.data?.getAdminHorsePlacementListings
					);
					setListingsData(availableForRetrainingAdmin);
					setIsLoading(false);
				}
			} else if (isRetrainer) {
				setIsLoading(true);
				onLoadRetrainerListings();
			} else {
				const result = AppSyncService.execute(
					getPublishedHorsePlacementListings,
					{
						memberExternalId: membersExternalId
					}
				);
				if (result?.data?.getHorsePlacementListings?.length > 0) {
					setListingsData(result.data.getHorsePlacementListings);
					setApprovedPlacementOwner(true);
				}
				setIsLoading(false);
			}
		}
	}, [currentUser, selectedSearch, selectedHorseStatus]);

	useEffect(() => {
		onLoadListings();
	}, [onLoadListings]);

	useEffect(() => {
		if (location?.state?.searchSelected) {
			setSelectedSearch(location.state.search);
		} else {
			setSelectedSearch(THOROUGHBRED);
		}
	}, [location?.state]);

	useEffect(() => {
		let menuList = [
			{
				id: SearchKeys.THOROUGHBRED,
				key: 1,
				menuItem: THOROUGHBRED,
				title: 'Thoroughbred',
				show: true
			},
			{
				id: SearchKeys.ACKNOWLEDGED_RETRAINERS,
				key: 2,
				menuItem: ACKNOWLEDGED_RETRAINER,
				title: 'Acknowledged Retrainers',
				show: true
			},
			{
				id: 3,
				menuItem: ACKNOWLEDGED_RETIREMENT_FARMS,
				title: 'Acknowledged Retirement Farms',
				show: true
			}
		];
		if (auth.currentUser) {
			menuList = [
				{
					id: SearchKeys.THOROUGHBRED,
					key: 1,
					menuItem: THOROUGHBRED,
					title: 'Thoroughbred',
					show: true
				},
				{
					id: SearchKeys.ACKNOWLEDGED_RETRAINERS,
					key: 2,
					menuItem: ACKNOWLEDGED_RETRAINER,
					title: 'Acknowledged Retrainers',
					show: true
				},
				{
					id: 3,
					menuItem: ACKNOWLEDGED_RETIREMENT_FARMS,
					title: 'Acknowledged Retirement Farms',
					show: true
				},
				{
					id: SearchKeys.ACKNOWLEDGED_FOSTER_PROVIDERS,
					menuItem: ACKNOWLEDGED_FOSTER_FARMS,
					title: 'Acknowledged STAR Providers',
					show:
						auth?.currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_FOSTER_CARER) ||
						auth?.currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE)
				},
				{
					id: SearchKeys.AVAILABLE_FOR_RETRAINING,
					key: 4,
					menuItem: AVAILABLE_FOR_RETRAINING,
					title: `Available for Retraining${` (${placementsListed})`}`,
					show:
						approvedPlacementOwner ||
						auth?.currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER) ||
						auth?.currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE)
				}
			];
		}
		setmenuList(menuList);
	}, [currentUser, approvedPlacementOwner, placementsListed]);

	// const onSelectHorseStatus = () => {

	// }

	return (
		<>
			<div className={isSmallScreen ? classes.pageContainerMobile : {}}>
				{!isSmallScreen && (
					<HeaderBanner title="Search" styles={bannerStyles} />
				)}

				<ScrollMenu
					selectedSearch={selectedSearch}
					menuList={menuList}
					onClick={(s) => setSelectedSearch(s)}
				/>

				{selectedSearch === THOROUGHBRED && (
					<div>
						<SearchThoroughBred />
					</div>
				)}
				{selectedSearch === ACKNOWLEDGED_RETRAINER && (
					<div>
						<RetrainerSearch isSmallScreen={isSmallScreen} />
					</div>
				)}
				{selectedSearch === AVAILABLE_FOR_RETRAINING && (
					<div>
						<AvailableForRetraining
							isRetrainerOnly={isRetrainer && !isAdmin}
							listingsData={listingsData}
							isLoading={isLoading}
							isRetrainerWithResetExpress={isRetrainerWithResetExpress}
							isAdmin={isAdmin}
							selectedHorseStatus={selectedHorseStatus}
							setSelectedHorseStatus={setSelectedHorseStatus}
						/>
					</div>
				)}
				{selectedSearch === ACKNOWLEDGED_RETIREMENT_FARMS && (
					<div>
						<RetirementFarmSearch isSmallScreen={isSmallScreen} />
					</div>
				)}
				{selectedSearch === ACKNOWLEDGED_FOSTER_FARMS && (
					<div>
						<FosterProviderSearch isSmallScreen={isSmallScreen} />
					</div>
				)}
				{selectedSearch === EVENTS && <div></div>}
			</div>
		</>
	);
};

export default GlobalSearch;
