import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { Container, Row, Hidden } from 'react-grid-system';
import SEO from '../components/seo';
import Renderer, { components } from './renderer';

const StyleGuide = () => {
	const classes = useStyles();
	const [activeComponent, setActiveComponent] = useState(null);

	useEffect(() => {
		//page load, select Overview as default
		setActiveComponent('OverView');
	}, []);

	return (
		<div className={classes.affix}>
			<Hidden xs sm>
				<Container className={classes.wrapper}>
					<SEO title="Style Guide" />
					<Row>
						<div className={classes.leftMenu}>
							<div>
								{components?.map((element) => {
									return (
										<div
											key={element.label}
											className={
												activeComponent === element.component
													? classes.sidebarMenuOptionSelected
													: classes.sidebarMenuOption
											}
											onClick={() => setActiveComponent(element.component)}
										>
											{element.label}
										</div>
									);
								})}
							</div>
						</div>
						<div className={classes.rightSide}>
							<Renderer componentName={activeComponent} />
						</div>
					</Row>
				</Container>
			</Hidden>
			<Hidden md lg xl xxl>
				<h1 style={{ wordWrap: 'break-word!important', maxWidth: '75%' }}>
					Styleguide is only designed for &gt;= md displays
				</h1>
			</Hidden>
		</div>
	);
};

export default StyleGuide;
